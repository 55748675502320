import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "./../../components/simpleTable";
import { getLoanStatement } from "./../../service/api";
import Alert from "./../../components/alert";
import { convertTimestamptoDate } from "./../../utils/helper";

const COLS = [
    { key: "emi-amount", align: "left", label: "EMI AMOUNT", value: "emiAmount", type: "text", sort: false, fn: (v) => v },
    { key: "emi-frequency", align: "left", label: "FREQUENCY", value: "frequency", type: "text", sort: false, fn: (v) => v},
    { key: "due-date", align: "left", label: "DUE DATE", value: "dueDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "due-amount", align: "left", label: "DUE AMOUNT", value: "dueAmount", type: "text", sort: false, fn: (v) => v },
    { key: "bounce-fee", align: "left", label: "BOUNCE FEE", value: "bounceFee", type: "text", sort: false, fn: (v) => v },
    { key: "late-fee", align: "left", label: "LATE FEE", value: "lateFee", type: "text", sort: false, fn: (v) => v },
    { key: "dpd-count", align: "left", label: "DPD", value: "dpd", type: "text", sort: false, fn: (v) => v },
    // { key: "closure-amount", align: "left", label: "CLOSURE AMT", value: "closureAmount", type: "text", sort: false, fn: (v) => v },
    { key: "paid-amount", align: "left", label: "PAID AMT", value: "paidAmount", type: "text", sort: false, fn: (v) => v }
]

class Statement extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false,
            data: [],
            hasError: false,
            errorMessage: ""
        }
    }

    componentDidMount() {
        this.fetchLoanStatement();
    }

    fetchLoanStatement = () => {
        
        const { loading } = this.state;
        const { loanId } = this.props;

        if (!loading) {
            this.setState({
                loading: true
            })
        }

        getLoanStatement(loanId, {},
            (res) => {
                if (res.success) {
                    this.setState({
                        loading: false,
                        data: res.response
                    })
                } else {
                    this.setState({
                        hasError: true
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >EMI DETAILS</Typography>
                    <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                    />
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(Statement);