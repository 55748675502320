import React from "react";
import {
    Grid,
    Typography,
    TextField,
    Button,
    MenuItem
} from "@material-ui/core";
import { RELATIONS } from "./../../common/constants";

const FIELD = [
    { key: "user-name", name: "name", label: "NAME", type: "text", option: [] },
    { key: "user-phone", label: "PHONE", name: "phone", type: "text", option: [] },
    { key: "user-relation", label: "RELATIONSHIP", name: "relation", type: "select", option: RELATIONS }
]

export default function AddReference(props) {
    const { userId, handleClose } = props;
    const [field, setField] = React.useState({"userId": userId });

    const handleChange = evt => {
        setField({
            ...field, [evt.target.name]: evt.target.value
        });
    }

    return (<div style={styles.root}>
        <Grid
            container
            style={styles.rootContainer}
            justify="center"
            alignItems="center"
        >
            <Typography
                variant="h5"
                style={styles.typography}
                gutterBottom
                >ADD REFERENCE</Typography>
                {FIELD.map(item => {
                    const { key, label, type, option, name } = item;
                    const isSelect = item.type === "select";
                    return <TextField
                        id={key}
                        name={name}
                        label={label}
                        type={type}
                        required
                        select={isSelect}
                        defaultValue={isSelect ? option[0] : null}
                        onChange={handleChange}
                        style={styles.textField}
                        variant="outlined"
                        size="small"
                    >{isSelect ?
                        option.map(opt => <MenuItem key={opt} value={opt}>
                            {opt}</MenuItem>)
                        : null
                        }</TextField>
                })}
                <div style={styles.btnContainer}>
                    <Button
                        style={styles.closeBtn}
                        variant="outlined"
                        onClick={handleClose}
                    >CLOSE</Button>
                    <Button
                        style={styles.saveBtn}
                        variant="outlined"
                    >ADD</Button>
                </div>
        </Grid>
    </div>);
}

const styles = {
    root: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    rootContainer: {
        width: 390,
        background: "#fff",
        borderRadius: 6,
        padding: 30
    },
    typography: {
        fontFamily: "Poppins"
    },
    textField: {
        width: 300,
        margin: "15px 0"
    },
    btnContainer: {
        display: "inherit",
        margin: "15px 0px",
        width: 300
    },
    closeBtn: {
        marginRight: "auto",
        width: 100
    },
    saveBtn: {
        width: 100,
        background: "#1B2D93",
        color: "#fff"
    }
}

