import React from "react";
import {
    Grid,
    Typography
} from "@material-ui/core";
import { convertTimestamptoDate } from "../../utils/helper";
import { getCreditLineApplicationInfo, getCreditLineDigiLockerInfo, getCreditLineKycInfo, getUnderwritingBasicInfo } from "../../service/api";
import CustomImage from "../../components/imageView";
import CustomModal from "../../components/modal";
import imageNotAvailable from '../../assets/imageNotAvailable.png';


class UnderWritingDetail extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isImageZoom: "",
            staticData: [],
            clApplicationDetails: {},
            clKycDetails: {},
            clDigilockerDetails: {},
            uwDetails: {},
            modalOpen:false,
            modalChildren:null
        }
    }

    componentDidMount () {
        const { id } = this.props;
        this.callApis(id);
    }

    callApis = (id) => {

        const getCreditLineApplicationInfoPromise = new Promise((resolve, reject) => {
            getCreditLineApplicationInfo(id, {}, res => resolve(res))
        })
        const getCreditLineKycInfoPromise = new Promise((resolve, reject) => {
            getCreditLineKycInfo(id, {}, res => resolve(res))
        })

        const getCreditLineDigiLockerInfoPromise = new Promise((resolve, reject) => {
            getCreditLineDigiLockerInfo(id, {}, res => resolve(res))
        })

        const getUnderwritingInfoPromise = new Promise((resolve, reject) => {
            getUnderwritingBasicInfo(id, {}, res => resolve(res))
        })

        Promise.all([
            getCreditLineApplicationInfoPromise,
            getCreditLineKycInfoPromise,
            getCreditLineDigiLockerInfoPromise,
            getUnderwritingInfoPromise
        ]).then(res => {
            this.setState({
                clApplicationDetails: res[0],
                clKycDetails: res[1],
                clDigilockerDetails: res[2],
                uwDetails: res[3],
                id: id
            },
                () => this.makeColumns()
            )
        }).catch(err => {
            this.setState({
                hasError: true,
                loading: false,
                id: id
            })
        })
    }

    handleZoomChange = imageSrc => {
        const { isImageZoom } = this.state;
        if (isImageZoom === imageSrc) {
            this.setState({
                isImageZoom: ""
            })
        } else {
            this.setState({
                isImageZoom: imageSrc
            })
        }
    }

    handleModal = () => {
        const {modalOpen}= this.state;
        if (!modalOpen){
            this.setState({
                modalOpen:true
            })
        }else{
            this.setState({
                modalOpen:false
            })
        }
    };

    handleImage =(item)=>{
        this.setState({
            modalChildren:<CustomImage item={item} handleClose={this.handleModal}/>
        },()=>{
            this.handleModal()
        })
    }


    createApplicationDetail = (dat) => {
        if (!dat) return [];
        const showReference = ref => {
            return String('Name ' + (ref[0].name ?? '-') + '\nPhone ' + (ref[0].phone ?? '-'));
        }
        const { applicationId, name, phone, reference1, reference2, company, applicationCompleteDate } = dat;
        return [
            { key: "credit-line-application-id", label: "APPLICATION ID ", value: applicationId },
            { key: "credit-line-name", label: "NAME", value: name },
            { key: "credit-line-phone", label: "PHONE", value: phone },
            { key: "credit-line-reference1", label: "REFERENCE 1", value: showReference(reference1) },
            { key: "credit-line-reference2", label: "REFERENCE 2", value: showReference(reference2) },
            { key: "credit-line-company", label: "COMPANY", value: company },
            { key: "credit-line-application-complete-date", label: "APPLICATION COMPLETE DATE", value: convertTimestamptoDate(applicationCompleteDate) },
        ]
    }

    createKycDetail = (dat) => {
        if (!dat) return [];
        const { photo, panCard, aadharFront, aadharBack } = dat;
        console.log(photo);
        return [
            { key: "credit-line-photo", label: "PHOTO", value: photo },
            { key: "credit-line-panCard", label: "PANCARD", value: panCard },
            { key: "credit-line-aadharFront", label: "AADHAR FRONT", value: aadharFront },
            { key: "credit-line-aadharBack", label: "AADHAR BACK", value: aadharBack },
        ]
    }

    createUnderwritingDetail = (dat) => {
        if (!dat) return [];
        const { employmentType,currentAddress,permanentAddress}=dat;
        return [
            { key: "uw-employ-type", label: "EMPLOYMENT TYPE", value:employmentType },
            { key: "uw-current-address", label: "CURRENT ADDRESS", value: currentAddress },
            { key: "uw-permanent-address", label: "PERMANENT ADDRESS", value: permanentAddress },
        ]
    }


    createDigilockerPanDetail = (dat) => {
        if (!dat) return [];
        const { nameOnPan, dob } = dat;
        return [
            { key: "credit-line-digilocker-name-on-pan", label: "NAME ON PAN", value: nameOnPan },
            { key: "credit-line-digilocker-dob-on-pan", label: "DOB ON PAN", value: dob }
        ]
    }

    createDigilockerAadhaarDetail = (dat) => {
        if (!dat) return [];
        const { aadharPdf } = dat;
        console.log(aadharPdf)
        return [
            { key: "credit-line-digilocker-aadhaar-detail", label: "", value: aadharPdf }
        ]
    }

    showLess =(item)=>{
      let tempStr ="";
      let tempLen = 0;
      if(item.length<40){ tempLen=item.length;}
      else if(item.length>50){tempLen=50;}
      else{tempLen=40;}
      for(let i =0;i<tempLen;i++){
          if(item[i]===","){tempStr=tempStr+" , "}
          else{tempStr=tempStr+item[i]}
      }
      return tempStr;
    }

    makeRow = (data) => {
        return (
            <>
                <Grid container item xs={12} spacing={3} >
                    {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>{item.label}</Typography>
                        {["uw-permanent-address","uw-current-address"].includes(item.key)?
                          <>
                           <div title={item.value ?? "-"} style={styles.fieldValue} >{item.value ?this.showLess(item.value): "-"}</div>
                          </>:
                          <>
                          <Typography style={styles.fieldValue}>{item.value ?? "-"}</Typography>
                          </>}
                    </Grid>
                    )}
                </Grid>
            </>
        );
    }

    makeImageRow = (data) => {
        return (
            <Grid container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    {item.value?
                     <>
                     <div onClick={()=>{this.handleImage(item) }}>
                     <img
                         key={item.key}
                         src={`${item.value}`}
                         alt={`${item.label} _not_found`}
                         style={{
                             width:250,
                             height:200,
                             border: '2px solid',
                             borderRadius: 6,
                             objectFit: 'contain',
                         }}
                     />
                     </div>
                     </>:
                     <>
                     <img
                         key={item.key}
                         src={`${imageNotAvailable}`}
                         alt={`${item.label} _not_found`}
                         style={{
                             width:250,
                             height:200,
                             border: '2px solid',
                             borderRadius: 6,
                             objectFit: 'contain',
                         }}
                     />
                     </>}
                </Grid>)}
            </Grid>
        )
    }

    makeHead = (data) => {
        const viewMapper = {
            'credit-line-kyc-detail-col': (item) => this.makeImageRow(item),
            'credit-line-digilocker-aadhaar-col-uw': (item) => this.makeImageRow(item),
            'default': (item) => this.makeRow(item)
        }

        return (
            <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                {data.map(item => <div key={item.key}>
                    {
                        <>
                            <Typography variant="h6" gutterBottom style={styles.header}>{item.label}</Typography>
                            {viewMapper[item.key] ? viewMapper[item.key](item.value) : viewMapper['default'](item.value)}
                        </>
                    }
                </div>)}
            </Grid>
        );
    }

    makeColumns = () => {
        const { clApplicationDetails, clKycDetails ,clDigilockerDetails,uwDetails} = this.state;
        const detailData = [
            { key: "credit-line-app-detail-col", label: "APPLICATION DETAIL", value: this.createApplicationDetail(clApplicationDetails), },
            { key: "credit-line-kyc-detail-col", label: "KYC DETAIL", value: this.createKycDetail(clKycDetails), },
            { key: "uw-basic-details-col", label: "UNDERWRITING DETAIL", value: this.createUnderwritingDetail(uwDetails), },
            { key: "credit-line-digilocker-pan-col", label: clDigilockerDetails ? "DIGILOCKER PAN DETAIL" : "", value: this.createDigilockerPanDetail(clDigilockerDetails) },
            { key: "credit-line-digilocker-aadhaar-col-uw", label: clDigilockerDetails ? "DIGILOCKER AADHAAR" : "", value: this.createDigilockerAadhaarDetail(clDigilockerDetails)},

        ]
        this.setState({
            staticData: detailData
        })
    }

    render () {
        const { staticData,modalOpen ,modalChildren} = this.state;
        return (<>
            {this.makeHead(staticData)}
            <CustomModal
                isOpen={modalOpen}
                handleClose={this.handleModal}
            >{<>
                {modalChildren}
            </>}</CustomModal>
        </>)
    }

}

const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    btnContainer: {
        background: "#fff",
        listStyle: "none",
        margin: "0px 40px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    },
    container: {
        padding: "20px 60px"
    },
    backBar: {

    },
    barTitle: {
        margin: 5,
        padding: 10
    },
    backIcon: {
        marginLeft: 10
    },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldLabel: {
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E"
    },
    fieldValue: {
        fontSize: 17,
        fontFamily: "inherit",
        whiteSpace: 'break-spaces'
    },
    singleHead: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 30,
        borderBottom: "1px solid"
    },
    singleRowContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 40,

    },
    borderContainer: {
        border: "1px solid"
    },
    singleHeadCell: {
        width: 200,
        textAlign: "left",
        fontSize: 25,
        padding: "20px 50px 10px",

    },
    singleRowCell: {
        width: 200,
        textAlign: "center"
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        height: 40,
        maxHeigth: 80,
    },
    addButton: {
        width: 120,
        height: 40,
        position: "float",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    }
}

export default UnderWritingDetail;