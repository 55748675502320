import React, { useState } from "react";
import {
    Typography,
    IconButton,
    TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "../../components/simpleTable";
import { getDeductionSummaryWithTransactionId } from "../../service/api";
import Alert from "../../components/alert";
import ContentLoader from "../../components/contentLoader";

const COLS = [

    { key: "dc-statement-id", align: "center", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-loan-id", align: "left", label: " LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-emi-uploaded", align: "center", label: "EMI UPLOADED ", value: "emiUploaded", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-emi-deducted", align: "left", label: "EMI DEDUCTED", value: "emiDeducted", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-company", align: "center", label: "COMPANY", value: "company", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-status", align: "center", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "dc-failure-reason", align: "center", label: "FAILURE REASON", value: "failureReason", type: "text", sort: false, fn: (v) => v ?? '-' },


]
  
function Searching(props) {
    const { data } = props
     // the value of the search field 
    const [name, setName] = useState('');

    // the search result
    const [foundUsers, setFoundUsers] = useState(data);
    const filter = (e) => {
        const keyword = e.target.value;
    
        if (keyword !== '') {
          const results = data.filter((item) => {
            if(item.loanId===keyword){
                return item;
            }else{
                return console.log("1")
            }
          });
          setFoundUsers(results);
        } else {
          setFoundUsers(data);
        }
    
        setName(keyword);
      };
    return (
      <div className="container">
        <TextField
          id="outlined-basic"
          variant="outlined"
          label="Search by loan Id"
          size="small"
          value={name}
          onChange={filter}
          style={{margin:2}}
        />
          {foundUsers && foundUsers.length > 0 ? (
            <SimpleTable
                        cols={COLS}
                        data={foundUsers}
                        loading={props.loading}
                    />

          ) : (
            <h1 style={{margin:15}}>No results found!</h1>
          )}
      </div>
    );
  }

class DeductionSummary extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: ""
        }
    }

    componentDidMount () {
        this.fetchStatement();
    }

    fetchStatement = () => {

        const { id } = this.props;

        getDeductionSummaryWithTransactionId({"transactionId":id},
            (res) => {
                if (res.success && res) {
                    const { data } = res;
                    this.setState({
                        loading: false,
                        data: data
                    })
                } else {
                    this.setState({
                        hasError: true,
                        errorMessage:res.message
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >DEDUCTION SUMMARY</Typography>
                    {loading?
                    <>
                    <div style={{height: "calc(100vh - 260px)",}}>
                    <ContentLoader numberOfLines={4} module="statement"/>
                    </div>
                    </>:<>
                    <Searching data={data} loading={loading}/>
                    </>}
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "3% 5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(DeductionSummary);
