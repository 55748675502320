import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "./../../components/simpleTable";

const COLS = [
    { key: "created-date", align: "left", label: "CREATED DATE", value: "createdDate", type: "text", sort: false },
    { key: "last-updated", align: "left", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false },
    { key: "call-log-phone", align: "left", label: "PHONE", value: "phone", type: "text", sort: false },
    { key: "scheduled-time", align: "left", label: "SCHEDULED TIME", value: "scheduledTime", type: "text", sort: false },
    { key: "call-log-comment", align: "left", label: "COMMENT", value: "comment", type: "text", sort: false },
    { key: "reason-code", align: "left", label: "REASON CODE", value: "reasonCode", type: "text", sort: false },
    { key: "call-log-bound", align: "left", label: "BOUND", value: "bound", type: "text", sort: false },
    { key: "call-log-type", align: "left", label: "TYPE", value: "type", type: "text", sort: false },
    { key: "loan-id", align: "left", label: "LOAN ID", value: "loanId", type: "text", sort: false },
    { key: "agent-id", align: "left", label: "AGENT ID", value: "agentId", type: "text", sort: false }
]

const DATA = [{
    "createdDate": "2018-12-20",
    "lastUpdated": "2019-12-29",
    "phone": "9493939293",
    "scheduledTime": "12:45 PM",
    "comment": "WILL CALL LATER",
    "reasonCode": "PHONE_NOT_PICKED",
    "bound": "NONE",
    "type": "NONE",
    "loanId": "2003923",
    "agentId": "3"
}, {
    "createdDate": "2018-12-10",
    "lastUpdated": "2019-12-19",
    "phone": "9493993843",
    "scheduledTime": "12:45 PM",
    "comment": "WILL CALL LATER",
    "reasonCode": "PHONE_NOT_PICKED",
    "bound": "NONE",
    "type": "NONE",
    "loanId": "2003922",
    "agentId": "3"
}, {
    "createdDate": "2018-12-20",
    "lastUpdated": "2019-12-29",
    "phone": "9493939293",
    "scheduledTime": "12:45 PM",
    "comment": "WILL CALL LATER",
    "reasonCode": "PHONE_NOT_PICKED",
    "bound": "NONE",
    "type": "NONE",
    "loanId": "2003923",
    "agentId": "3"
}];

class CallLog extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false
        }
    }
    render() {
        const { classes, handleClose } = this.props;
        return (<div className={classes.rootContainer}>
            <IconButton variant="contained"
                    className={classes.closeBtn}
                    onClick={() => handleClose()}
                ><CloseIcon /></IconButton>
            <Typography
                variant="h5"
                gutterBottom
                className={classes.typography}
            >CALL LOGS</Typography>
            <SimpleTable
                cols={COLS}
                data={DATA}
            />
        </div>);
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(CallLog);