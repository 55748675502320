import React from "react";
import {
    Button,
    TextField,
 } from "@material-ui/core";

export default function ActionGoToPage (props) {
    const {pageNumberValue,handleGoToPageChange,onChangeGoToValue}= props;

    return (
      <>
      <div style={{
          background: "#fff",
          height: 40,
          listStyle: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexWrap: 'wrap',
          position:"absolute",
          bottom:5,
      }}>
      <TextField
        id={pageNumberValue}
        type="number"
        name="pageNumberValue"
        required
        value={pageNumberValue}
        onChange={onChangeGoToValue}
        select={false}
        InputLabelProps={{
            shrink: true
        }}
        inputProps={{
            style: {
              width: 90,
              height: 10
            },
          }}
        style={{

        }}
        size="small"
        variant="outlined"
        >
        </TextField>
        <Button
        style={styles.agreeBtn}
        size="small"
        onClick={handleGoToPageChange}
        >Go To</Button>
      </div>
      </>

    );
}

const styles={
    rootContainer:{
      width:400
    },
    title:{
        margin:10
    },
    progessBar:{
        color:"green",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"5% 35% 5% 42%"
    },
    dialogAction:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:10
    },
    disagreeBtn:{
        padding:10,
        background:"#FF0A50",
        color:"white",
        width:100,
        fontWeight:500,
        borderRadius:20,
        margin:10
    },
    agreeBtn:{
        padding:5,
        background:"#1B2D93",
        color:"white",
        width:100,
        fontWeight:500,
        borderRadius:20,
        margin:5
    },
    fieldLabel:{
        fontWeight:"bold"
    }

}