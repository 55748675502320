import React from "react";
import {
    TextField,
} from "@material-ui/core";


export default function SearchField(props) {
    const { placeholder, onSearch, search, onSearchType, searchType } = props;

    return (
        <div style={styles.rootContainer}>
            <select style={styles.selectField} onChange={onSearchType}>
                {searchType.map(item => <option key={item.value} value={item.value}>
                    {item.label}
                </option>)}
            </select>
            <TextField
                id="search-field"
                name="search"
                type="search"
                placeholder={"Search " + placeholder}
                onChange={onSearch}
                value={search}
                title={"Search " + placeholder}
                style={styles.textField}
                variant="outlined"
                size="small"
            />
        </div>
    );
}

const styles = {
    rootContainer: {
        position: "absolute",
        top: 95,
        right: 48
    },
    selectField: {
        background: "#fff",
        width: 150,
        height: 40,
        borderRadius: 5,
        margin: "0 30px",
        outline: "none",
        fontSize: 16
    },
    textField: {
        background: "#fff",
        borderRadius: 5,
        width: 250
    }
}