import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import {  makeSort,  debounce, convertTimestamptoDateWithTime } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import { getClPtpListing} from "./../../service/api";
import ClPtpStore from "../../stores/clPtpStore";
import SearchField from "./../../components/searchField";
import FilterBar from "./../../components/filterBar";
import { SEARCH_TYPE_CODES } from "../../common/constants";

const COLS = [
    // { key: "p-id", align: "center", label: "ID", value: "id", type: "text", sort: true, fn: (v) => v??"-" },
    // // { key: "p-user-id", align: "center", label: "USER ID", value: "userId", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-application-id", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-statement-id", align: "center", label: "STATEMENT ID", value: "statementId", type: "text", sort: true, fn: (v) => v??"-" },

    // { key: "p-agent", align: "center", label: "AGENT", value: "agent", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-promise-amount", align: "center", label: "PROMISE AMOUNT", value: "promiseAmount", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-amount-paid", align: "center", label: "AMOUNT PAID", value: "amountPaid", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-promise-date", align: "center", label: "USER ID", value: "promiseDate", type: "text", sort: true, fn: (v) => v ??"-"},

    // { key: "p-created-date", align: "center", label: "CREATED DATE", value: "createdDate", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },
    // { key: "p-lastupdated", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },


    // { key: "p-order-id", align: "center", label: "ORDER ID", value: "orderId", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-status-id", align: "center", label: "STATUS", value: "status", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-payment-link-type", align: "center", label: "PAYMENT LINK TYPE", value: "paymentLinkType", type: "text", sort: true, fn: (v) => v ??"-"},
    // { key: "p-last-payment-id", align: "center", label: "LAST PAYMENT ID", value: "lastPaymentId", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-dpd-count", align: "center", label: "DPD COUNT", value: "dpdCount", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-comment", align: "center", label: "COMMENT", value: "comment", type: "text", sort: true, fn: (v) => v??"-" },



    { key: "p-id", align: "center", label: "ID", value: "id", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-created-date", align: "center", label: "CREATED DATE", value: "createdDate", type: "text", sort: true, fn: (v) => convertTimestamptoDateWithTime(v) },
    { key: "p-agent", align: "center", label: "AGENT", value: "agent", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-promise-date", align: "center", label: "PROMISE DATE", value: "promiseDate", type: "text", sort: true, fn: (v) => v ??"-"},
    { key: "p-promise-amount", align: "center", label: "PROMISE AMOUNT", value: "promiseAmount", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-amount-paid", align: "center", label: "AMOUNT PAID", value: "amountPaid", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-order-id", align: "center", label: "ORDER ID", value: "orderId", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-dpd-count", align: "center", label: "DPD COUNT", value: "dpdCount", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-status-id", align: "center", label: "STATUS", value: "status", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-application-id", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-statement-id", align: "center", label: "STATEMENT ID", value: "statementId", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-statement-id", align: "center", label: "PHONE", value: "phone", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "p-waiver-committed", align: "center", label: "WAIVER COMMITTED", value: "waiverCommitted", type: "text", sort: true, fn: (v) => v??"-" },
    // { key: "p-user-id", align: "center", label: "USER ID", value: "userId", type: "text", sort: true, fn: (v) => v??"-" },

    { key: "p-lastupdated", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: true, fn: (v) => convertTimestamptoDateWithTime(v) },


    // { key: "p-payment-link-type", align: "center", label: "PAYMENT LINK TYPE", value: "paymentLinkType", type: "text", sort: true, fn: (v) => v ??"-"},
    // { key: "p-last-payment-id", align: "center", label: "LAST PAYMENT ID", value: "lastPaymentId", type: "text", sort: true, fn: (v) => v??"-" },

    { key: "p-comment", align: "center", label: "COMMENT", value: "comment", type: "clPtpComment", sort: true, fn: (v) => v??"-" },

   ];


class ClPtpListing extends React.Component {
    constructor(props) {
        super(props);
        this.store = ClPtpStore;
        this.pagination = PaginationStore;
        this.dbounceCaller = debounce(() => this.fetchClPtpRequest(true));
        this.state = {
            searchText: "",
            searchType: "applicationId",
            loading: true,
            hasError: false,
            errorMessage: "",
            filter: {},
            sort: {
                sortType: "desc",
                sort: false
            },
            pageNumberValue:"1"
        }
    }

    componentDidMount() {
        this.fetchClPtpRequest(true);
    }


    makeFiltersForIt = (obj)=>{
        const filter = {};
        Object.keys(obj).forEach(key => {
            let value = obj[key];
                if (key === "status"|| key === "agentEmail") {
                    filter[key] = value;
                } return {};
        })
        return filter ;

    }
    getSearchValue =(type,value)=>{
        switch(type){
            case "userId":
                return { "userId":value};
            case "loanId":
                return { "loanId":value};
            case "phone":
                return { "phone":value};
            default :
               return {};
        }
    }

    fetchClPtpRequest($hasUpdate = false) {
        const { loading, filter, sort, searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            pageNo: page,
            pageSize: pageSize
        }


        if ($hasUpdate) {
            params = {
                pageNo: 1,
                pageSize: pageSize
            }
        }

        const updatedFilter = this.makeFiltersForIt(filter);

        if (sort.sort) {
            Object.assign(params, sort);
        }


        Object.assign(params,  updatedFilter );

        if (searchText) {
            Object.assign(params, this.getSearchValue(searchType,searchText));

        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getClPtpListing(
            params,
            (res) => {
                if (res && res.success) {
                    const { pageNo,clPtpList,  totalCount } = res.response;
                    this.store.setData(clPtpList);
                    this.pagination.goToPage(pageNo);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchClPtpRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchClPtpRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

    // search methods
    handleSearch = evt => {

        if (evt.target.value) {
            this.setState({ searchText: evt.target.value });
        } else {
            this.setState({ searchText: "" });
        }

        this.dbounceCaller();

    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    // sort methods
    handleSort = (sortByValue) => {
       console.log(sortByValue);
        const { sort } = this.state;
        let nSort = sort;

        if (!nSort.sort) {
            nSort = {
                "sortLabel": sortByValue,
                "sort": sortByValue,
                "sortType": "desc"
            }
        } else {
            if (nSort.sort === sortByValue) {
                nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
            } else {
                nSort = {
                    "sortLabel": sortByValue,
                    "sort": sortByValue,
                    "sortType": "desc"
                }
            }
        }

        this.setState({
            sort: makeSort(nSort, "user")
        }, () => this.fetchClPtpRequest(true))
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // filter bar methods
    handleFilter = data => {
        let tempFilter = Object.assign({}, this.state.filter);
        data.forEach(item => {
            const propertyValue = {[item.property]: item.value};
            Object.assign(tempFilter, propertyValue);
        })

        if (!data.length) {
            tempFilter = {};
        }

        this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
        console.log("updating property...", _filter);
        this.setState({
            filter: _filter
        }, () => this.fetchClPtpRequest(true))
    }

    removeProperty = (property) => {
        let tempFilter = Object.assign({}, this.state.filter);
        delete tempFilter[property];
        this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
        this.setState({
            filter: {}
        }, () => this.updateProperty())
    }

    render() {
        const { loading, hasError, errorMessage, sort, filter ,pageNumberValue} = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>
                <SearchField
                    placeholder={"Ptp "}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["clPtpList"]}
                />

                <FilterBar
                    screen={"clPtpList"}
                    appliedFilter={filter}
                    callback={{
                        handleFilter: this.handleFilter,
                        removeProperty: this.removeProperty,
                        clearFitler: this.clearFilter
                    }}
                />

                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={true}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

export default observer(ClPtpListing);