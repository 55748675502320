
import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import {  makeFilters,  makeSort, debounce,} from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import { getManualCheckCount, postNameReviewLoanListing  } from "./../../service/api";
import SearchField from "./../../components/searchField";
import FilterBar from "./../../components/filterBar";
import {  SEARCH_TYPE_CODES } from "../../common/constants";
import ManualCheckStore from "../../stores/manualCheckStore";
import FloatingDetail from "../../components/floatingDetails";

import { IconButton } from "@material-ui/core";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import { getLocalStorage } from "../../utils/localStorage";

// const ROLE_VALUES = {
//     "ADMIN":["ROLE_ADMIN","ROLE_CRM_ADMIN"],
//     "USERS":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN"],
//     "LOANS":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN"],
//     "CREDITLINES":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN"],
//     "WAIVERS":["ROLE_ADMIN" , "ROLE_WAIVER", "ROLE_CRM_ADMIN"],
//     "MANUALCHECK":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN"],
//     "UNDERWRITING":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN"],
//     "SETTLEMENT":["ROLE_ADMIN" , "ROLE_WAIVER", "ROLE_CRM_ADMIN"],
//     "CAMPAIGNS":["ROLE_ADMIN" , "ROLE_CRM_ADMIN","ROLE_CAMPAIGN"],
//     "DEDUCTION":["ROLE_ADMIN" , "ROLE_DP", "ROLE_CRM_ADMIN"],
//     "LOANPTP":["ROLE_ADMIN" , "ROLE_CE", "ROLE_CRM_ADMIN"],
//     "CREDITLINEPTP":["ROLE_ADMIN" , "ROLE_CE", "ROLE_CRM_ADMIN"],
// }




class ManualApprove extends React.Component {
      constructor (props) {
          super(props);
          this.store = ManualCheckStore
          this.pagination = PaginationStore;
          this.dbounceCaller = debounce(() => this.fetchManualApproveRequest(true));
          this.state = {
              searchText: "",
              searchType: "loanId",
              loading: true,
              hasError: false,
              errorMessage: "",
              filter: {},
              sort: {
                  sortType: "desc",
                  sort: false
              },
              btnStatus:true,
              showResults:false,
              countDetails:{},
              pageNumberValue:"1"
          }
      }

      componentDidMount () {
          this.fetchManualApproveRequest();
          this.callForCount();
      }

      fetchManualApproveRequest ($hasUpdate = false) {
          const { loading, filter, sort, searchText, searchType } = this.state;
          const { page, pageSize } = this.pagination;

          let params = {
              page: page,
              pageSize: pageSize
          }

          if ($hasUpdate) {
              params = {
                  page: 1,
                  pageSize: pageSize
              }
          }

          const updatedFilter = makeFilters(filter, "manualReview")

          if (sort.sort) {
              Object.assign(params, sort);
          }

          Object.assign(params, { filter: updatedFilter.filter });

          if (searchText) {
              Object.assign(params, { "searchText": searchText, "searchType": searchType });
          }

          if (!loading) {
              this.setState({ loading: true });
          }

          postNameReviewLoanListing(
              params,
              (res) => {
                  if (res && res.success) {
                      const { page, totalCount,disbursalReviewList } = res.response;
                      this.store.setData(disbursalReviewList);
                      this.pagination.goToPage(page);
                      this.pagination.updateMaxPage(totalCount);
                      this.setState({ loading: false });
                  } else {
                      this.setState({
                          hasError: true,
                          loading: false
                      })
                  }
              }
          );
      }


    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchManualApproveRequest();
    }

    handleColmList = ()=>{
        const COLS = [
            { key: "loan-rw-id", align: "center", label: "REVIEW ID", value: "id", type: "text", sort: false, fn: (v) => v ?? "-" ,roleValue:["ROLE_MRRO","ROLE_MRRW" ,"ROLE_UW","ROLE_CRM_ADMIN","ROLE_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-loan-id", align: "center", label: "LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v ?? "-" ,roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-name-check", align: "center", label: "NAME MATCH STATUS", value: "nameMatch", type: "text", sort: false, fn: (v) => v?"YES":"NO",roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"] },
            { key: "loan-rw-last-action", align: "center", label: "LAST ACTION ", value: "lastAction", type: "text", sort: false, fn: (v) => v ??"-",roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-reviewed", align: "center", label: "REVIEWED ", value: "reviewed", type: "text", sort: false, fn: (v) => v ?"YES":"NO",roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-doc-check", align: "center", label: "DOC CHECK ", value: "docCheck", type: "text", sort: false, fn: (v) => v ?"YES":"NO",roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-agent", align: "center", label: "AGENT", value: "agent", type: "text", sort: false, fn: (v) => v ??"-",roleValue:["ROLE_MRRO","ROLE_MRRW","ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
            { key: "loan-rw-reset-bank-detail", align: "center", label: "RESET BANK DETAIL", value: "resetBankDetail", type: "resetBankDetail", sort: false, fn: (v) => v ,roleValue:["ROLE_MRRW" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]},
        ]

        const {role}= getLocalStorage("LmsUserDetails");
        const roleAdd =role ;
        const showList = [];
        for (let obj of COLS) {
            for(let item of roleAdd){
                if (obj.roleValue.includes(item)) {
                    showList.push(obj);
                    break;
                    }
            }
        }
        return showList;
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchManualApproveRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

      // search methods

      handleSearch = evt => {
          this.setState({ searchText: evt.target.value });
          this.dbounceCaller();

      }

      handleSearchType = (evt) => {
          this.setState({
              searchType: evt.target.value
          })
      }

      // sort methods
      handleSort = (sortByValue) => {

          const { sort } = this.state;
          let nSort = sort;

          if (!nSort.sort) {
              nSort = {
                  "sortLabel": sortByValue,
                  "sort": sortByValue,
                  "sortType": "desc"
              }
          } else {
              if (nSort.sortLabel === sortByValue) {
                  nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
              } else {
                  nSort = {
                      "sortLabel": sortByValue,
                      "sort": sortByValue,
                      "sortType": "desc"
                  }
              }
          }
          this.setState({
              sort: makeSort(nSort, "underWriting")
          }, () => this.fetchManualApproveRequest(true))
      }

      falsifyError = () => {
          this.setState({
              hasError: false
          })
      }

      // filter bar methods
      handleFilter = data => {
          let tempFilter = Object.assign({}, this.state.filter);
          data.forEach(item => {
              const propertyValue = { [item.property]: item.value };
              Object.assign(tempFilter, propertyValue);
          })

          if (!data.length) {
              tempFilter = {};
          }

          this.updateProperty(tempFilter);
      }

      updateProperty = (_filter) => {
          console.log("updating property...", _filter);
          this.setState({
              filter: _filter
          }, () => this.fetchManualApproveRequest(true))
      }

      removeProperty = (property) => {
          let tempFilter = Object.assign({}, this.state.filter);
          delete tempFilter[property];
          this.updateProperty(tempFilter)
      }

      clearFilter = _ => {
          this.setState({
              filter: {}
          }, () => this.updateProperty())
      }

     handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    callForCount = ()=>{
        getManualCheckCount((res)=>{
            if(res&& res.success){
               this.setState({
                   countDetails:res.response
               })
            }else{
                this.setState({
                    countDetails:{}
                })
            }
        })
    }

    onMouseEnterFunc =()=>{
        this.setState({
            showResults:true
        })
    }

    onMouseLeaveFunc =()=>{
        this.setState({
            showResults:false
        })
    }
    createCounDetatil= (dat) => {
        if (!dat) return [];
        const { todayCount,yesterdayCount,totalCount} = dat;
        return [
            { key: "uwc-today-count",  label: "LEADS TODAY", value:todayCount},
            { key: "uwc-yesterday-count",  label: "LEADS YESTERDAY", value: yesterdayCount},
            { key: "uwc-total-count",  label: "PENDING LEADS", value: totalCount},
        ]
    }


    render () {
          const { loading, hasError, errorMessage, sort, filter,showResults,countDetails,pageNumberValue } = this.state;
          const { data } = this.store;
          const { maxPage, page } = this.pagination;
          return (
              <>
                  <SearchField
                      placeholder={"manualReview"}
                      onSearch={this.handleSearch}
                      onSearchType={this.handleSearchType}
                      search={this.state.searchText}
                      searchType={SEARCH_TYPE_CODES["manualReview"]}
                  />

                  <FilterBar
                      screen={"manualReview"}
                      appliedFilter={filter}
                      callback={{
                          handleFilter: this.handleFilter,
                          removeProperty: this.removeProperty,
                          clearFitler: this.clearFilter
                      }}
                  />

                  <CustomTable
                      {...this.props}
                      cols={this.handleColmList()}
                      data={data}
                      option={[]}
                      loading={loading}
                      count={maxPage}
                      page={page}
                      sort={sort}
                      handlePageChange={this.handlePageChange}
                      handleSort={this.handleSort}
                      pageNumberValue={pageNumberValue}
                      handleGoToPageChange={this.handleGoToPageChange}
                      onChangeGoToValue={this.onChangeGoToValue}
                      pageButton={true}
                  />
                <div style={styles.Detailbtn}>
                    <IconButton
                    style={{
                        fontSize:10,
                        color:"white",
                        backgroundColor:"rgb(16,12,109)"
                    }}
                    onClick={this.onMouseEnterFunc}
                    ><KeyboardArrowLeft fontSize="small"/>
                    </IconButton>
                </div>
                {showResults?
                <FloatingDetail data={this.createCounDetatil(countDetails)} selectedSide={"right"} closeEvent={this.onMouseLeaveFunc} />
                :null}
                  <Alert
                      open={hasError}
                      message={errorMessage}
                      callback={this.falsifyError}
                  />
              </>
          );
      }
  }

  const styles = {
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute",
        left:10,

    },
    Detailbtn:{
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute",
        bottom:5,
        right:15
    }
 }

  export default observer(ManualApprove);