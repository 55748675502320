import React from "react";
import {
    Popover
} from "@material-ui/core";

// const defaultPopoverProps = {
//     anchorOrigin: {
//         vertical: "bottom",
//         horizontal: "center"
//     },
//     transformOrigin: {
//         vertical: "top",
//         horizontal: "center"
//     }
// }

class CustomPopover extends React.Component {
    render() {
        const {  children, handleClose, anchorEl, isOpen } = this.props;
        return (
            <Popover
                id={"custom-popover"}
                open={isOpen}
                anchorEl={anchorEl}
                elevation={4}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >{children}</Popover>
        );
    }
}

export default CustomPopover;