import React from "react";
import {
    IconButton,
    Button
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import ViewImage from "./viewImage";

import Slider from '@material-ui/core/Slider';
import Grid from '@material-ui/core/Grid';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { Colors } from "../styles";
// const defaultImageProps = {
//     src: imageNotAvailable,
//     alt: 'Image not availble',
//     width: 250,
//     height: 200
// }
const marks = [
    { value: 0, label: '0°'},
    { value: 45, label: '45°'},
    { value: 90, label: '90°'},
    { value: 135, label: '135°'},
    { value: 180, label: '180°'},
    { value: 225, label: '225°'},
    { value: 270, label: '270°'},
    { value: 315, label: '315°'},
    { value: 360, label: '360°'},
  ];

class CustomImage extends React.Component {
    constructor(props){
        super(props);
        this.state={
            isImageZoom:false,
            rotationValue:0
        }
    }

    handleZoomOn = ()=>{
        this.setState({
            isImageZoom:true
        })
    }

    handleZoomOff = () => {
        this.setState({
            isImageZoom:false
        })
    }

    valuetext =(value)=> {
        return `${value}`;
    }

    handleSliderValue = (evt,value)=>{
        this.setState({
            rotationValue:value
        })
    }
    render() {
        const {item,handleClose} = this.props;
        const { isImageZoom,rotationValue} = this.state;
        return (
            <>
             <div style={styles.rootContainer}>
                    <IconButton variant="contained"
                        style={styles.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                <div style={{
                     display: 'flex',
                     justifyContent:'center',
                     alignItems:'center',
                     height: '50vh',
                     width:"100%"
                }}>
                <ViewImage
                    alt= {`${item.label} _not_found` }
                    src={item.value}
                    isZoomed= {isImageZoom}
                    handleZoomChange= {() => this.handleZoomOff()}
                    styleProp={
                        {
                            transform: `rotate(${rotationValue}deg)`,
                            border: '2px solid',
                            borderRadius: 6,
                            objectFit: 'contain',
                            with:300,
                            height:300
                        }
                    }
                    />
                </div>
                <Grid
                   container
                   spacing={3}
                   style={{
                    padding:30,
                  }}
                >
                    <Grid item  xs={6} style={{ display: 'flex',justifyContent:'center', alignItems:'center', }}>
                        <Button  style={{ background:Colors.primary, color:"white", padding:8 }} onClick={()=>{this.handleZoomOn()}}>
                            Zoom In
                            <ZoomInIcon />
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{width: "100%"}}>
                            <Slider
                                defaultValue={0}
                                getAriaValueText={this.valuetext}
                                onChange={this.handleSliderValue}
                                aria-labelledby="discrete-slider-always"
                                marks={marks}
                                step={1}
                                valueLabelDisplay="on"
                                max={360}
                                style={{
                                    color:Colors.primary,
                                    fontWeight:"bold"
                                }}
                            />
                        </div>
                    </Grid>
                </Grid>
             </div>
            </>
        );
    }
}

export default CustomImage;

const styles = {
    imageStyle: {
        border: '2px solid',
        borderRadius: 6,
        objectFit: 'contain',
    },
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5% 10%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}


