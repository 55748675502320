import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import { getLocalStorage } from "../utils/localStorage";

import AddRole from "./../screens/role/addRole";


const ROLE_VALUES = {
    "ADMIN":["ROLE_ADMIN","ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "USERS":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN","ROLE_ADMIN_RO"],
    "LOANS":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN","ROLE_ADMIN_RO"],
    "CREDITLINES":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_WAIVER", "ROLE_CCE", "ROLE_CE", "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_CAMPAIGN","ROLE_ADMIN_RO"],
    "WAIVERS":["ROLE_ADMIN" , "ROLE_WAIVER", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "MANUALCHECK":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "UNDERWRITING":["ROLE_ADMIN" ,"ROLE_UW", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "SETTLEMENT":["ROLE_ADMIN" , "ROLE_WAIVER", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "CAMPAIGNS":["ROLE_ADMIN" , "ROLE_CRM_ADMIN","ROLE_CAMPAIGN","ROLE_ADMIN_RO"],
    "DEDUCTION":["ROLE_ADMIN" , "ROLE_DP", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "LOANPTP":["ROLE_ADMIN" , "ROLE_CE", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "CREDITLINEPTP":["ROLE_ADMIN" , "ROLE_CE", "ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "EDITROLE":["ROLE_CRM_ADMIN","ROLE_ADMIN_RO"],
    "ADDROLE":["ROLE_CRM_ADMIN","ROLE_ADMIN_RO"]
}

function RestrictedPage(props){
    return (<>
    <div
        style={{
           margin:"210px 540px 400px 540px",
           fontSize:40,
           color:"red"
        }}
    >
        You are restricted to visit this page
    </div>
     {
        setTimeout(() => {
            const { history } = props;
            history.goBack();
        }, 1000)
     }
    </>)
}

function AddRoute() {
    const res= getLocalStorage("LmsUserDetails");
    const { role} = res;


    const checkPermission = (PERMISSIONS)=>{
        let conditionCheck = false;
            for(let item of PERMISSIONS){
                if(role.includes(item)){
                    conditionCheck=true;
                    break;
                }
        }
        
        return conditionCheck;
    }

    if(checkPermission(ROLE_VALUES.ADDROLE)){
        return (<>
        <Router >
            <Route exact path="/home/role/addRole" component={AddRole} />
        </Router>
        </>)
    }else{
        return (<>
            <Router >
                <Route path="/restrictedPage" component={RestrictedPage} />
                    <Route path="*"  >
                        <Redirect to="/restrictedPage" />
                    </Route>
            </Router>
            </>)
    }
}

export default AddRoute;