import React from "react";
import {
    Typography,
    Button,
    TextField,
    IconButton,
} from "@material-ui/core";
import {REGEX} from "../../common/regex";
import Alert from "../alert";

import CloseIcon from '@material-ui/icons/Close';
import { postWaiverRequestCreditline, postWaiverRequestLoan } from "../../service/api";

class CreateWaiver extends React.Component{
    constructor(props){
        super(props);
        this.state={
            hasError:"",
            errorType:"",
            errorMessage:"",
            waiveOffAmount:"",
            promiseAmount:"",
            typeOfFee:"",
            reason:"",
            promiseDate:"",
            errors:{}
        }
    }

    handleField = (event) => {
        const { id,value } = event.target;
        this.setState({
            [id]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    loadModuleFilter = () => {
        const comps = [];
        const { waiveOffAmount, promiseAmount, reason,promiseDate,errors}= this.state;
        const dispatchfields = [
            { id:"waiveOffAmount",type:"number",name:"waveOffAmount",label:"WAIVE OFF AMOUNT ",value:waiveOffAmount,isSelect:false,option:[],isDisable:false,isMultiline:false},
            { id:"promiseAmount",type:"number",name:"promiseAmount",label:"PROMISE AMOUNT",value:promiseAmount,isSelect:false,option:[],isDisable:false,isMultiline:false},
           // { id:"typeOfFee",type:"text",name:"typeOfFee",label:"TYPE OF FEE",value:typeOfFee,isSelect:false,option:[],isDisable:false,isMultiline:false},
            { id:"reason",type:"text",name:"reason",label:"REASON",value:reason,isSelect:false,option:[],isDisable:false,isMultiline:false},
            { id:"promiseDate",type:"date",name:"promiseDate",label:"PROMISE DATE",value:promiseDate,isSelect:false,option:[],isDisable:false,isMultiline:false},
        ]
        for (let obj of dispatchfields) {
                const { id,type,label ,value,multiline,isSelect,isDisable ,option} = obj;
                let TextFieldProps = {
                    id: id,
                    key: id,
                    disable:isDisable,
                    multiline:multiline,
                    name: id,
                    select: isSelect,
                    label: label,
                    type: type,
                    value: value,
                    defaultValue: isSelect ? option[0]: "",
                    isSelect: isSelect,
                    option: option,
                    helperText:errors[id]
                }
                comps.push(TextFieldProps);
        } return comps;
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { amount_regex  } = REGEX;
        const { waiveOffAmount,promiseAmount,reason,promiseDate} = this.state;
        const { sumOfWaive} = this.props;

        if (!waiveOffAmount) {
            formIsValid = false;
            errorsTemp["waiveOffAmount"] = "Cannot be empty";
        } else if (!amount_regex.test(waiveOffAmount)) {
            formIsValid = false;
            errorsTemp["waiveOffAmount"] = "Please enter valid amount";
        }
        // else if(!(waiveOffAmount<=sumOfWaive)){
        //     formIsValid = false;
        //     errorsTemp["waiveOffAmount"] = `Waive Off amount should be less than ${sumOfWaive}`;
        // }
        if (!promiseAmount) {
            formIsValid = false;
            errorsTemp["promiseAmount"] = "Cannot be empty";
        } else if (!amount_regex.test(promiseAmount)) {
            formIsValid = false;
            errorsTemp["promiseAmount"] = "Please enter valid amount";
        }

        if (!promiseDate) {
            formIsValid = false;
            errorsTemp["promiseDate"] = "Please mention promise date";
        }
        if (!reason) {
            formIsValid = false;
            errorsTemp["reason"] = "Cannot be empty";
        }


        this.setState({ errors: errorsTemp });
        return formIsValid;
    }


    createWaiver= e => {
        const { handleClose ,typeOfWaiver} = this.props;
        const { waiveOffAmount, promiseAmount,reason,promiseDate}= this.state;
        const { LOAN_APPLICATION_ID}=this.props;
        console.log(LOAN_APPLICATION_ID)

        if(typeOfWaiver === "LOAN_WAIVER"){
            let finalDetails = {
                "waiveOffAmount":waiveOffAmount,
                "promiseAmount":promiseAmount,
                "reason":reason,
                "loanId":LOAN_APPLICATION_ID,
                "promiseDate":promiseDate
            }
            e.preventDefault();
                if (this.handleValidation()) {
                    postWaiverRequestLoan(finalDetails,(res)=>{
                       if(res&&res.success){
                        this.setState({
                            hasError:true,
                            errorMessage:res.message,
                            errorType:"success"
                        })
                        setTimeout(() => { handleClose() }, 1500);
                        this.setToDefault()
                       }else {
                        this.setState({
                            hasError:true,
                            errorMessage:res?res.message:"",
                            errorType:"error"
                        })
                    }
                    })
                } else {
                    this.setState({
                        hasError:true,
                        errorMessage:"Please fill the details correctly ",
                        errorType:"error"
                    })
                }

        }else{
            let finalDetails = {
                "waiveOffAmount":waiveOffAmount,
                "promiseAmount":promiseAmount,
                "reason":reason,
                "applicationId":LOAN_APPLICATION_ID,
                "promiseDate":promiseDate
            }
            e.preventDefault();
                if (this.handleValidation()) {
                    postWaiverRequestCreditline(finalDetails,(res)=>{
                       if(res&&res.success ){
                        this.setState({
                            hasError:true,
                            errorMessage:res.message,
                            errorType:"success"
                        })
                        setTimeout(() => { handleClose() }, 1500);
                        this.setToDefault()
                       }else {
                        this.setState({
                            hasError:true,
                            errorMessage:res?res.message:"",
                            errorType:"error"
                        })
                    }
                    })
                } else {
                    this.setState({
                        hasError:true,
                        errorMessage:"Please fill the details correctly ",
                        errorType:"error"
                    })
                }
        }


    }

    setToDefault=()=>{
        this.setState({
            waiveOffAmount:"",
            promiseAmount:"",
            typeOfFee:"",
            reason:"",
            errors:{}
        })
    }
    render(){
        const {hasError,errorType,errorMessage}= this.state;
        const { handleClose } = this.props;

        const comps = this.loadModuleFilter();
        return (
            <>
            <div style={styles.backContainer}>
                <div style={styles.rootContainer}>
                    <Typography
                        style={styles.title}
                        variant="h6"
                        align={"center"}>CREATE WAIVER</Typography>
                    {comps.map(comp => {
                        const { id, name, type, isSelect, label, option, value ,helperText} = comp;
                        return <TextField
                            id={id}
                            key={id}
                            label={label}
                            name={name}
                            type={type}
                            select={isSelect}
                            style={styles.textField}
                            value={value}
                            defaultValue={isSelect ? option[0] : null}
                            // size="small"
                            InputLabelProps={{
                                shrink: true
                            }}
                            helperText={helperText}
                            onChange={(evt) => this.handleField(evt, {
                                label: label
                            })}
                            SelectProps={{
                                MenuProps: {
                                    MenuListProps: {
                                        style: {
                                            maxHeight: 210
                                        }
                                    }
                                }
                            }}

                            FormHelperTextProps={{
                                style: {
                                    color: "red",
                                    fontSize: 12,
                                    marginLeft: "-1px"
                            }
                            }}
                            variant="outlined"
                    >{isSelect ? this.loadOptions(option) : null}</TextField>
                    })}
                    <div style={styles.actionBtnContainer}>
                        <Button
                            variant="contained"
                            style={{ ...styles.applyBtn, ...styles.commonBtn }}
                            disableElevation
                            disableFocusRipple
                            onClick={this.createWaiver}
                        >CREATE</Button>
                    </div>
                </div>
                <IconButton variant="contained"
                    style={styles.closeBtn}
                    onClick={handleClose}
                ><CloseIcon fontSize={"large"} /></IconButton>
            </div>
            <Alert
                open={hasError}
                message={errorMessage}
                type={errorType}
                callback={this.falsifyError}
            />
            </>
            )
    }
}

const styles = {
    backContainer: {
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    closeBtn: {
        position: "relative",
        background: "transparent",
        color: "#fff",
        bottom: 230
    },
    rootContainer: {
        height: "auto",
        display: "inherit",
        alignItems: "center",
        flexDirection: "column",
        width: 350,
        borderRadius: 6,
        background: "#fff",
        position: "relative",
    },

    title: {
        marginTop: 30,
        marginBottom: 10,
        fontFamily: "Poppins"
    },
    textField: {
        margin: 20,
        width: 250
    },
    actionBtnContainer: {
        display: "inline-block",
        // position: "absolute",
        bottom: 0
    },
    commonBtn: {
        margin: "30px 25px",
        width: 150
    },
    clearBtn: {

    },
    applyBtn: {
        background: "#1B2D93",
        color: "#fff",
        padding:10,
    }
}

export default CreateWaiver;