import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";

import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
// import SimpleTable from "../../components/simpleTable";
import { getCreditLineStatement } from "../../service/api";
import Alert from "../../components/alert";
// import { convertTimestamptoDate } from "../../utils/helper";
import BilledStatement from "../../components/creditLine/billedStatement";
import UnbilledStatement from "../../components/creditLine/unbilledStatement";


class CreditLineStatement extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            creditLineStatementData: null,
        }
    }

    componentDidMount () {
        this.fetchStatement();
    }

    fetchStatement = () => {

        const { id } = this.props;

        getCreditLineStatement(id, {},
            (res) => {
                if (res.success) {
                    this.setState({
                        loading: false,
                        creditLineStatementData: res.response
                    })
                } else {
                    this.setState({
                        hasError: true,
                        loading: false,
                        creditLineStatementData: { unBilled: [], billed: [] }
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, hasError, errorMessage, creditLineStatementData } = this.state;
        const { classes, handleClose ,id} = this.props;
        let UNBILLED = [], BILLED = [];
        if (creditLineStatementData) {
            const { unBilled, billed } = creditLineStatementData;
            UNBILLED = unBilled;
            BILLED = billed;
        }
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    > <CloseIcon /> </IconButton>

                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    > Statement </Typography>
                    <UnbilledStatement transactions={UNBILLED} loading={loading} />
                    <BilledStatement  data={BILLED} loading={loading} applicationId={id}/>
                </div>

                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll",
        height: 'calc(100vh - 220px)'
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    },
    extandAccordion: {
        background: "#fff",
        outline: "none",
        padding: 5,
        borderRadius: 6,
        margin: "5px",
        overflowY: "scroll"
    },
    extandAccordionSummary: {
        color: "green"
    },
    extandAccordionDetail: {
        background: "#fff",
        outline: "none",
        padding: 5,
        borderRadius: 6,
        margin: "5px",
        overflowY: "scroll"
    }
}

export default withStyles(styles)(CreditLineStatement);