import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { getLoanSettlementSummary } from "../../service/api";
import Alert from "../../components/alert";
import Skeleton from '@material-ui/lab/Skeleton';

const COLS = [
    { key: "ls-loan-amount", align: "left", label: "LOAN AMOUNT", value: "loanAmount", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "ls-total-outstanding", align: "left", label: "TOTAL OUTSTANDING", value: "totalOutstanding", type: "text", sort: false, fn: (v) => v ??"-"},
    { key: "ls-total-amount-paid", align: "left", label: "TOTAL AMOUNT PAID", value: "totalAmountPaid", type: "text", sort: false, fn: (v) => v  ??"-"},
    { key: "ls-principle-amount", align: "left", label: "PRINCIPLE AMOUNT (Principle + Interest)", value: "principleAmount", type: "text", sort: false, fn: (v) => v  ?? '-' },
    { key: "ls-waiver-amount", align: "left", label: "WAIVER AMOUNT (Bounce fee + Late fee)", value: "waiverAmount", type: "text", sort: false, fn: (v) => v ?? '-' },
]

class LoanSettlementSummary extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: "",
        }
    }

    componentDidMount() {
        this.fetchSettlementSummary();
    }

    fetchSettlementSummary = () => {

        const { id } = this.props;

        getLoanSettlementSummary(id, 
            (res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        data: res.response
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >Loan Settlement Summary</Typography>
                    <div>
                   {!loading?<>
                        <table style={{
                            width:"95%",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            margin:"20px 0px 0px 10px",
                            }}>
                            {COLS.map((item,key)=>{
                                return <>
                                <tr>
                                    <th style={styles.thStyle}>{item.label}</th>
                                    <td style={styles.tdStyle}>{data[item.value]?`₹ ${data[item.value]}`:"-"} </td>
                                </tr>
                                </>
                            })}  
                        </table>
                        </>:<>
                        <div style={{width:"100%"}}>
                            <Skeleton />
                            <Skeleton animation={false} />
                            <Skeleton animation="wave" />
                            
                        </div>
                        </>}
                   </div> 
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 20,
        borderRadius: 6,
        margin: "5% 30%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "15px 5px 15px"
    },
    closeBtn: {
        float: "right"
    },
    thStyle:{
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: 5,
        textAlign: "left",
        fontWeight:450
    },
    tdStyle:{
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: 5,
        textAlign: "left"
    }
}

export default withStyles(styles)(LoanSettlementSummary);