import React from "react";
import Detail from "./../../components/detail";
import { findByPathName } from "./../../utils/helper";
import { getCreditLineApplicationInfo, getLoanApplicationInfo,  getLoanInfo,  getLoanUserInfo, getWaiverInfo,  postWaiverAcceptRejectCreditLine,  postWaiverAcceptRejectLoan } from "./../../service/api";
import { convertTimestamptoDate } from "./../../utils/helper";

import { LOAN_REASON_CODES, rolePermission, WAIVER_REQUEST_STATUS } from "../../common/constants";
import { 
    Button,
    IconButton,
 } from "@material-ui/core";
import FloatingDetail from "../../components/floatingDetails";
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import ActionDialogBox  from "../../components/actionDialog"
import Alert from "../../components/alert";
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';

class WaiverDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            errorType: "",
            data: [],
            loanDetails: {},
            loanUserDetails: {},
            loanApplicationDetails: {},
            loanWaiverDetail:{},
            showResults:false,
            actionDialogBoxIsOpen:false,
            actionType:null,
            checkActive:false,
            clApplicationDetails:{},
            lmsWaiverType:"",
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const requestID = findByPathName(location);
        this.callApis(requestID);
    }


    callApis = (id) => {

        getWaiverInfo(id, {}, (res)=>{
            if(res){
                const { waiverType,waiverRequestId,waiverStatus}= res;
                this.setState({
                    loanWaiverDetail:res,
                    checkActive:waiverStatus===0?true:false,
                    waiverRequestId:waiverRequestId,
                    lmsWaiverType:waiverType,
                    waiverStatus:waiverStatus
                })
                 if(waiverType==="loan"){
                    const { loanId}= res;
                    const getLoanInfoPromise = new Promise(function (resolve, reject) {
                        getLoanInfo(loanId, {}, res => resolve(res))
                    })
                    const getLoanUserInfoPromise = new Promise(function (resolve, reject) {
                        getLoanUserInfo(loanId, {}, res => resolve(res))
                    })
                    const getLoanApplicationInfoPromise = new Promise(function (resolve, reject) {
                        getLoanApplicationInfo(loanId, {}, res => resolve(res))
                    })
            
                    Promise.all([
                        getLoanInfoPromise,
                        getLoanUserInfoPromise,
                        getLoanApplicationInfoPromise,
                    ]).then(res => {
                        this.setState({
                            loanDetails: res[0],
                            loanUserDetails: res[1],
                            loanApplicationDetails: res[2],
                            id: loanId
                        }, () => this.makeColumns("loan"))
                    }).catch(err => {
                        this.setState({
                            hasError: true,
                            loading: false,
                            id: loanId
                        })
                    })
                 }else{
                    const { applicationId}= res;
                    const getCreditLineApplicationInfoPromise = new Promise((resolve, reject) => {
                        getCreditLineApplicationInfo(applicationId, {}, res => resolve(res))
                    })
            
                    Promise.all([
                        getCreditLineApplicationInfoPromise,
                    ]).then(res => {
                        this.setState({
                            clApplicationDetails: res[0],
                            id: applicationId
                        }, () => this.makeColumns("cl"))
                    }).catch(err => {
                        this.setState({
                            hasError: true,
                            loading: false,
                            id: id
                        })
                    })
                 }
            }else{
                this.setState({
                    hasError: true,
                })
            }
        })

        
    }
   

    createLoanApplicationDetail = (dat) => {

        if (!dat) return [];

        const { accountCreationDate, firstLogin, lastLogin, applicationCompletionDate, documentUploadDate, tncAcceptDate } = dat;
        return [
            { key: "account-create-date", label: "ACCOUNT CREATION DATE", value: convertTimestamptoDate(accountCreationDate) },
            { key: "first-login", label: "FIRST LOGIN", value: convertTimestamptoDate(firstLogin) },
            { key: "last-login", label: "LAST LOGIN", value: convertTimestamptoDate(lastLogin) },
            { key: "app-complete-date", label: "APPLICATION COMPLETION DATE", value: convertTimestamptoDate(applicationCompletionDate) },
            { key: "doc-upload-date", label: "DOCUMENTS UPLOAD DATE", value: convertTimestamptoDate(documentUploadDate) },
            { key: "tnc-accept-date", label: "T&C ACCEPT DATE", value: convertTimestamptoDate(tncAcceptDate) }
        ]
    }

    createLoanDetail = (dat) => {

        if (!dat) return [];

        const { loanId, loanStatus, loanType, appliedLoanAmount, approvedLoanAmount, outstandingPrinciple, reasonCode, dpdCount, lender, outstandingAmount, bankTransfer } = dat;
        return [
            { key: "loan-id", label: "LOAN ID", value: loanId },
            { key: "loan-status", label: "LOAN STATUS", value: loanStatus },
            { key: "loan-type", label: "LOAN TYPE", value: loanType },
            { key: "applied-loan-amount", label: "APPLIED LOAN AMOUNT", value: appliedLoanAmount },
            { key: "approved-loan-amount", label: "APPROVED LOAN AMOUNT", value: approvedLoanAmount },
            { key: "outstanding-amount", label: "OUTSTANDING AMOUNT", value: outstandingAmount },
            { key: "outstanding-principle", label: "OUTSTANDING PRINCIPAL", value: outstandingPrinciple },
            { key: "reason-code", label: "REASON CODE", value: reasonCode },
            { key: "dpd-count", label: "DPD COUNT", value: dpdCount },
            { key: "lender", label: "LENDER", value: lender },
            { key: "bank-transfer", label: "BANK TRANSFER", value: bankTransfer ? 'YES' : 'NO' }
        ]
    }

    createWaiverDetail= (dat) => {

        if (!dat) return [];
        const { waiverRequestId, createdDate, lastUpdated,  waiverStatus,agent,loanId,promiseAmount,promiseDate,applicationId,
                statementId,waiveOffAmount} = dat;
        return [
            { key: "waiver-id",  label: "REQUEST ID", value: waiverRequestId},
            { key: "waiver-created-date",  label: "CREATED DATE", value: convertTimestamptoDate(createdDate)},
            { key: "waiver-last-updated",  label: "LAST UPDATED", value: convertTimestamptoDate(lastUpdated)},
            { key: "waiver-active",  label: "WAIVER STATUS", value: WAIVER_REQUEST_STATUS[waiverStatus] },
            { key: "waiver-agent-name",  label: "AGENT", value: agent},
            { key: "waiver-loanId-applicationId",  label: loanId?"LOAN ID":"APPLICATION ID", value: loanId?loanId:applicationId},
            { key: "waiver-promise-amount",  label: "PROMISE AMOUNT", value: promiseAmount},
            { key: "waiver-promise-date",  label: "PROMISE DATE", value: promiseDate},
            { key: "waiver-statementId",  label: "STATEMENT ID", value: statementId},
            //{ key: "waiver-typeOfFee",  label: "TYPE OF FEE", value: "typeOfFee", type: "text", sort: false, fn: (v) => v },
            { key: "waiver-waiveOffAmount",  label: "WAIVE OFF AMOUNT",value:waiveOffAmount},
        ]
    }

    createUserDetail = (dat) => {

        if (!dat) return [];
       
        const { name, phone, company, nameOnPan, alternatePhone } = dat;
        return [
            { key: "name", label: "NAME", value: name },
            { key: "phone", label: "PHONE", value: phone },
            { key: "company", label: "COMPANY", value: company },
            { key: "nameOnPan", label: "NAME ON PAN", value: nameOnPan },
            { key: "alternatePhone", label: "ALTERNATE PHONE", value: alternatePhone }
        ]
    }

    createPaymentDetail = () => {
        return [
            { key: "loan-payment-detail", label: "PAYMENT", value: 'View', fn: (v) => v },
            { key: "loan-statement-detail", label: "STATEMENT", value: 'View', fn: (v) => v },
            { key: "all-loan-payment-detail", label: "ALL PAYMENT", value: 'View', fn: (v) => v },
           // { key: "loan-waiver-detail", label: "WAIVER", value: 'View', fn: (v) => v },
        ]
    }

   
    createEmandateCharges = () => {
        return [
            { key: "emandate-charge-history", label: "EMANDATE CHARGES", value: 'View', fn: (v) => v }
        ]
    }

    createApplicationDetail = (dat) => {
        if (!dat) return [];
        const showReference = ref => {
            return String('Name ' + (ref[0].name ?? '-') + '\nPhone ' + (ref[0].phone ?? '-'));
        }
        const { applicationId, name, phone, reference1, reference2, company,  creditLineStatus,  nameOnPan,
             approvedAmount, remainingAmount, accountCreationDate,  applicationCompleteDate, documentUploadDate,
            tncAcceptDate, outstandingBalance, dueAmount, reasonCode } = dat;
        return [
            { key: "credit-line-application-id", label: "APPLICATION ID ", value: applicationId },
            { key: "credit-line-name", label: "NAME", value: name },
            { key: "credit-line-phone", label: "PHONE", value: phone },
            { key: "credit-line-reference1", label: "REFERENCE 1", value: showReference(reference1) },
            { key: "credit-line-reference2", label: "REFERENCE 2", value: showReference(reference2) },
            { key: "credit-line-company", label: "COMPANY", value: company },
            { key: "credit-line-status", label: "CREDIT LINE STATUS", value: creditLineStatus },
            { key: "credit-line-name-on-pan", label: "NAME ON PAN", value: nameOnPan },

            { key: "credit-line-approved-amount", label: "APPROVED AMOUNT", value: approvedAmount },
            { key: "credit-line-remaining-amount", label: "REMAINING AMOUNT", value: remainingAmount },
            { key: "credit-line-outstanding-balance", label: "OUTSTANDING BALANCE", value: outstandingBalance },
            { key: "credit-line-due-amount", label: "DUE AMOUNT", value: dueAmount },
            { key: "credit-line-account-creation-date", label: "ACCOUNT CREATION DATE", value: convertTimestamptoDate(accountCreationDate) },
            { key: "credit-line-application-complete-date", label: "APPLICATION COMPLETE DATE", value: convertTimestamptoDate(applicationCompleteDate) },
            { key: "credit-line-document-upload-date", label: "DOCUMENT UPLOAD DATE", value: convertTimestamptoDate(documentUploadDate) },
            { key: "credit-line-tnc-accept-date", label: "T&C ACCEPT DATE", value: convertTimestamptoDate(tncAcceptDate) },
            { key: "credit-line-reason-code", label: "REASON CODE", value: LOAN_REASON_CODES[reasonCode] },

        ]
    }
    createViewDetail = () => {
        return [
            { key: "credit-line-statement-detail", label: "STATEMENT", value: 'View', fn: (v) => v },
            { key: "credit-line-emandate-detail", label: "EMANDATE", value: 'View', fn: (v) => v },
            { key: "credit-line-payment", label: "PAYMENT", value: 'View', fn: (v) => v }
        ]
    }

    checkViewPermission = (key) => {
        const role = "roleAdmin";
        return rolePermission[role].includes(key);
    }



    makeColumns = (type) => {
        const { loanDetails, loanUserDetails, loanApplicationDetails ,clApplicationDetails} = this.state;
        var detailData = []
        if(type==="loan"){
          detailData=  [
                { key: "user-detail", label: loanUserDetails?"USER DETAIL":"", value: this.createUserDetail(loanUserDetails), viewPermission: this.checkViewPermission("user-detail") },
                { key: "loan-detail", label: loanDetails?"LOAN DETAIL":"", value: this.createLoanDetail(loanDetails), viewPermission: this.checkViewPermission("loan-detail") },
                { key: "loan-application-detail", label: loanApplicationDetails?"LOAN APPLICATION DETAIL":"", value: this.createLoanApplicationDetail(loanApplicationDetails), viewPermission: this.checkViewPermission("loan-application-detail") },
                { key: "loan-emandate-charges", label: "EMANDATE CHARGES", value: this.createEmandateCharges(), viewPermission: this.checkViewPermission("loan-emandate-charges") },
                { key: "view-payment-detail", label: "PAYMENT DETAIL", value: this.createPaymentDetail(), viewPermission: this.checkViewPermission("view-payment-detail") },
            ]
        }else{
            detailData=  [
                { key: "credit-line-app-detail-col", label: clApplicationDetails ? "APPLICATION DETAIL" : "", value: this.createApplicationDetail(clApplicationDetails), viewPermission: this.checkViewPermission("credit-line-app-detail-col") },
                { key: "view-click-detail", label: "VIEWS", value: this.createViewDetail(), viewPermission: this.checkViewPermission("view-click-detail") }
            ]
        }
        this.setState({ data: detailData })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    onMouseEnterFunc =()=>{
        this.setState({
            showResults:true
        })
    }

    onMouseLeaveFunc =()=>{
        this.setState({
            showResults:false
        })
    }
    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    floatingActionDetails = ()=>{
       const { showResults } = this.state;
       return (
        <>  
            <div style={{
                display:showResults?"none":"flex"
            }}>
                <IconButton 
                style={styles.detailHover}
                onClick={this.onMouseEnterFunc}
                ><KeyboardArrowLeftIcon fontSize="small"/>
                </IconButton>
            </div>
       </>)
    }


    handleWaiverApprove =()=>{
        const { waiverRequestId,lmsWaiverType}= this.state;
        if(lmsWaiverType==="loan"){
            postWaiverAcceptRejectLoan(waiverRequestId,{"waiverStatus":1},(res)=>{
                if(res&&res.success){
                    this.setState({
                        hasError:true,
                        errorType:"success",
                        errorMessage:res.message
                    },()=>{
                       setTimeout(() => {
                        this.dialogBoxHandleClose()
                        window.location.reload(false);
                       }, 1200);      
                    })
                }else {
                    this.setState({
                        hasError:true,
                        errorType:"error",
                        errorMessage:res?res.message:""
                    },()=>{
                        setTimeout(() => {
                         this.dialogBoxHandleClose()
                        }, 1200);      
                     })
                }
            })
        }else{
            postWaiverAcceptRejectCreditLine(waiverRequestId,{"waiverStatus":1},(res)=>{
                if(res&&res.success){
                    this.setState({
                        hasError:true,
                        errorType:"success",
                        errorMessage:res.message
                    },()=>{
                       setTimeout(() => {
                        this.dialogBoxHandleClose()
                        window.location.reload(false);
                       }, 1200);      
                    })
                }else {
                    this.setState({
                        hasError:true,
                        errorType:"error",
                        errorMessage:res?res.message:""
                    },()=>{
                        setTimeout(() => {
                         this.dialogBoxHandleClose()
                        }, 1200);      
                     })
                }
            })
        }
        
     
    }

    handleWaiverReject =()=>{
        const { waiverRequestId,lmsWaiverType }= this.state;

        if(lmsWaiverType==="loan"){
            postWaiverAcceptRejectLoan(waiverRequestId,{"waiverStatus":2},(res)=>{
                if(res&&res.success){
                    this.setState({
                        hasError:true,
                        errorType:"success",
                        errorMessage:res.message
                    },()=>{
                       setTimeout(() => {
                        this.dialogBoxHandleClose()
                        window.location.reload(false);
                       }, 1200);      
                    })
                }else {
                    this.setState({
                        hasError:true,
                        errorType:"error",
                        errorMessage:res.messageres?res.message:""
                    },()=>{
                        setTimeout(() => {
                         this.dialogBoxHandleClose()
                        }, 1200);      
                     })
                }
            })
        }else{
            postWaiverAcceptRejectCreditLine(waiverRequestId,{"waiverStatus":2},(res)=>{
                if(res&&res.success){
                    this.setState({
                        hasError:true,
                        errorType:"success",
                        errorMessage:res.message
                    },()=>{
                       setTimeout(() => {
                        this.dialogBoxHandleClose()
                        window.location.reload(false);
                       }, 1200);      
                    })
                }else {
                    this.setState({
                        hasError:true,
                        errorType:"error",
                        errorMessage:res?res.message:""
                    },()=>{
                        setTimeout(() => {
                         this.dialogBoxHandleClose()
                        }, 1200);      
                     })
                }
            })
        }
       
    }

    setDialogBox =(type)=>{
        const {actionDialogBoxIsOpen} = this.state;
        switch(type){
            case "approve":
                return <ActionDialogBox 
                title="Are sure to accept this waiver request"
                handleAgree={()=>this.handleWaiverApprove()}
                handleDisagree={()=>this.dialogBoxHandleClose()}
                description=""
                actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                agreeName="APPROVE"
                disagreeName="CLOSE"
                loader={false}
                   />
                    
            case "reject" :
                 return <ActionDialogBox 
                 title="Are sure to reject this waiver request"
                 handleAgree={()=>this.handleWaiverReject()}
                 handleDisagree={()=>this.dialogBoxHandleClose()}
                 description=""
                 actionDialogBoxIsOpen={actionDialogBoxIsOpen}
                 agreeName="REJECT"
                 disagreeName="CLOSE"
                 loader={false}
                    /> 
         
            default :
                return ""
         }
    }

    dialogBoxHandleOpen = (type)=>{
     this.setState({
           actionDialogBoxIsOpen:true,
           actionType:type
     })
    }

    dialogBoxHandleClose = ()=>{
        this.setState({
            actionDialogBoxIsOpen:false,
        })
    }

    render () {
        const { loading, data, id,loanWaiverDetail ,showResults,actionType,actionDialogBoxIsOpen,checkActive,
        hasError,errorMessage,errorType,waiverStatus} = this.state;
        const tempData =this.createWaiverDetail(loanWaiverDetail)
        return (
            <>
            <Detail
                data={data}
                loading={loading}
                handleBack={this.handleBack}
                id={id}
            />
            {this.floatingActionDetails()}
            { showResults ? <FloatingDetail data={tempData} closeEvent={this.onMouseLeaveFunc} /> : null }
            
             <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                    duration="5000"
                />


            {checkActive?
            <>
                <div className="mybutton">
                    <Button
                        variant="contained"
                        color="default"
                        className="feedback"
                        style={{backgroundColor:"green",margin:10,color:"white"}}
                        startIcon={<DoneIcon  />}
                        onClick={()=>{
                            this.dialogBoxHandleOpen("approve")
                        }} 
                    >
                        Approve
                    </Button>
                    <Button
                        variant="contained"
                        color="default"
                        className="feedback"
                        style={{backgroundColor:"red",margin:10,color:"white"}}
                        startIcon={<ClearIcon  />}
                        onClick={()=>{
                            this.dialogBoxHandleOpen("reject")
                        }} 
                    >
                        Reject
                    </Button>
                </div>
            </>:<><div style={styles.elseBtn}>WAIVER STATUS : {WAIVER_REQUEST_STATUS[waiverStatus]}</div></>}
             {actionDialogBoxIsOpen?this.setDialogBox(actionType):null}
            </>
        );
    }
}
const styles ={
    btnContainer: {
        position: 'absolute',
        bottom:"11%",
        right: ".5%",
        background:"#00AA4A",
        color:"white",
        margin:20
    },
    btnContainerReject:{
        position: 'absolute',
        bottom:"3%",
        right: ".5%",
        background:"red",
        color:"white",
        margin:20
    },
    detailHover:{
        position: 'absolute',
        top:85,
        right: "0.1%",
        background:"white",
        color:"black",
        margin:8,
    },
    elseBtn:{
        position: 'absolute',
        top:80,
        right: "9.5%",
        background:"transparent",
        color:"white",
        fontWeight:600,
        padding:10,
        margin:20,
        width:"auto",
        heigth:10,
        borderRadius:10
    }
}

export default WaiverDetail;