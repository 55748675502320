import React from "react";
import {
    Typography,
    IconButton,
    Button
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "../../components/simpleTable";
import { getLoanAmortEmiDownload, getLoanAmortSchedule,} from "../../service/api";
import Alert from "../../components/alert";
import { convertTimestamptoDate } from "../../utils/helper";


const COLS = [
    { key: "loan-amort-id", align: "center", label: " ID", value: "id", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "loan-amort-emi-amount", align: "left", label: " EMI AMOUNT", value: "emiAmount", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "loan-amort-emi-principal", align: "center", label: "EMI PRINCIPAL ", value: "emiPrincipal", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "loan-amort-due-date", align: "left", label: "DUE DATE", value: "dueDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) ?? '-' },
    { key: "loan-amort-interest", align: "center", label: "INTEREST", value: "interest", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "loan-amort-oustanding-balance", align: "center", label: "OUSTANDING BALANCE ", value: "outstandingBalance", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "loan-amort-tenure", align: "left", label: "TENURE", value: "tenure", type: "text", sort: false, fn: (v) => v ?? '-' },
]

class LoanAmortSchedule extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: ""
        }
    }

    componentDidMount () {
        this.fetchAmortSchedule();
    }

    fetchAmortSchedule = () => {

        const { id } = this.props;

        getLoanAmortSchedule(id, {},
            (res) => {
                if (res && res.success) {
                    const { amortSchedule } = res.response;
                    this.setState({
                        loading: false,
                        data: amortSchedule
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    downloadEmi =()=>{
        const { id } = this.props;

        getLoanAmortEmiDownload(id, {},
            (res) => {
                if(res.data && res){
                    window.open(URL.createObjectURL(res.data));
                }else{
                    this.setState({
                        hasError:true
                    })
                }
            }
        )
    }



    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Button
                        className={classes.downloadBtn}
                        onClick={this.downloadEmi}
                        >Download Emi</Button>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >AMORT SCHEDULE</Typography>
                    <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                    />
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    },
    downloadBtn: {
        float: "right",
        margin:5,
        background:"rgb(16,12,109)",
        color:"white",
        fontWeight:500
    }
}

export default withStyles(styles)(LoanAmortSchedule);