import React from "react";
import {
    Typography,
    Grid,
    Drawer
} from "@material-ui/core";
import '../css/main.css'
import { Colors } from "../styles";

export default function FloatingDetail (props) {
    const {  data ,closeEvent,selectedSide} = props
    return (
    <>
      {/* <div style={styles.fabContainer} className="scrollbar-hidden">
        <IconButton variant="contained"
                        style={styles.closeBtn}
                        onClick={closeEvent}
        ><CloseIcon fontSize={"medium"}/> </IconButton>
         <Grid  container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                {data.map((item, i) => <Grid key={item.key} item xs={12} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    <Typography style={styles.fieldValue}>{item.value ?? "-"}</Typography>
                </Grid>
                )}
            </Grid>
      </div> */}
        <React.Fragment key={selectedSide?selectedSide:"right"} >
            <Drawer anchor={selectedSide?selectedSide:"right"}  open={true} onClose={closeEvent}>
                <Grid style={{width:180}} container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        {data.map((item, i) => <Grid key={item.key} item xs={12} style={styles.fieldWrapper}>
                            <Typography style={styles.fieldLabel}>{item.label}</Typography>
                            { item.key==="waiver-agent-name"?
                            <>
                              <Typography style={styles.fieldValue}>{item.value.split('@')[0] +" @"+ item.value.split('@')[1] }</Typography>
                            </>:
                            <>
                             <Typography style={styles.fieldValue}>{item.value ?? "-"}</Typography>
                            </>}
                        </Grid>
                        )}
                </Grid>
            </Drawer>
        </React.Fragment>
    </>)

}

const styles = {
   fabContainer :{
    position: 'absolute',
    bottom:"10%",
    top:"15%",
    right: "0.1%",
    background:"white",
    color:"black",
    margin:20,
    width:170,
    borderRadius:7,
    // border:"2px solid black",
    overflowY: "scroll",
   },
   fieldWrapper: {
       fontFamily: "Poppins",
       padding: 8,
       border:"0.5px solid black",
       margin:10,
       borderRadius:7,
   },
   fieldLabel: {
       fontSize: 15,
       fontFamily: "inherit",
       color: Colors.primaryColor01
   },
   fieldValue: {
       fontSize: 14,
       fontFamily: "inherit",
       whiteSpace: 'break-spaces'
   },
   closeBtn: {
       position: 'absolute',
       background: "green",
       color: "black",
       right: "1%",
   },
}