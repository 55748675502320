import React from 'react';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import imageNotAvailable from './../assets/imageNotAvailable.png';

class ViewImage extends React.Component {
    render() {
        const { alt, src, isZoomed, handleZoomChange,width, height,styleProp } = this.props;
        return (
            <ControlledZoom isZoomed={isZoomed} onZoomChange={()=>handleZoomChange()} zoomMargin={1}>
                {src ? <img
                    alt={alt}
                    src={src}
                    width={width?width:250}
                    height={height?height:200}
                    style={styleProp} />
                    : <img 
                        alt="not availble"  
                        src={imageNotAvailable}
                        width= "250"
                        height="200"/> }
            </ControlledZoom>
        );
    }
}

export default ViewImage;



