import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "../../components/simpleTable";
import { getCreditLineEmandate } from "../../service/api";
import Alert from "../../components/alert";
import { convertTimestamptoDate } from "../../utils/helper";

const COLS = [

    { key: "cl-emandate-payment-id", align: "center", label: "PAYMENT ID", value: "paymentId", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "cl-emandate-amount", align: "left", label: " AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "cl-emandate-charge-date", align: "center", label: "CHARGED DATE ", value: "chargeDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v)?? '-' },
    { key: "cl-emandate-added-on", align: "left", label: "ADDED ON", value: "addedOn", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) ?? '-' },
    { key: "cl-emandate-status", align: "center", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "cl-last-updated-date", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false, fn: (v) => v ?? '-' },

]

class CreditLineEmandate extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: ""
        }
    }

    componentDidMount () {
        this.fetchStatement();
    }

    fetchStatement = () => {

        const { id } = this.props;

        getCreditLineEmandate(id, {},
            (res) => {
                if (res.success) {
                    const { creditLineEmandate } = res.response;
                    this.setState({
                        loading: false,
                        data: creditLineEmandate
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >EMANDATE CHARGES</Typography>
                    <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                    />
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(CreditLineEmandate );
