import * as React from 'react';
import { Colors, Fonts } from '../../styles';
import { Function } from '../../utils';

const { ConvertTimestampToDate } = Function;

export const HistoryWrapper = ({ children }) => {
	const { table, tr, th } = historyWrapper;
	return (
		<table style={table}>
			<thead>
				<tr style={tr}>
					<th style={th}>Date</th>
					<th style={th}>Warning Letter Type</th>
					<th style={th}>Triggered By</th>
					<th style={th}>Mode</th>
					<th style={th}>Status</th>
				</tr>
			</thead>
			<tbody>{children}</tbody>
		</table>
	);
};

const historyWrapper = {
	table: {
		width: '100%',
		borderCollapse: 'collapse'
	},
	tr: {
		background: Colors.primaryColor05
	},
	th: {
		fontSize: '12px',
		letterSpacing: '1px',
		fontWeight: Fonts.weight.medium,
		padding: '12px 24px',
		textAlign: 'center'
	}
};

export const HistoryRow = ({
	date,
	type,
	triggeredBy,
	mode,
	status
}) => {
	const { tr, td } = historyRow;
	return (
		<tr style={tr}>
			<td style={td}>{ConvertTimestampToDate(date)}</td>
			<td style={td}>{type.replace(/_/g, ' ')}</td>
			<td style={td}>{triggeredBy}</td>
			<td style={td}>{mode}</td>
			<td style={td}>{<Status status={status} />}</td>
		</tr>
	);
};

const historyRow = {
	tr: {},
	td: {
		textTransform: 'capitalize',
		fontSize: '14px',
		padding: '16px 24px',
		textAlign: 'center'
	}
};

export const Status = ({ status }) => {
	switch (status) {
		case 'sent':
			return (
				<span
					style={{
						...statusStyle,
						color: Colors.semanticColorSuccess01,
						background: Colors.semanticColorSuccess02
					}}>
					{status}
				</span>
			);
		case 'in-progress':
			return (
				<span
					style={{
						...statusStyle,
						color: Colors.supportingColorInProgress01,
						background: Colors.supportingColorInProgress02
					}}>
					{status}
				</span>
			);
		case 'failed':
			return (
				<span
					style={{
						...statusStyle,
						color: Colors.semanticColorFailed01,
						background: Colors.semanticColorFailed02
					}}>
					{status}
				</span>
			);
		default:
			return (
				<span
					style={{
						...statusStyle,
						color: Colors.typographyColor01,
						background: Colors.white
					}}>
					-
				</span>
			);
	}
};

const statusStyle = {
	padding: '4px 8px',
	textAlign: 'center',
	borderRadius: '4px',
	textTransform: 'capitalize'
};
