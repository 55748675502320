import React from "react";
import {
    Typography,
    Button,
    TextField
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {  postNameReviewLoanBankDetailReset, postNameReviewLoanBankDetailResetWithMandate } from "../../service/api";
import Alert from "../../components/alert";
import CircularProgress from '@material-ui/core/CircularProgress';
import Checkbox from '@material-ui/core/Checkbox';
class BankDetailReset extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: false,
            data: [],
            hasError: false,
            errorMessage: "",
            errorType:"",
            commentValue:"",
            errors:{},
            withMandate:false
        }
    }

    componentDidMount () {
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        const { commentValue}= this.state;
        let errorsTemp = {};
        let formIsValid = true;
        if (!commentValue) {
            formIsValid = false;
            errorsTemp["commentValue"] = "Cannot be empty";
        }
        this.setState({
            errors:errorsTemp
        })
        return formIsValid;
    }

    resetBankDetail = ()=>{
        const {  handleClose ,id} = this.props;
        const { commentValue,withMandate}= this.state;
        let params ={
            "loanId":id,
            "comment":commentValue
        }
        if(this.handleValidation()){
            this.setState({loading:true})
            if(withMandate){
                postNameReviewLoanBankDetailResetWithMandate(params,(res)=>{
                    if(res && res.success){
                    this.setState({
                        hasError:true,
                        errorMessage:"Bank details / mandate reset successfully",
                        errorType:"success",
                    })
                    setTimeout(() => {
                        window.location.reload(true);
                        handleClose()
                    }, 2000);
                    }else{
                        this.setState({
                            hasError:true,
                            errorMessage:"Problem while reseting please try after sometime",
                            errorType:"error"
                        })
                        setTimeout(() => {
                            handleClose()
                        }, 2000);
                    }
                })
            }else{
                postNameReviewLoanBankDetailReset(params,(res)=>{
                    if(res && res.success){
                    this.setState({
                        hasError:true,
                        errorMessage:"Bank details reset successfully",
                        errorType:"success",
                    })
                    setTimeout(() => {
                        window.location.reload(true);
                        handleClose()
                    }, 2000);
                    }else{
                        this.setState({
                            hasError:true,
                            errorMessage:"Problem while reseting please try after sometime",
                            errorType:"error"
                        })
                        setTimeout(() => {
                            handleClose()
                        }, 2000);
                    }
                })
            }
        }else{
            this.setState({
                hasError:true,
                errorMessage:"add comment please",
                errorType:"error"
            })
        }
    }

    handleChangeValue = event => this.setState({commentValue: event.target.value});
    handleChangeCheckBox = event => {
        this.setState({withMandate: event.target.checked});
    }
    render () {
        const {  hasError, errorMessage,errorType,loading,commentValue ,errors,withMandate} = this.state;
        const {  handleClose ,id} = this.props;

        return (
            <>
               <div style={styles.backContainer}>
                    <div style={styles.rootContainer}>
                       {loading?
                        <>
                        <CircularProgress style={styles.progessBar}/>
                        </>:
                        <>
                        <Typography
                            style={styles.title}
                            align={"center"}>Are sure to reset bank details of loan id <b>{id}</b></Typography>
                        <Typography
                            style={styles.emandate}
                            align={"center"}>
                                <Checkbox
                                checked={withMandate}
                                onChange={this.handleChangeCheckBox}
                                style={{color:"green"}}
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                                reset with mandate details  </Typography>

                        <div style={styles.actionBtnContainer}>
                            <Button
                                variant="contained"
                                style={{ ...styles.clearBtn, ...styles.commonBtn }}
                                disableElevation
                                disableFocusRipple
                                onClick={()=>handleClose()}
                            >NO</Button>
                            <Button
                                variant="contained"
                                style={{ ...styles.applyBtn, ...styles.commonBtn }}
                                disableElevation
                                disableFocusRipple
                                onClick={()=>this.resetBankDetail()}
                            >YES</Button>
                        </div>
                        <div style={{
                            margin:"10px 10px 20px 10px",
                            width:"90%"
                        }}>
                            <Typography style={styles.fieldLabel}>Add Comment </Typography>
                            <TextField
                                id={commentValue}
                                type="text"
                                name="commentValue"
                                required
                                value={commentValue}
                                onChange={this.handleChangeValue}
                                style={{width:"100%"}}
                                select={false}
                                helperText={errors["commentValue"]}
                                InputLabelProps={{
                                    shrink: true
                                }}
                                size={'small'}
                                variant="outlined"
                            >
                            </TextField>
                        </div>
                        </>}
                    </div>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                    type={errorType}
                />
            </>
        );
    }
}

const styles = {
    backContainer: {
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    rootContainer: {
        display: "inherit",
        alignItems: "center",
        flexDirection: "column",
        width: 400,
        borderRadius: 6,
        background: "#fff",
        position: "relative"
    },
    closeBtn: {
        position: "relative",
        background: "transparent",
        color: "#fff",
        bottom: 289
    },
    title: {
        margin:"30px 10px 30px 10px",
        fontFamily: "Poppins"
    },
    emandate: {
        margin:"10px",
        fontFamily: "Poppins"
    },
    textField: {
        margin: 20,
        width: 300
    },
    actionBtnContainer: {
        display: "inline-block",
        bottom: 0
    },
    commonBtn: {
        margin: "0 40px 30px",
        width: 110
    },
    clearBtn: {

    },
    applyBtn: {
        background: "rgb(16,12,109)",
        color: "#fff"
    },
    progessBar:{
        color:"green",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"5% 35% 5% 40%"
    },
}

export default withStyles(styles)(BankDetailReset);