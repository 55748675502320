import React from "react";
import {
    Typography,
    IconButton,
    Grid,
    TextField,
    MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Button } from "@material-ui/core";
import {REGEX }from '../../common/regex';
import { generateCreditLinePaymentLink} from "../../service/api";
import Alert from "../alert";

class GenerateCreditLinePaymentLink extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errors: [],
            errorMessage: "",
            phoneNumber: "",
            amount: "",
            errorType: ""
        }
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    loadOptions = opts => {
        console.log(opts[0])
        return opts.map(item => {
            return <MenuItem value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;

        const { phone_regex, amount_regex } = REGEX;

        const { phoneNumber, amount } = this.state;

        if (!phoneNumber) {
            formIsValid = false;
            errorsTemp["phone"] = "Cannot be empty";
        } else if (!phone_regex.test(phoneNumber)) {
            formIsValid = false;
            errorsTemp["phone"] = "Please enter valid phone number";
        }

        if (!amount) {
            formIsValid = false;
            errorsTemp["amount"] = "Cannot be empty";
        } else if (!amount_regex.test(amount)) {
            formIsValid = false;
            errorsTemp["amount"] = "Please enter valid amount";
        }

        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    generateLink = (e) => {
        e.preventDefault();
        const { phoneNumber,amount } = this.state;
        const { id , callBack} = this.props;

        const createUserDetail = {
            "phone": phoneNumber,
            "amount": amount,
        }
        if (this.handleValidation()) {
            generateCreditLinePaymentLink(id, createUserDetail, (res) => {
                if (res&&res.success) {
                    this.setState({
                        hasError: true,
                        errorMessage: `Payment link is send to the entered number for application Id ${id}`,
                        errorType: "success"
                    })
                    setTimeout(() => { callBack() }, 2500);
                }else {
                    this.setState({
                        hasError: true,
                        errorMessage: res?res.message:"",
                        errorType: "error"
                    })
                }
            })
            this.setToDefault();
        } else {
            console.log("Form has errors." + JSON.stringify(this.state.errors));
        }
    }

    setToDefault = () => {
        this.setState({
            phoneNumber: "",
            amount: ""
        })
    }

    render () {
        const { classes, handleClose, id } = this.props;
        const { amount, phoneNumber, errors, hasError, errorMessage, errorType } = this.state;
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >GENERATE LINK </Typography>
                    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>APPLICATION ID </Typography>
                                <TextField
                                    id={id}
                                    type="text"
                                    name="id"
                                    required
                                    disabled
                                    value={id}
                                    style={styles.textField}
                                    select={false}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>PHONE NUMBER </Typography>
                                    <TextField
                                        id={phoneNumber}
                                        type="number"
                                        name="phoneNumber"
                                        required
                                        value={phoneNumber}
                                        onInput = {(e) =>{
                                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,10)
                                        }}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        select={false}
                                        helperText={errors["phone"]}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size={'small'}
                                        variant="outlined"
                                    >
                                    </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>AMOUNT </Typography>
                                <TextField
                                    id={amount}
                                    type="text"
                                    name="amount"
                                    required
                                    value={amount}
                                    onChange={this.handleField}
                                    style={styles.textField}
                                    select={false}
                                    helperText={errors["amount"]}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    variant="outlined"
                                    type="submit"
                                    disableElevation
                                    style={styles.addButton}
                                    onClick={this.generateLink}
                                    size="small"
                                >GENERATE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                    duration="5000"
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 5px 25px"
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    addButton: {
        width: 120,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    }
}

export default withStyles(styles)(GenerateCreditLinePaymentLink);
