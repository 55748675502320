import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid
} from "@material-ui/core";
import { convertTimestamptoDate, convertTimestamptoDateWithTime} from '../../utils/helper';
import SimpleTable from "../simpleTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CustomModal from '../modal';
import CreateWaiver from '../loan/createWaiver';
import { getLocalStorage } from '../../utils/localStorage';

const COLS = [
    { key: "id", label: "ID", value: "id", fn: (v) => v?? '-'  },
    { key: "amount", label: "AMT", value: "amount", fn: (v) => v?? '-'  },
    { key: "amount-paid", label: "AMT PAID", value: "amountPaid", fn: (v) => v?? '-'  },
    { key: "bounce-fee", label: "BOUNCE FEE", value: "bounceFee", fn: (v) => v ?? '-' },
    { key: "bounce-fee-paid", label: "BOUNCE FEE PAID", value: "bounceFeePaid", fn: (v) => v ?? '-' },
    { key: "collection-mode", label: "COLLECTION MODE", value: "collectionMode", fn: (v) => v?? '-'  },
    { key: "convinience-fee", label: "CONVINIENCE FEE", value: "convenienceFee", fn: (v) => v ?? '-' },
    { key: "convinience-fee-paid", label: "CONVINIENCE FEE PAID", value: "convenienceFeePaid", fn: (v) => v ?? '-' },
    { key: "current-cycle", label: "CURRENT CYCLE", value: "currentCycle", fn: (v) => v?? '-'  },
    { key: "due-date", label: "DUE DATE", value: "dueDate", fn: (v) => v ? convertTimestamptoDate(v): '-' },
    { key: "fee", label: "FEE", value: "fee", fn: (v) => v ?? '-' },
    { key: "fee-paid", label: "FEE PAID", value: "feePaid", fn: (v) => v ?? '-' },
    { key: "offline-amount", label: "OFFLINE AMT", value: "offlineAmount", fn: (v) => v ?? '-' },
    { key: "prev-bounce-fee-unpaid", label: "PREV BOUNCE FEE UNPAID", value: "prevBounceFeeUnpaid", fn: (v) => v?? '-'  },
    { key: "prev-convinience-fee-unpaid", label: "PREV CONV FEE UNPAID", value: "prevConvenienceFeeUnpaid", fn: (v) => v ?? '-' },
    { key: "prev-fee-unpaid", label: "PREV FEE UNPAID", value: "previousFeeUnpaid", fn: (v) => v ?? '-' },
    { key: "prev-principal-unpaid", label: "PREV PRINCIPAL UNPAID", value: "previousPrincipalUnpaid", fn: (v) => v?? '-'  },
    { key: "principal", label: "PRINCIPAL", value: "principal", fn: (v) => v ?? '-' },
    { key: "principal-paid", label: "PRINCIPAL PAID", value: "principalPaid", fn: (v) => v ?? '-' },
    { key: "statement-generation-date", label: "STATEMENT GENERATION DATE", value: "statementGenerationDate", fn: (v) => convertTimestamptoDate(v) },
    { key: "cl-latest-statement", label: "GENERATE WAIVER", value: "latestStatement", fn: (v) => v },

    { key: "cl-bt-interest", label: "BT INTEREST", value: "bankTransferInterest", fn: (v) => v ?? '-' },
    { key: "cl-bt-interest-paid", label: "BT INTEREST PAID", value: "bankTransferInterestPaid", fn: (v) => v?? '-'  },
    { key: "cl-bt-principal", label: "BT PRINCIPAL", value: "bankTransferPrincipal", fn: (v) => v?? '-'  },
    { key: "cl-bt-principal-paid", label: "BT PRINCIPAL PAID", value: "bankTransferPrincipalPaid", fn: (v) => v ?? '-' },
    { key: "cl-excess-payment", label: "EXCESS PAYMENT", value: "excessPayment", fn: (v) => v ?? '-' },
];

const TXN_COLS = [
    { key: "transaction-id", align: "center", label: "TRANSACTION ID", value: "transactionId", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "transaction-date", align: "left", label: "TRANSACTION DATE", value: "transactionDate", type: "text", sort: false, fn: (v) => convertTimestamptoDateWithTime(v) },
    { key: "amount", align: "left", label: "AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v },
    // { key: "merchant-category-code", align: "left", label: "MERCHANT CATEGORY CODE", value: "merchantCategoryCode", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "merchant-name", align: "left", label: "MERCHANT NAME", value: "merchantName", type: "text", sort: false, fn: (v) => v },
    { key: "payment-id", align: "left", label: "PAYMENT ID", value: "paymentId", type: "text", sort: false, fn: (v) => v },
    { key: "category-type", align: "left", label: "CATEGORY TYPE", value: "categoryType", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "current-cycle", align: "left", label: "CURRENT CYCLE", value: "currentCycle", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "status", align: "left", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "payment-type", align: "left", label: "PAYMENT TYPE", value: "paymentType", type: "text", sort: false, fn: (v) => v }
]

const CustomisedHeading = props => {
    const { month ,latestStatement} = props;
    return (<>
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="credit-line-billed-statement-summary"
            style={styles.summaryTyography}
        >{month}
        <div style={styles.waiverStatus}>{latestStatement?"YOU CAN GENERATE WAIVER":null}</div>
        </AccordionSummary>

        </>
    );
}

const CustomisedSummary = props => {
    const { fields,APPLICATION_ID } = props;

    const [isOpen, setIsOpen] = React.useState(false);
    const openModal = (evt) => {
        setIsOpen(true)
        evt.stopPropagation();
    }
    console.log(fields.statementId)

    const handleClose = _ => {
        setIsOpen(false)
    }
    const handleCols = (key,field) => {
        switch (key) {
            case "cl-latest-statement":
                return field.fn(fields[field.value])? <>
                <button
                    style={styles.linkBtn}
                    onClick={(evt) => openModal(evt)}
                >Waive Amount</button>
                </>  :"-"

            default:
                return field.fn(fields[field.value])
        }
    }
    return (<>
        <Grid container style={styles.summaryContainer} spacing={1}>
            {COLS.map(field => {
                return (
                    <Grid item xs={2}>
                        <div style={styles.keyValueContainer}>
                            <div style={styles.rowTitle}>{field.label}</div>
                            <div style={styles.rowValue}>{handleCols(field.key,field)}</div>
                        </div>
                    </Grid>
                )
            })}
        </Grid>

        <CustomModal
            isOpen={isOpen}
            handleClose={handleClose}
            >
            <CreateWaiver
                handleClose={handleClose}
                typeOfWaiver={"CREDITLINE_WAIVER"}
                LOAN_APPLICATION_ID={APPLICATION_ID}
                sumOfWaive={fields.bounceFee+fields.fee}
                        />
        </CustomModal >
        </>
    )
}

const CustomisedDetail = props => {
    const { transactions, loading } = props;
    return (
        <AccordionDetails style={styles.customisedDetail}>
            <SimpleTable
                cols={TXN_COLS}
                data={transactions}
                loading={loading}
            />
        </AccordionDetails>
    );
}

const CheckLatest = (months) =>{
    const { role} = getLocalStorage("LmsUserDetails");

    const checkPermission = (PERMISSIONS)=>{
        let conditionCheck = false;
            for(let item of PERMISSIONS){
                if(role.includes(item)){
                    conditionCheck=true;
                    break;
                }
        }

        return conditionCheck;
    }


    let latestStatement= months.sort((a,b) =>
    new Date(b.statementGenerationDate).getTime() - new Date(a.statementGenerationDate).getTime())[0];
    months.map((item)=>{
        if(item===latestStatement){
            if(item.bounceFee>0 || item.fee>0){

                if(checkPermission(["ROLE_ADMIN","ROLE_CE","ROLE_WAIVER"])){
                    Object.assign(item,{"latestStatement":true})
                    return console.log("1");
                }else{
                    Object.assign(item,{"latestStatement":false})
                    return console.log("2");
                }
            }else{
                Object.assign(item,{"latestStatement":false})
                return console.log("3");
            }
        }else{
                Object.assign(item,{"latestStatement":false})
                return console.log("4");
        }
    })
    return months;

}


const BilledStatement = props => {
    const [expanded, setExpanded] = React.useState(false);
    const { loading, data ,applicationId} = props;

    const monthsTemp = Object.keys(data).map((val, k) => Object.assign(data[val],{"monthName":val}))

    let months = CheckLatest(monthsTemp);
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={styles.root}>
            <Typography
                variant="h6"
                style={styles.typography}
            >Billed Statement
            </Typography>
            {months.map((month,idx) => {
                // const currentMonthData = data[month];
                return (
                    <Accordion
                        square
                        onChange={handleChange(idx)}
                        expanded={expanded === idx}
                    >
                        <CustomisedHeading month={month.monthName} latestStatement={month.latestStatement}/>
                        <CustomisedSummary fields={month} APPLICATION_ID={applicationId} />
                        <CustomisedDetail transactions={month.transactions} loading={loading} />
                    </Accordion>
                )
            })}

        </div>
    );
}

export default BilledStatement;

const styles = {
    root: {
        backgroundColor: '#fff',
        padding: 10
    },
    typography: {
        margin: "0px 0 15px"
    },
    summaryTyography: {
        fontSize: 20,
        fontWeight: '500'
    },
    summaryContainer: {
    },
    keyValueContainer: {
        margin: '2px 15px 2px',
    },
    rowTitle: {
        fontWeight: '400',
        color: '#9E9E9E'
    },
    rowValue: {

    },
    customisedDetail: {
        marginTop: 20,
    },
    linkBtn: {
        "&:hover": {
            cursor: "pointer"
        },
        background:"#1B2D93",
        color:"white",
        height: 38,
        width: 120,
        margin:2,
        borderRadius:"10px",
    },
    waiverStatus:{
        color:"green",
        margin:"0px 0px 0px auto",
        borderRadius:10,
        border:"0px solid black",
        padding:"auto",
        fontSize: 18,
        fontWeight: '300'
    }
}