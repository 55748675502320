import { makeObservable, observable, action } from "mobx";

class PaginationStore {

    pageSize = 10;
    page = 1;
    maxPage = 1;
    count = 0;

    constructor(){
        makeObservable(this, {
            page: observable,
            maxPage: observable,
            updateMaxPage: action,
            count: observable,
            goToPage: action
        })
    }

    goToPage(page) {
        if(page && page <= this.maxPage) {
            this.page = page;
        } else this.page = 1;
    }

    updateMaxPage(totalCount) {

        this.count = totalCount;

        let maxPage = Math.ceil(this.count/this.pageSize);
        // let leftOver = this.count%this.pageSize;
        if(maxPage) {
            this.maxPage = maxPage;
        } else this.maxPage = 1;
    }
}

export default new PaginationStore();