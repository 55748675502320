import React, { useState } from "react";
import "./sidebar.css"

import { getLocalStorage } from "../../utils/localStorage";
import {  setNavItems } from "../../utils/helper";
import { Redirect } from "react-router-dom";
import { Button } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';



function DisplayIcon(props){
    const {keyValue,item,includeIt,isOpen}=props;
    const [isHover,setIsHover]= useState(false);
    return<>
    <div className={"sidebar__listItem"}
        onMouseEnter={()=>{
            setIsHover(true)
        }}
        onMouseLeave={()=>{
            setIsHover(false)
        }} >
        <img
            key={keyValue}
            alt=""
            className={"sidebar__icon"}
            src={includeIt|| isHover?item.activeIcons:item.inactiveIcon} />
        <div style={{margin: "12px 0px 10px 15px", }}>
            {isOpen?item.name:null}
        </div>
    </div>
    </>
}

class SideBar extends React.Component{

    constructor(props){
        super(props);
        this.state= {
            displayItems:[],
            isOpen:true,
            isHover:false
        }

    }
    componentDidMount(){
        this.setItems();
    }

    navigateToPath = (pathName) => {
        const { history } = this.props;
        console.log("Navigating to path", pathName);
        history.push(pathName);
    }

    setItems = ()=>{
       const res= getLocalStorage("LmsUserDetails");
       if(!res){
           <Redirect to="/login"/>
       }else {
            const navList = setNavItems();
            this.setState({
                displayItems:navList
            })
       }

    }


    render() {
        const {  location } = this.props;
        const { displayItems,isOpen}= this.state;

        const path = location.pathname;
        return(
        <>
        <Button style={{
                position:"absolute",
                top:100,
                left:10,
                color:"white"
        }}
            onClick={() => this.setState({isOpen:!isOpen})}>
                <MenuIcon fontSize="small"/>
        </Button>
        <div className={`${!isOpen && "sidebar-closed"} sidebar`}>
            <div style={{height:50,backgroundColor:"rgb(21 27 128 )",marginTop:90}}></div>
            <div className="innerDiv" >
                {displayItems.map((item,cKey) => (
                <div
                    className="link"
                    key={cKey}
                    onClick={() => this.navigateToPath(item.path)}
                    style={
                        path.includes(item.path)
                        ?{
                        color:"#1B2D93",
                        background:"#F0F8FF",
                        fontWeight:500
                    }:null}
                      >
                    <DisplayIcon
                        keyValue={cKey}
                        item={item}
                        includeIt={path.includes(item.path)}
                        isOpen={isOpen}/>
                </div>
                ))}
            </div>
        </div>
        </>
        )
    }
}

export default SideBar;