import React from 'react';
import { Alert } from '@material-ui/lab';
import { Snackbar } from '@material-ui/core';

const defaultErrorMsg =
	'Something went wrong. Please try again after some time.';
const defaultDuration = 4000;

const Toast = ({
	isOpen,
	severity,
	duration,
	message,
	onClose
}) => {
	React.useEffect(() => {
		if (isOpen) {
			setTimeout(() => onClose, duration || defaultDuration);
		}
	}, [isOpen]);

	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={duration || defaultDuration}
			key="error-snackbar"
			anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
			onClose={onClose}>
			<Alert severity={severity || 'error'} onClose={onClose}>
				{message || defaultErrorMsg}
			</Alert>
		</Snackbar>
	);
};

export default Toast;
