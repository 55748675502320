import * as React from 'react'
import { Colors, Fonts } from '../../../styles'
import { IconButton } from '@material-ui/core';
import { Clear } from '@material-ui/icons';

const Notification = ({ visible = false, message, onPress }) => {
  if (visible) {
    return (
      <span style={styles.root}>
        {message ?? "An Unknown error occured. Please retry after sometime."}
        <IconButton onClick={onPress} style={styles.clearIcon}>
          <Clear />
        </IconButton>
      </span>
    )
  }
  return null;
}

export default Notification;

const styles = {
  root: {
    padding: '18px 24px',
    backgroundColor: Colors.typographyColor02,
    color: Colors.white,
    borderRadius: '8px',
    left: '42%',
    fontSize: Fonts.size.small,
    position: 'absolute',
    bottom: '10px',
    display: 'flex',
    alignItems: 'center'
  },
  clearIcon: {
    color: Colors.white,
    marginLeft: '10px',
    marginTop: '2px',
    position: 'relative',
    cursor: 'pointer',
  }
}