import React from "react";
import Modal from '@material-ui/core/Modal';

class CustomModal extends React.Component {
    render() {
        const { isOpen, children, handleClose } = this.props;
        return (
            <Modal
                open={isOpen}
                onClose={(_, reason) => {
                    if (reason !== "backdropClick") {
                      handleClose();
                    }
                  }}
                aria-labelledby="custom-modal-title"
                aria-describedby="custom-modal-description"
            >{children}</Modal>
        );
    }
}

export default CustomModal;