import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import { makeFilters, convertTimestamptoDate, makeSort,  debounce } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import { getCreditLine, getSearch } from "./../../service/api";
import CreditLineStore from "./../../stores/creditLineStore";
import SearchField from "./../../components/searchField";
import FilterBar from "./../../components/filterBar";
import { SEARCH_TYPE_CODES } from "../../common/constants";

const COLS = [
    { key: "creditLine-application-id", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-name", align: "center", label: "NAME", value: "name", type: "text", sort: false, fn: (v) => (v) },
    { key: "creditLine-phone", align: "center", label: "PHONE", value: "phone", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-status", align: "center", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-company", align: "center", label: "COMPANY", value: "company", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-dpd-count", align: "center", label: "DPD", value: "dpdCount", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-status-Active", align: "center", label: "CREDIT LINE STATUS", value: "creditLineStatus", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-applied-outstanding-balance", align: "center", label: "OUTSTANDING BALANCE ", value: "outstandingBalance", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-approved-amount", align: "center", label: "APPROVED AMOUNT", value: "approvedAmount", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-remaining-amount", align: "center", label: "REMAINING AMOUNT", value: "remainingAmount", type: "text", sort: false, fn: (v) => v },
    { key: "creditLine-application-complete-date", align: "center", label: "APPLICATION COMPLETE DATE", value: "applicationCompleteDate", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },
    { key: "cl-statement-download", align: "center", label: "DOWNLOAD STATEMENT", value: " ", type: "clStatementDownload", sort: false, fn: (v) => v }


    // { key: "creditLine-status", align: "center", label: "CREDIT LINE STATUS", value: "creditLineStatus", type: "comment", sort: false, fn: (v) => v }
];

class CreditLine extends React.Component {
    constructor (props) {
        super(props);
        this.store = CreditLineStore;
        this.pagination = PaginationStore;
        this.dbounceCaller = debounce(() => this.fetchSearch(true));
        this.state = {
            searchText: "",
            searchType: "applicationId",
            loading: true,
            hasError: false,
            errorMessage: "",
            filter: {},
            sort: {
                sortType: "desc",
                sort: false
            },
            pageNumberValue:"1",
            pageButton:true
        }
    }

    componentDidMount () {
        this.fetchCreditLineRequest(true);
    }

    fetchCreditLineRequest ($hasUpdate = false) {
        const { loading, filter, sort, searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            page: page,
            pageSize: pageSize
        }

        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }

        const updatedFilter = makeFilters(filter, "creditLine")

        if (sort.sort) {
            Object.assign(params, sort);
        }

        Object.assign(params, { filter: updatedFilter.filter });

        if (searchText) {
            Object.assign(params, { "searchText": searchText, "searchType": searchType });
        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getCreditLine(
            params,
            (res) => {
                if (res.success) {
                    const { page, totalCount } = res.response;
                    this.store.setData(res.response.applications);
                    this.pagination.goToPage(page);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchCreditLineRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchCreditLineRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

    // search methods

    fetchSearch ($hasUpdate = false) {
        const { searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;
        let params = {
            page: page,
            pageSize: pageSize
        }
        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }
        if (searchText) {
            this.setState({
                pageButton:false
            })
            Object.assign(params, { "searchText": searchText, "searchType": searchType, "type": "credit-line-application" });
            getSearch(
                params,
                (res) => {
                    if (res.success) {
                        const { page, totalCount } = res.response;
                        this.store.setData(res.response.applications);
                        this.pagination.goToPage(page);
                        this.pagination.updateMaxPage(totalCount);
                        this.setState({ loading: false });
                    } else {
                        this.setState({
                            hasError: true,
                            loading: false
                        })
                    }
                })
        } else {
            this.fetchCreditLineRequest(true);
            this.setState({
                pageButton:true
            })
        }

    }
    handleSearch = evt => {
        this.setState({ searchText: evt.target.value });
        this.dbounceCaller();

    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    // sort methods
    handleSort = (sortByValue) => {

        const { sort } = this.state;
        let nSort = sort;

        if (!nSort.sort) {
            nSort = {
                "sortLabel": sortByValue,
                "sort": sortByValue,
                "sortType": "desc"
            }
        } else {
            if (nSort.sortLabel === sortByValue) {
                nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
            } else {
                nSort = {
                    "sortLabel": sortByValue,
                    "sort": sortByValue,
                    "sortType": "desc"
                }
            }
        }
        this.setState({
            sort: makeSort(nSort, "creditLine")
        }, () => this.fetchCreditLineRequest(true))
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // filter bar methods
    handleFilter = data => {
        let tempFilter = Object.assign({}, this.state.filter);
        data.forEach(item => {
            const propertyValue = { [item.property]: item.value };
            Object.assign(tempFilter, propertyValue);
        })

        if (!data.length) {
            tempFilter = {};
        }

        this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
        console.log("updating property...", _filter);
        this.setState({
            filter: _filter
        }, () => this.fetchCreditLineRequest(true))
    }

    removeProperty = (property) => {
        let tempFilter = Object.assign({}, this.state.filter);
        delete tempFilter[property];
        this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
        this.setState({
            filter: {}
        }, () => this.updateProperty())
    }

    render () {
        const { loading, hasError, errorMessage, sort, filter,pageNumberValue,pageButton } = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>
                <SearchField
                    placeholder={"Credit Line"}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["creditLine"]}
                />

                <FilterBar
                    screen={"creditLine"}
                    appliedFilter={filter}
                    callback={{
                        handleFilter: this.handleFilter,
                        removeProperty: this.removeProperty,
                        clearFitler: this.clearFilter
                    }}
                />

                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={pageButton}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

export default observer(CreditLine);