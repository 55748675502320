export default {
  weight: {
    regular: '400',
    medium: '600',
    bold: '700'
  },
  size: {
    small: '16px',
    medium: '18px',
    large: '22px'
  }
}