import {
  PATHS,
  API
} from "./constants";

export function searchWarningLetter (type, value) {
  if (type && value) {
    return new Promise((resolve, reject) => {
      API.GET(PATHS.SEARCH_WARNING_LETTER(type, value), {}, {},
        function (res) {
          resolve(res);
          console.log("searchWarningLetter", res);
        }
      )
    })
  }
}

export function getWarningLetterHistory (type, value) {
  if (type && value) {
    return new Promise((resolve, reject) => {
      API.GET(PATHS.WARNING_LETTER_HISTORY(type, value), {}, {},
        function (res) {
          resolve(res)
          console.log("getWarningLetterHistory", res);
        }
      )
    })
  }
}

/**
 * Represents sendWarningLetterSMS.
 * @constructor
 * @param {string} type - The type of the warning letter.
 * @param {string} value - LoanId, Phone or LenderLoanId.
 * @param {string} warningLetterType - 1 2 or 3.
 */
export function sendWarningLetterSMS (
  type,
  value,
  warningLetterType) {
  return new Promise((resolve, reject) => {
    API.POST(PATHS.SEND_WARNING_LETTER_SMS(type, value, warningLetterType), {}, {},
      function (res) {
        resolve(res);
        console.log("sendWarningLetterSMS", res);
      }
    )
  })
}

export function sendWarningLetterWhatsApp () {

}

export default {
  searchWarningLetter,
  getWarningLetterHistory,
  sendWarningLetterSMS,
  sendWarningLetterWhatsApp,
}