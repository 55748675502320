import React from "react";
import { 
    Button, 
    Dialog,
    DialogTitle,
    Slide,
 } from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
  });

export default function ActionDialogBox (props) {
    const {actionDialogBoxIsOpen,title,handleAgree,handleDisagree ,agreeName,disagreeName,loader}= props; 
    return (
      
        <Dialog
          open={actionDialogBoxIsOpen}
          TransitionComponent={Transition}
          onClose={handleDisagree} >

        <div style={{width:loader?400:"auto"}}>  
           {loader?<>
            <CircularProgress style={styles.progessBar}/>
           </>:
            <>
            <DialogTitle style={styles.title}>{title}</DialogTitle>
            <div style={styles.dialogAction} >
                <Button onClick={handleDisagree} style={styles.disagreeBtn} >
                {disagreeName}
                </Button>
                <Button onClick={handleAgree} style={styles.agreeBtn} >
                {agreeName}
                </Button>
            </div>
            </>
            
            }
    
        </div>
        </Dialog>
    );
} 

const styles={
    rootContainer:{
      width:400
    },
    title:{
        margin:10
    },
    progessBar:{
        color:"green",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"5% 35% 5% 42%"
    },
    dialogAction:{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:10
    },
    disagreeBtn:{
        padding:10,
        background:"#FF0A50",
        color:"white",
        width:100,
        fontWeight:500,
        borderRadius:20,
        margin:10
    },
    agreeBtn:{
        padding:10,
        background:"#00AA4A",
        color:"white",
        width:100,
        fontWeight:500,
        borderRadius:20,
        margin:10
    }

}