import React from "react";
import {
    Typography,
    IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

export default function ReferenceView(props) {
    const { data, handleClose } = props;
    return (
        <div style={styles.rootContainer}>
            <IconButton
                variant="contained"
                aria-controls="close-btn"
                aria-haspopup="true"
                aria-label="close"
                onClick={handleClose}
                style={styles.closeBtn}
                ><CloseIcon /></IconButton>
                <Typography
                    variant="h5"
                >REFRENCE</Typography>
                <div style={styles.referenceList}>
                    <ul style={styles.listHead}>
                        <li style={styles.listItem}>NAME</li>
                        <li style={styles.listItem}>PHONE</li>
                        <li style={styles.listItem}>RELATION</li>
                    </ul>
                    {data.map(item => {
                        return <ul key={item.phone} style={styles.listBody}>
                        <li style={styles.listItem}>{item.name}</li>
                        <li style={styles.listItem}>{item.phone}</li>
                        <li style={styles.listItem}>{item.relation?item.relation:"-"}</li>
                    </ul>
                    })}
                </div>
        </div>
    );
}

const styles = {
    rootContainer: {
        padding: 40,
        background: "#fff",
        position: "absolute",
        right: 0,
        width: "45%",
        height: "100vh",
        overflowY: "scroll",
        borderTopLeftRadius: 10
    },
    closeBtn: {
        float: "right"
    },
    divider: {
        marginTop: 5,
        marginBottom: 10
    },
    referenceList: {
        fontSize: 18,
        fontFamily: "Poppins"
    },
    listHead: {
        display: "flex",
        listStyleType: "none",
        border: "1px solid"
    },
    listItem: {
        margin: 10,
        width: "calc(100% / 3)"
    },
    listBody: {
        display: "flex",
        listStyleType: "none",
    },

}