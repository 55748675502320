import React from "react";
import {
    Typography,
    Button,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {  postNameReviewLoanResetLoanKycDetail } from "../../service/api";
import Alert from "../../components/alert";
import CircularProgress from '@material-ui/core/CircularProgress';
class ResetLoanKycDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: false,
            data: [],
            hasError: false,
            errorMessage: "",
            errorType:"",
            errors:{}
        }
    }

    componentDidMount () {
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    ResetLoanKycDetail = ()=>{
        const {  handleClose ,id} = this.props;

        this.setState({loading:true})
        postNameReviewLoanResetLoanKycDetail(id,(res)=>{
            if(res && res.success){
            this.setState({
                hasError:true,
                errorMessage:"Kyc details reset successfully",
                errorType:"success",
            })
            setTimeout(() => {
                window.location.reload(true);
                handleClose()
            }, 2000);
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:res?res.message:"Problem while reseting please try after sometime",
                    errorType:"error"
                })
                setTimeout(() => {
                    handleClose()
                }, 2000);
            }
        })

    }

    handleChangeValue = event => this.setState({commentValue: event.target.value});

    render () {
        const {  hasError, errorMessage,errorType,loading,} = this.state;
        const {  handleClose ,id} = this.props;

        return (
            <>
               <div style={styles.backContainer}>
                    <div style={styles.rootContainer}>
                       {loading?
                        <>
                        <CircularProgress style={styles.progessBar}/>
                        </>:
                        <>
                        <Typography
                            style={styles.title}
                            align={"center"}>Are sure to reset Kyc details of loan id <b>{id}</b></Typography>

                        <div style={styles.actionBtnContainer}>
                            <Button
                                variant="contained"
                                style={{ ...styles.clearBtn, ...styles.commonBtn }}
                                disableElevation
                                disableFocusRipple
                                onClick={()=>handleClose()}
                            >NO</Button>
                            <Button
                                variant="contained"
                                style={{ ...styles.applyBtn, ...styles.commonBtn }}
                                disableElevation
                                disableFocusRipple
                                onClick={()=>this.ResetLoanKycDetail()}
                            >YES</Button>
                        </div>
                        </>}
                    </div>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                    type={errorType}
                />
            </>
        );
    }
}

const styles = {
    backContainer: {
        height: "60vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    rootContainer: {
        display: "inherit",
        alignItems: "center",
        flexDirection: "column",
        width: 400,
        borderRadius: 6,
        background: "#fff",
        position: "relative"
    },
    closeBtn: {
        position: "relative",
        background: "transparent",
        color: "#fff",
        bottom: 289
    },
    title: {
        margin:"30px 10px 30px 10px",
        fontFamily: "Poppins"
    },
    textField: {
        margin: 20,
        width: 300
    },
    actionBtnContainer: {
        display: "inline-block",
        bottom: 0
    },
    commonBtn: {
        margin: "0 40px 30px",
        width: 110
    },
    clearBtn: {

    },
    applyBtn: {
        background: "rgb(16,12,109)",
        color: "#fff"
    },
    progessBar:{
        color:"green",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        margin:"5% 35% 5% 40%"
    },
}

export default withStyles(styles)(ResetLoanKycDetail);