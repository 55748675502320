import React from "react";
import {
    Typography,
    IconButton,
    Grid,
    MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Button } from "@material-ui/core";
import {REGEX} from '../../common/regex';
import { raiseLoanSettlement } from "../../service/api";
import Alert from "../alert";

import { getLoanSettlementSummary } from "../../service/api";
import Skeleton from '@material-ui/lab/Skeleton';

const COLS = [
    { key: "ls-loan-amount", align: "left", label: "LOAN AMOUNT", value: "loanAmount", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "ls-total-outstanding", align: "left", label: "TOTAL OUTSTANDING", value: "totalOutstanding", type: "text", sort: false, fn: (v) => v ??"-"},
    { key: "ls-total-amount-paid", align: "left", label: "TOTAL AMOUNT PAID", value: "totalAmountPaid", type: "text", sort: false, fn: (v) => v  ??"-"},
    { key: "ls-principle-amount", align: "left", label: "PRINCIPLE AMOUNT (Principle + Interest)", value: "principleAmount", type: "text", sort: false, fn: (v) => v  ?? '-' },
    { key: "ls-waiver-amount", align: "left", label: "WAIVER AMOUNT (Bounce fee + Late fee)", value: "waiverAmount", type: "text", sort: false, fn: (v) => v ?? '-' },
]


class RaiseLoanSettlement extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errors: [],
            errorMessage: "",
            amount: "",
            errorType: ""
        }
    }


    componentDidMount() {
        this.fetchSettlementSummary();
    }

    fetchSettlementSummary = () => {

        const { id } = this.props;

        getLoanSettlementSummary(id,
            (res) => {
                if (res) {
                    this.setState({
                        loading: false,
                        data: res.response
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    loadOptions = opts => {
        console.log(opts[0])
        return opts.map(item => {
            return <MenuItem value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;

        const {  amount_regex } = REGEX;

        const { amount } = this.state;

        if (!amount) {
            formIsValid = false;
            errorsTemp["amount"] = "Cannot be empty";
        } else if (!amount_regex.test(amount)) {
            formIsValid = false;
            errorsTemp["amount"] = "Please enter valid amount";
        }

        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    generateLink = (e) => {
        e.preventDefault();
        const { id , handleClose} = this.props;

        const createUserDetail = {
            "loanId":id,
            // "amount": amount,
        }
            raiseLoanSettlement(createUserDetail, (res) => {
                if (res&&res.success) {
                    this.setState({
                        hasError: true,
                        errorMessage: `Settlement raised for loan Id ${id}`,
                        errorType: "success"
                    })
                    setTimeout(() => { handleClose() }, 2500);
                }else {
                    this.setState({
                        hasError: true,
                        errorMessage: res?res.message:"",
                        errorType: "error"
                    })
                }
            })
            this.setToDefault();
    }

    setToDefault = () => {
        this.setState({
            amount: ""
        })
    }

    render () {
        const { classes, handleClose } = this.props;
        const { hasError, errorMessage, errorType ,loading, data} = this.state;
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >RAISE LOAN SETTLEMENT </Typography>
                    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            {/* <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>LOAN ID </Typography>
                                <TextField
                                    id={id}
                                    type="text"
                                    name="id"
                                    required
                                    disabled
                                    value={id}
                                    style={styles.textField}
                                    select={false}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid> */}

                            {/* <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>AMOUNT </Typography>
                                <TextField
                                    id={amount}
                                    type="text"
                                    name="amount"
                                    required
                                    value={amount}
                                    onChange={this.handleField}
                                    style={styles.textField}
                                    select={false}
                                    helperText={errors["amount"]}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                </TextField>
                            </Grid> */}
                    {!loading?<>
                        <table style={{
                            width:"95%",
                            border: "1px solid black",
                            borderCollapse: "collapse",
                            margin:"20px 0px 0px 10px",
                            }}>
                            {COLS.map((item,key)=>{
                                return <>
                                <tr>
                                    <th style={styles.thStyle}>{item.label}</th>
                                    <td style={styles.tdStyle}>{data[item.value]?`₹ ${data[item.value]}`:"-"}</td>
                                </tr>
                                </>
                            })}
                        </table>
                        <Grid item xs={12} style={styles.fieldWrapper}>
                            <Button
                                variant="outlined"
                                type="submit"
                                disableElevation
                                style={styles.addButton}
                                onClick={this.generateLink}
                                size="small"
                            >Raise  </Button>
                        </Grid>
                        </>:<>
                        <div style={{width:"100%"}}>
                            <Skeleton height={38}/>
                            <Skeleton  animation={false} height={38}/>
                            <Skeleton animation="wave"height={38} />
                            <Skeleton variant="rect" width={120} height={40} style={{margin:10,borderRadius:5}} />
                        </div>
                        </>}

                        </Grid>
                    </Grid>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                    duration={10000}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5% 35%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 5px 25px"
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 0,
    },
    container: {
        padding: "0 0px"
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    addButton: {
        width: 120,
        height: 40,
        margin: "10px",
        borderRadius:10,
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    thStyle:{
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: 5,
        textAlign: "left",
        fontWeight:450
    },
    tdStyle:{
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: 5,
        textAlign: "left"
    }
}

export default withStyles(styles)(RaiseLoanSettlement);
