export default function createEnvironment() {
    const __dev__ = process.env.REACT_APP_HOST_ENV;
    if(__dev__ === "development") {
        return {
            BASE_URL: process.env.REACT_APP_BASE_URL,
            END_URL: process.env.REACT_APP_END_URL,
            CREDIT_LINE_URL: process.env.REACT_APP_CREDIT_LINE_END_URL,
            LOAN_URL: process.env.REACT_APP_LOAN_URL,
            LOAN_BASE_URL: process.env.REACT_APP_LOAN_BASE_URL,
            UNDERWRITING_URL: process.env.REACT_APP_UNDERWRITING_URL
        }
    }

    return {
        BASE_URL: process.env.REACT_APP_BASE_URL,
        END_URL: process.env.REACT_APP_END_URL,
        CREDIT_LINE_URL: process.env.REACT_APP_CREDIT_LINE_END_URL,
        LOAN_URL: process.env.REACT_APP_LOAN_URL,
        LOAN_BASE_URL: process.env.REACT_APP_LOAN_BASE_URL,
        UNDERWRITING_URL: process.env.REACT_APP_UNDERWRITING_URL,
        HOST_NAME: __dev__
    }
}