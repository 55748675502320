import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "../../components/simpleTable";
import { getLoanDeductionDetails } from "../../service/api";
import Alert from "../../components/alert";
import { convertTimestamptoDate } from "../../utils/helper";

const COLS = [
    { key: "id", align: "left", label: "ID", value: "id", type: "text", sort: false, fn: (v) => v },
    { key: "statement-id", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v },
    { key: "request-Date", align: "left", label: "REQUEST DATE", value: "requestDate", type: "text", sort: false, fn: (v) => v ? convertTimestamptoDate(v) : '-' },
    { key: "Ddeduction-request-raised", align: "left", label: "DEDUCTION REQUEST RAISED", value: "deductionRequestRaised", type: "text", sort: false, fn: (v) => v ? "YES":"NO" },
    { key: "amount-requested", align: "left", label: "AMOUNT REQUESTED", value: "amountRequested", type: "text", sort: false, fn: (v) => v },
    { key: "amount-received", align: "left", label: "AMOUNT RECEIVED", value: "amountReceived", type: "text", sort: false, fn: (v) => v },
    { key: "processed-date", align: "left", label: "PROCESSED DATE", value: "processedDate", type: "text", sort: false, fn: (v) => v ? convertTimestamptoDate(v) : '-'  },
   
]


class LoanDeductionDetail extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: "",
        }
    }

    componentDidMount() {
        this.fetchPayments();
    }

    fetchPayments = () => {

        const { id } = this.props;

        getLoanDeductionDetails(id,
            (res) => {
                if (res && res.success) {
                    this.setState({
                        loading: false,
                        data: res.response.deductionDetail
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render() {
        const { loading, data, hasError, errorMessage } = this.state;
        const { classes, handleClose } = this.props;

        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >DEDUCTION SUMMARY</Typography>
                    <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                    />
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(LoanDeductionDetail);