import React from "react";
import {
    TableCell,
    TableSortLabel
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

export default function CustomTableCell(props) {
    const { 
        children,
        sort,
        handleSort,
        item,
        align
    } = props;
    const sortDirection = sort.sortType;
    // const sortBy = sort.sort;
    return (<>
        <TableCell
            align={align}
            sortDirection={sortDirection}
            onClick={(evt) => item.sort?
                handleSort(evt, item.value):
                null
            }
            style={styles.tableHeadCell}
        >
            {children}
        
        <TableSortLabel
            active={item.value === sort.sortLabel}
            direction={sort.sortType}
            hideSortIcon={true}
            IconComponent={ArrowDropDownIcon}
        />
        </TableCell>
    </>);
}

const styles = {
    tableHeadCell: {
        padding: 10,
        fontSize: 13,
        cursor: "pointer"
    },
}