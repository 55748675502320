import React from "react";
import {
    Button,
    Grid,
    Typography,
    Checkbox,
    TextField,
    FormControl,
    FormGroup,
    FormLabel
} from "@material-ui/core";

const FIELD = [
    { key: "payment-amount", name: "addPaymentAmount", label: "AMOUNT", type: "number", option: [] },
    { key: "payment-waiver", label: "LATE FEE WAIVER", name: "addPaymentWaiver", type: "checkbox", option: [] }
]

export default function AddPayment(props) {
    const { handleClose } = props;
    const [field, setField] = React.useState({});

    const handleChange = evt => {
        const { name, value } = evt.target;
        setField({ ...field, [name]: value });
    }

    return (
        <div style={styles.rootContainer}>
            <Grid
                container
                justify="center"
                style={styles.innerContainer}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    style={styles.typography}
                >ADD PAYMENT</Typography>
                {FIELD.map(item => {
                    const { key, name, label, type } = item;
                    const isCheckbox = type === "checkbox";
                    if(isCheckbox) return (
                        <FormControl component="fieldset">
                            <FormGroup row style={styles.formGroup}>
                                <FormLabel style={styles.label}>{label}</FormLabel>
                                <Checkbox
                                    color="small"
                                    style={styles.checkBox}
                                />
                                </FormGroup>
                        </FormControl>
                    );
                    return <TextField
                        id={key}
                        name={name}
                        label={label}
                        placeholder={label}
                        type={type}
                        required
                        style={styles.textField}
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    />
                })}
                <div style={styles.btnContainer}>
                    <Button
                        style={styles.closeBtn}
                        variant="outlined"
                        onClick={handleClose}
                    >CLOSE</Button>
                    <Button
                        style={styles.saveBtn}
                        variant="outlined"
                    >ADD</Button>
                </div>
            </Grid>

        </div>
    );
}

const styles = {
    rootContainer: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    innerContainer: {
        width: 390,
        background: "#fff",
        borderRadius: 6,
        padding: 30
    },
    typography: {
        fontFamily: "Poppins"
    },
    textField: {
        width: 300,
        margin: "20px 0"
    },
    formGroup: {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: 300
    },
    label: {

    },
    checkBox: {
        color: "green"
    },
    btnContainer: {
        margin: "15px 0px",
        display: "inherit",
        width: 300,
        justifyContent: "space-between"
    },
    closeBtn: {
        width: 100
    },
    saveBtn: {
        width: 100,
        background: "#1B2D93",
        color: "#fff"
    }
}