import React from "react";
import { observer } from "mobx-react";
import Alert from "./../../components/alert";
import {   getPayoutPrevList, getPayoutResponseWithProcessId, postPayoutFile } from "./../../service/api";

import '../../css/main.css';
import {
    Typography,
    Grid,
    TextField,
    MenuItem
} from "@material-ui/core";
import { COMPANY } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";
import { Skeleton } from "@material-ui/lab";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ContentLoader from "../../components/contentLoader";
import "./main.css";
import { convertTimestamptoDate } from "../../utils/helper";

const LISTING_COLS = [
    { key: "payout-id", align: "left", label: "ID", value: "id", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "payout-process-d", align: "left", label: "PROCESS ID", value: "processId", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "payout-status", align: "left", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "payout-created-date", align: "left", label: "CREATED DATE", value: "createdDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "payout-last-updated", align: "left", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false, fn: (v) =>convertTimestamptoDate(v)},
    { key: "payout-total-count", align: "left", label: "TOTAL COUNT", value: "totalCount", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "payout-processed-count-combined", align: "left", label: "PROCESSED COUNT IN COMBINED", value: "processedCountInCombined", type: "text", sort: false, fn: (v) => v?? "-" },
    { key: "payout-processed-count-in-marketplace", align: "left", label: "PROCESSED COUNT IN MARKETPLACE", value: "processedCountInMarketplace", type: "text", sort: false, fn: (v) => v??"-" },

]

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 30,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

// const LISTING_COLS = [
//     { key: "pout-applicationId", align: "left", label: "APPLICATION ID", value: "applicationId", type: "text", sort: false, fn: (v) => v ?? "-" },
//     { key: "pout-row", align: "left", label: "ROW NO.", value: "row", type: "text", sort: false, fn: (v) => v ?? "-"},
//     { key: "pout-error", align: "left", label: "ERROR", value: "error", type: "errorList", sort: false, fn: (v) => v ?? "-"},
// ]


class PayOutScreen extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            filteredDataList: [] ,
            faultyDataList:[],
            hasError: false,
            errorMessage: "",
            companyName:"",
            selectedFile:null,
            loadingForEdit:true,
            showNext:false,
            errors:{},
            companyList:COMPANY,
            data:[],
            transactionId:"",
            listItems:{},
            loading:false,
            responseErrorList:"",
            waitForResponse:false,
            recordsCount:0,
            responseStatus:0,
            nextStepView:false,
            ProcessedPrevList:[]
        }
    }

    componentDidMount () {
        const res= getLocalStorage('LmsUserDetails');
        const { company } = res;
        this.setState({
            companyName:company??"Swiggy"
        })
        this.callApis(company)
    }

    callApis = (company)=>{
        getPayoutPrevList({"company":company},(res)=>{
            if(res && res.success){
                const {prevRecords}= res.response;
                this.setState({
                    ProcessedPrevList:prevRecords
                })
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error",
                })
            }
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=> this.handleValidation());
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { selectedFile} = this.state;

        if (!selectedFile) {
            formIsValid = false;
            errorsTemp["selectedFile"] = "Please select file";
        }
        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    handleField = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }

    sendPayDeduction = e =>{

        e.preventDefault();
        const {selectedFile,companyName} = this.state;
        let formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("company", companyName);
        const AUTH_RESPONSE = getLocalStorage('auth');
        const headers = {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${AUTH_RESPONSE.access_token}`
        }

        if(this.handleValidation()){
            this.setState({
                loading:true,
                waitForResponse:false,
                nextStepView:true
            })
            postPayoutFile(formData, headers,async (res)=>{
                const json = await res;
                const { statusCode} = json ?json.response:{"statusCode":3};
                if(json && statusCode===1){
                    const {processId} = json.response;
                    let timer = setInterval(() => {
                        getPayoutResponseWithProcessId(processId,(res)=>{
                           if(res && res.success){
                            const {processedCount,status} = res.response;
                             if(status==="COMPLETED"){
                                this.setState({
                                    recordsCount:processedCount,
                                    responseStatus:1
                                })
                                clearInterval(timer);
                             }else{
                                this.setState({
                                    recordsCount:processedCount,
                                    responseStatus:0
                                })
                             }

                           }else{
                            clearInterval(timer);
                            this.setState({
                                nextStepView:false,
                                hasError:true,
                                errorMessage:"",
                                errorType:"error",
                                loading:false,
                              })
                           }
                        })
                    }, 15000);
                    this.setState({
                        hasError:true,
                        errorMessage:json.message?json.message:"Successfully uploaded",
                        errorType:"success",
                        selectedFile:null,
                        loading:false
                      })


                }else if(json && statusCode===2){
                    this.setState({
                        hasError:true,
                        errorMessage:"There are many errors in this file, first fix them and then upload",
                        errorType:"error",
                        loading:false,
                        waitForResponse:true,
                        responseErrorList:json && json.message?json.message:"",
                        nextStepView:false
                      })
                }else{
                  this.setState({
                    hasError:true,
                    errorMessage:"unexpected error,please try after sometime",
                    errorType:"error",
                    loading:false,
                    nextStepView:false
                  })
                }
            })
        } else {
            this.setState({
            hasError:true,
            errorMessage:"Please fill the details correctly ",
            errorType:"error"
            })
         }
    }



    loadingModule = () => {
        return (
            <>
            <div style={{fontSize:"22px",marginLeft:"45%",marginTop:40}}>Processing</div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <div >
                {[ ...Array(4).keys() ].map((i) => <Skeleton style={{height:50}} key={i} animation="wave"  />)}
            </div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <div >
                {[ ...Array(4).keys() ].map((i) => <Skeleton style={{height:50}} key={i} animation="wave"  />)}
            </div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            </>
        )
    }
    loadingModuleForCount = () => {
        return (
            <>
            <div style={{fontSize:"22px",marginLeft:"45%",marginTop:40}}>{"Pending ......"}</div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <div >
                {[ ...Array(2).keys() ].map((i) => <Skeleton style={{height:50}} key={i} animation="wave"  />)}
            </div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            </>
        )
    }

    joinError =(err)=>{
       let tError =err.join(" , ");
       return tError;
    }

    handleCols=(data,column,id)=>{
        const { type} = column;
        switch (type) {
            case "errorList":
                return this.joinError(data[column.value])

            default:
                return column.fn(data[column.value])
        }
    }


    listingFunc = (cols,data,loading)=>{
        return (
            <TableContainer style={styles.tableContainer} >
            <Table stickyHeader style={styles.table} aria-label="simple-table">
                <TableHead style={styles.tableHead}>
                    <TableRow >
                        {cols.map(it => <TableCell key={it.key}>
                            {it.label}
                        </TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody style={styles.tableBody} >
                    { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                        data.map((it,i )=> <StyledTableRow  style={styles.tableRow}>
                            {cols.map(col => <TableCell style={styles.bodyTableCell}>
                                {this.handleCols(it,col,i)}
                                </TableCell>
                            )}
                            </StyledTableRow>
                        )
                    }
                </TableBody>
            </Table>
        </TableContainer>
        );
    }

    downloadErrorFile = ()=>{
        const {responseErrorList}=this.state;
        return(
        <>
           <div style={styles.tableContainer}>
                <div className="info">
                     file is consisting some error please download file and resolve them first
                     <a
                        style={styles.downloadBtn}
                        href={responseErrorList}
                     >
                         Download File
                     </a>
                </div>
           </div>
        </>
        )
    }

    render () {
        const { hasError, errorMessage ,companyName,errors,errorType,loading,waitForResponse,recordsCount,responseStatus,nextStepView,
        ProcessedPrevList} = this.state;
        return (
            <>
                <div style={styles.btn}></div>
                <div style={styles.rootContainer}>
                {!loading?<>
                    <form onSubmit={this.sendPayDeduction}>

                    <Typography
                        variant="h5"
                        gutterBottom
                        style={styles.typography}
                    >UPLOAD FILE </Typography>
                   <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Company </Typography>

                                    <TextField
                                        id={companyName}
                                        type="text"
                                        name="companyName"
                                        required
                                        value={companyName}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        disabled
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size={'small'}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Upload File </Typography>
                                    <Button variant="contained" style={{height:40,width:250}} component="label" >
                                    <input
                                        type="file"
                                        //hidden
                                        onChange={this.onFileChange}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        />
                                    </Button>
                                    {errors["selectedFile"]?
                                    <div style={{margin:2,color:"red"}}>{errors["selectedFile"]}</div>
                                    :null}
                                </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    disableFocusRipple
                                    style={styles.addButton}

                                >PROCESS DETAILS</Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </form>
                {nextStepView?
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Grid container item xs={12} spacing={1}>
                            <Grid item xs={3} style={styles.fieldWrapperCount}>
                                Counts :{recordsCount}
                            </Grid>
                            <Grid item xs={1} style={styles.fieldWrapper}>

                            </Grid>
                        {responseStatus===1?<>
                            <Grid item xs={8} style={styles.fieldWrapperSuccess}>
                            Successfully COMPLETED
                            </Grid>
                        </>:
                        <Grid item xs={8} style={styles.fieldWrapperPending}>
                             wait a while the process is going on ....
                        </Grid>
                        }
                    </Grid>
                    {/* {responseStatus==="1"
                    ?null:
                    this.loadingModuleForCount()
                    }  */}
               </Grid>:
               null}
               {this.listingFunc(LISTING_COLS,ProcessedPrevList,false)}
                {waitForResponse?
                // this.listingFunc(LISTING_COLS,responseErrorList,false)
                this.downloadErrorFile()
                :null}
                </>:this.loadingModule()}
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                    type={errorType}
                    duration={5000}
                />


            </>
        );
    }
}

const styles = {
    // headTitle: {
    //     margin: "20px 0px 0px 40vw",
    //     position: "absolute",
    //     size: "55px"
    // },
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute"
    },
    loadingModule:{
        height: "calc(100vh - 310px)",
        backgroundColor:"transparent"
    },
    responseEdit:{
        // position: 'absolute',
        color:"black",
        padding:10,
        margin:"4% 2% 4% 2.5%",
        width:"92%",
        heigth:"auto",
        borderRadius:10,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootContainer: {
        // background: "#fff",
        // outline: "none",
        // padding: 25,
        // borderRadius: 6,
        // margin: "0%",
        // overflowY: "scroll",

        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 25px",
        fontWeight:400
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldWrapperCount: {
        fontFamily: "Poppins",
        padding: 20,
        borderRadius:10,
        backgroundColor:"rgb(233, 243, 237)",
        color:"black",
        fontWeight:400
    },
    fieldWrapperSuccess: {
        fontFamily: "Poppins",
        padding: 20,
        borderRadius:10,
        backgroundColor:"rgb(76, 130, 5)",
        color:"white",
        fontWeight:400
    },
    fieldWrapperPending: {
        fontFamily: "Poppins",
        padding: 20,
        borderRadius:10,
        backgroundColor:"#ddbe5d",
        color:"white",
        fontWeight:400
    },
    container: {
        padding: "0 60px",
        margin:"20px 0px 20px 0px"
    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    fileUpload:{
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        background:"red"
    },
    addButton: {
        width: 250,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    downloadBtn:{
        width: 180,
        height: 40,
        margin: "0px 50px",
        color: "White",
        padding:10,
        textDecoration:"none",
        borderRadius:8,
        background: "rgb(36 123 171 / 86%)",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    tableContainer: {
        height: "calc(60vh - 120px)",
        backgroundColor:"white",
    },
    table: {
    },
    tableRow: {
    },
    tableBody: {
    },
    tableCell: {
    },
    bodyTableCell:{
        padding:"20px"
    }
}
export default observer(PayOutScreen);