import React from "react";
import { 
    Button,
    Typography,
    TextField
} from "@material-ui/core";

export default function CommentBox(props) {
    return (
        <div style={styles.rootContainer}>
            <Typography
                variant="h6"
                gutterBottom
            >Note</Typography>
            <TextField
                multiline
                rows={4}
                rowsMax={6}
                placeholder={"Jot something down"}
                style={styles.commentBox}
                variant="outlined"
            />
            <Button
                style={styles.saveBtn}
                variant="outlined"
            >SAVE</Button>
        </div>
    );
}

const styles = {
    rootContainer: {
        background: "#fff",
        width: 220,
        padding: 15,
        borderRadius: 3,
    },
    typography: {
        marginTop: 5,
    },
    commentBox: {
        background: "#d0e2bc",
        borderRadius: 4
    },
    saveBtn: {
        display: "block",
        marginLeft: "auto",
        marginRight: 10,
        marginTop: 10
    }
}