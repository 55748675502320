import React, {  } from "react";
import { observer } from "mobx-react";

import '../../css/main.css';
import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import "./main.css";
import { postCountForCompany, postCountForCompanyByStatus, postCountsForLoan, postCountsForLoanOthers, postDpdCountForLoanPieChart, postLoanDashboardAmountDisbursedGraph, postLoanDashboardPaymentCollectionGraph, postLoanDashboardUsersInKycSteps } from "../../service/api";
import Alert from "../../components/alert";
import { convertTimestamptoDate } from "../../utils/helper";
import CreateChart from "./customChart";
const count_Type= [
    { key: "Daily", value: "Daily" },
    { key: "Weekly", value: "Weekly" },
    { key: "Monthly", value: "Monthly" },
]

// function CreateChart(props){
//     const {data,options,type} = props;
//     return (<>
//         <Grid container item xs={12} spacing={3}
//             style={styles.fieldWrapper}>
//               <Chart
//                 chartType={type}
//                 width="100%"
//                 height="400px"
//                 data={data}
//                 options={options}
//                 />
//         </Grid>

//     </>)
// }

class LoanDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state={
            ApplicationCountGraph:[],
            loadingForGraph:false,
            hasError:false,
            errorMessage:"",
            errorType:"",
            countType:"Daily",
            companyName:"All",
            loanDetailCounts:{},
            loanDetailCountsOthers:{},
            countsBlockList:[],
            loadingForValueCounts:false,
            loadingForValueCountsOthers:false,
            countDetailStartDate:this.getCurrentDate("lastMonth"),
            countDetailEndDate:this.getCurrentDate("currentMonth"),
            countDetailCompany:"All",
            loanApplicationGraph:{
                countType:"Daily",
                companyName:"All",
                loading:false,
                graphCount:[],
                chartType:"LineChart"
            },
            loanApplicationByStatusGraph:{
                countType:"Daily",
                companyName:"All",
                statusValue:2,
                loading:false,
                graphCount:[]
            },
            loanDpdPieChart:{
                companyName:"All",
                loading:false,
                graphCount:[]
            },
            loanUserKycStepsGraph:{
                loading:false,
                graphCount:[]
            },
            loanAmountDisbursedGraph:{
                countType:"Daily",
                companyName:"All",
                loading:false,
                graphCount:[]
            },
            loanPaymentCollectionGraph:{
                countType:"Daily",
                companyName:"All",
                loading:false,
                graphCount:[]
            },

            basicDetails:[],
            cashfreeDetails:[],
            payUDeatils:[],
            ptpDetails:[],
            waiverRequestDetails:[]
        }
    }

    componentDidMount(){
     this.countApi()
     this.countApiOthers()
     this.responseLoanApplicationGraph();
     this.responseOnchangeByStatus();
     this.responseOfDpdCount();
     this.LoanUsersInKycSteps();
     this.responseOnchangeAmountDesbursedGraph();
     this.responseOnchangePaymentCollectionGraph();
    }
    getCurrentDate=(type)=>{
        const separator="-";
        let newDate = new Date()
        let date = newDate.getDate();
        let month = type==="currentMonth"?newDate.getMonth() + 1:newDate.getMonth();
        let year = newDate.getFullYear();
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
    countApiOthers =()=>{
        let params ={
            // "startDate":countDetailStartDate,
            // "endDate":countDetailEndDate,
            "company":"All"
        }
        this.makeColumns()
        this.setState({
            loadingForValueCountsOthers:true
        })
        postCountsForLoanOthers(params,(res)=>{
            if(res && res.success){
                this.setState({
                    loanDetailCountsOthers:res.response,
                    loadingForValueCountsOthers:false
                })
                this.makeColumns()

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in loan detail cards 2",
                    errorType:"error",
                    loadingForValueCountsOthers:false
                })
            }
        })
    }
    // kyc steps
    LoanUsersInKycSteps =()=>{
        this.setState((state)=> {
            return {
                loanUserKycStepsGraph: Object.assign({},
                state.loanUserKycStepsGraph, {
                    loading:true
              })
            }
          })
        postLoanDashboardUsersInKycSteps({},(res)=>{
            if(res){
                this.setState((state)=> {
                    return {
                        loanUserKycStepsGraph: Object.assign({},
                        state.loanUserKycStepsGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in loan KYC steps api",
                    errorType:"error",
                })
            }
        })
    }
    // amount disbursed graph

    handleFieldAmountDesbursedGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                loanAmountDisbursedGraph: Object.assign({},
                state.loanAmountDisbursedGraph, {
                [name]: value
              })
            }
          })
    }

    responseOnchangeAmountDesbursedGraph=()=>{
        const { companyName,countType}=this.state.loanAmountDisbursedGraph;
        let params ={
            "type": countType,
            "company":companyName,
        }
        this.setState((state)=> {
            return {
                loanAmountDisbursedGraph: Object.assign({},
                state.loanAmountDisbursedGraph, {
                loading:true
              })
            }
          })
        postLoanDashboardAmountDisbursedGraph(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        loanAmountDisbursedGraph: Object.assign({},
                        state.loanAmountDisbursedGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }
     // payment collection graph

     handleFieldPaymentCollectionGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                loanPaymentCollectionGraph: Object.assign({},
                state.loanPaymentCollectionGraph, {
                [name]: value
              })
            }
          })
    }

    responseOnchangePaymentCollectionGraph=()=>{
        const { companyName,countType}=this.state.loanPaymentCollectionGraph;
        let params ={
            "type": countType,
            "company":companyName,
        }
        this.setState((state)=> {
            return {
                loanPaymentCollectionGraph: Object.assign({},
                state.loanPaymentCollectionGraph, {
                loading:true
              })
            }
          })
        postLoanDashboardPaymentCollectionGraph(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        loanPaymentCollectionGraph: Object.assign({},
                        state.loanPaymentCollectionGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }
    countApi =()=>{
        const {
            countDetailCompany,
        }=this.state;
        let params ={
            // "startDate":countDetailStartDate,
            // "endDate":countDetailEndDate,
            "company":countDetailCompany
        }
        this.makeColumns()
        this.setState({
            loadingForValueCounts:true
        })
        // postLoanDashboardAmountDisbursedGraph({},(res)=>{
        //     if(res){

        //     }else{
        //         this.setState({
        //             hasError:true,
        //             errorMessage:"error in loan detail api",
        //             errorType:"error",
        //         })
        //     }
        // })

        // postLoanDashboardPaymentCollectionGraph({},(res)=>{
        //     console.log(res);
        // })
        postCountsForLoan(params,(res)=>{
            if(res && res.success){
                this.setState({
                    loanDetailCounts:res.response,
                    loadingForValueCounts:false
                })
                this.makeColumns()

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in loan detail api",
                    errorType:"error",
                    loadingForValueCounts:false
                })
            }
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item.key} value={item.value}>
                {item.key}</MenuItem>
        })
    }
    loadOptionForCompany = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    handleFieldForLoanApplicationGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                loanApplicationGraph: Object.assign({},
                state.loanApplicationGraph, {
                [name]: value
              })
            }
          })
    }



    handleFieldCountDetail = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }
    responseLoanApplicationGraph=()=>{
        const { companyName,countType}=this.state.loanApplicationGraph;
        let params ={
            "type": countType,
            "company":companyName
        }
        this.setState((state)=> {
            return {
                loanApplicationGraph: Object.assign({},
                state.loanApplicationGraph, {
                   loading:true
              })
            }
          })
        postCountForCompany(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        loanApplicationGraph: Object.assign({},
                        state.loanApplicationGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    /// by status

    handleFieldForLoanApplicationByStatusGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                loanApplicationByStatusGraph: Object.assign({},
                state.loanApplicationByStatusGraph, {
                [name]: value
              })
            }
          })
    }

    responseOnchangeByStatus=()=>{
        const { companyName,countType,statusValue}=this.state.loanApplicationByStatusGraph;
        let params ={
            "type": countType,
            "company":companyName,
            "status":statusValue
        }
        this.setState((state)=> {
            return {
                loanApplicationByStatusGraph: Object.assign({},
                state.loanApplicationByStatusGraph, {
                loading:true
              })
            }
          })
        postCountForCompanyByStatus(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        loanApplicationByStatusGraph: Object.assign({},
                        state.loanApplicationByStatusGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    //// dpd

    handleFieldForLoanDpdCount = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                loanDpdPieChart: Object.assign({},
                state.loanDpdPieChart, {
                [name]: value
              })
            }
          })
    }

    responseOfDpdCount=()=>{
        const { companyName}=this.state.loanDpdPieChart;
        let params ={
            "company":companyName,
        }
        this.setState((state)=> {
            return {
                loanDpdPieChart: Object.assign({},
                state.loanDpdPieChart, {
                loading:true
              })
            }
          })
          postDpdCountForLoanPieChart(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        loanDpdPieChart: Object.assign({},
                        state.loanDpdPieChart, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    makeColumns = () => {
        const {
            loanActiveCount,
            loanAmountDisbursed,
            loanApplicationCount,
            loanApprovedCount,
            loanDataCompletedCount,
            loanRejectCount,
            noOfPayments,
            paymentCollection,
        }=this.state.loanDetailCounts;
        const {
            noOfCashfreePayments,
            cashfreePaymentPending,
            cashfreePaymentSuccess,
            cashfreePaymentCollection,

            noOfPayUPayments,
            payUPaymentsPending,
            payUPaymentsSuccess,
            payUPaymentsFailed,
            payUPaymentCollection,

            noOfPtpDisposed,
            pendingPTP,
            successPTP,
            brokenPTP,
            partialPaymentPTP,
            loanPTPCollection,

            noOfWaiverRequestRaised,
            waiverRequestPending,
            waiverRequestAccepted,
            waiverRequestRejected,
            waiverAmountProcessed,} = this.state.loanDetailCountsOthers;

        this.setState({
            "basicDetails":[
                { key: "dash-count-1", label: "ACTIVE LOANS", value: loanActiveCount??"---", bgColor:"#C8FACD" },
                { key: "dash-count-2", label: "AMOUNT DISBURSED", value: `₹${loanAmountDisbursed??"---"}`, bgColor:"#5BE584"  },
                { key: "dash-count-3", label: "TOTAL APPLICATION ", value: loanApplicationCount??"---", bgColor:"#00AB55" },
                { key: "dash-count-4", label: "TOTAL APPROVED", value: loanApprovedCount??"---", bgColor:"#007B55" },
                { key: "dash-count-5", label: "DATA COMPLETED", value: loanDataCompletedCount??"---", bgColor:"#1939B7" },
                { key: "dash-count-6", label: "TOTAL REJECTED", value: loanRejectCount??"---", bgColor:"#3366FF" },
                { key: "dash-count-7", label: "NO. OF PAYMENTS", value: noOfPayments??"---", bgColor:"#84A9FF" },
                { key: "dash-count-8", label: "PAYMENT COLLECTION", value: `₹${paymentCollection??"---"}`, bgColor:"#D6E4FF" },
               ],
            "cashfreeDetails":[
                { key: "dash-count-9", label: "CF PAYMENTS", value: noOfCashfreePayments??"---", bgColor:"#007B55" },
                { key: "dash-count-10", label: "CF PAYMENT PENDING", value: cashfreePaymentPending??"---", bgColor:"#1939B7" },
                { key: "dash-count-11", label: "CF PAYMENT SUCCESS", value: cashfreePaymentSuccess??"---", bgColor:"#3366FF" },
                { key: "dash-count-12", label: "CF PAYMENT COLLECTION", value: `₹${cashfreePaymentCollection??"---"}`, bgColor:"#84A9FF" },
               ],
            "payUDeatils":[
                { key: "dash-count-13", label: "PAYU PAYMENTS", value:  noOfPayUPayments??"---", bgColor:"#D6E4FF" },
                { key: "dash-count-14", label: "PAYU PAYMENTS PENDING", value: payUPaymentsPending??"---", bgColor:"#007B55" },
                { key: "dash-count-15", label: "PAYU PAYMENTS SUCCESS", value: payUPaymentsSuccess??"---", bgColor:"#1939B7" },
                { key: "dash-count-16", label: "PAYU PAYMENTS FAILED", value: payUPaymentsFailed??"---", bgColor:"#3366FF" },
                { key: "dash-count-17", label: "PAYU PAYMENTS COLLECTION", value:  `₹${payUPaymentCollection??"---"}`, bgColor:"#84A9FF" },
               ],
            "ptpDetails":[
                { key: "dash-count-18", label: "PTP DISPOSED", value: noOfPtpDisposed??"---", bgColor:"#D6E4FF" },
                { key: "dash-count-19", label: "PENDING PTP", value: pendingPTP??"---", bgColor:"#007B55" },
                { key: "dash-count-20", label: "SUCCESS PTP", value: successPTP??"---", bgColor:"#1939B7" },
                { key: "dash-count-21", label: "BROKEN PTP", value: brokenPTP??"---", bgColor:"#3366FF" },
                { key: "dash-count-22", label: "PARTIAL PAYMENT PTP", value: partialPaymentPTP??"---", bgColor:"#84A9FF" },
                { key: "dash-count-23", label: "LOAN PTP COLLECTION", value: `₹${loanPTPCollection??"---"}`, bgColor:"#D6E4FF" },
               ],
            "waiverRequestDetails":[
                { key: "dash-count-4", label: "WAIVER REQUEST RAISED", value: noOfWaiverRequestRaised??"---", bgColor:"#007B55" },
                { key: "dash-count-5", label: "WAIVER REQUEST PENDING", value: waiverRequestPending??"---", bgColor:"#1939B7" },
                { key: "dash-count-6", label: "WAIVER REQUEST ACCEPTED", value: waiverRequestAccepted??"---", bgColor:"#3366FF" },
                { key: "dash-count-7", label: "WAIVER REQUEST REJECTED", value: waiverRequestRejected??"---", bgColor:"#84A9FF" },
                { key: "dash-count-8", label: "WAIVER AMOUNT PROCESSED", value: `₹${waiverAmountProcessed??"---"}`, bgColor:"#D6E4FF" },
               ]
        })

    }

    falsifyError = () => {
        this.setState({
            hasError:false
        })
    }
    dateValidation =(type)=>{
        const {
            countDetailStartDate,
            countDetailEndDate,
        }=this.state;
        const tempEndDate = Date.parse(countDetailEndDate)-24*60*60*1000;
        const tempStartDate = Date.parse(countDetailStartDate)+24*60*60*1000;
        switch(type){
            case "startDate":
                return convertTimestamptoDate(tempEndDate);
            case "endDate":
                return convertTimestamptoDate(tempStartDate);
            default :
                 return "";
        }

    }

    loadingFunction =()=>{

        return(<>

            <div class="flexbox" >
                <div>

                    <div class="nb-spinner">
                    </div>
                </div>
            </div>

        </>)
    }

    displayCards = ( details,state,title)=>{
        return (<>
            <div className="text" style={{margin:10,color:"rgb(16,12,109)"}}>{title}</div>
            <Grid container item xs={12} spacing={1}>
                 {this.blockDisplay(details,state)}
            </Grid>
        </>)
    }

    blockDisplay = (details,stat)=>{

        return (<>
        {stat?
                <>
                {details.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    <div className="spinner-box-small" style={{padding:7}}>
                                        <div className="circle-border-small">
                                            <div className="circle-core-small"></div>
                                        </div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>:
                <>

                {details.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    {item.value}
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>
                }
        </>)

    }

    render(){
        const {companyList} = this.props;
        const { hasError,errorMessage,errorType,loadingForValueCounts,
        countDetailCompany,loanApplicationByStatusGraph,loanDpdPieChart,basicDetails,cashfreeDetails,
        payUDeatils,waiverRequestDetails,ptpDetails,loanUserKycStepsGraph,loanAmountDisbursedGraph,loanPaymentCollectionGraph,
        loanApplicationGraph} = this.state;
        const { changeTo} = this.props;

        return(<>
         <div className="start-container">
        <div className="MuiCardContent-root css-16ffwbg">
            <h4 className="MuiTypography-root MuiTypography-h4 MuiTypography-gutterBottom css-1w7rmh">
                Loan Dashboard
            </h4>
            <p className="MuiTypography-root MuiTypography-body2 css-1v6tuzf">
            Here you will get all the information about the loan App, you will be able to find different types of information here.
            </p>

        </div>
    </div>
    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
            <div className="block">
                <hr />
                <div className="text">Details</div>
                <hr />
            </div>


            <Grid container item xs={12} spacing={1}>

                {/* <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>Start Date: </Typography>
                    <TextField
                        id={countDetailStartDate}
                        type="date"
                        name="countDetailStartDate"
                        required
                        value={countDetailStartDate}
                        onChange={this.handleFieldCountDetail}
                        style={styles.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{inputProps: { min: "2021-01-01", max: this.dateValidation("startDate")} }}
                        size={'small'}
                        variant="outlined"
                    >
                    </TextField>
                </Grid>
                <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>End Date: </Typography>
                    <TextField
                        id={countDetailEndDate}
                        type="date"
                        name="countDetailEndDate"
                        required
                        value={countDetailEndDate}
                        onChange={this.handleFieldCountDetail}
                        style={styles.textField}
                        InputLabelProps={{
                            shrink: true
                        }}
                        InputProps={{inputProps: { min: this.dateValidation("endDate"), max: this.getCurrentDate("currentMonth")} }}
                        size={'small'}
                        variant="outlined"
                    >

                    </TextField>
                </Grid> */}
                <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>Company: </Typography>
                    <TextField
                        id={countDetailCompany}
                        type="select"
                        name="countDetailCompany"
                        required
                        value={countDetailCompany}
                        onChange={this.handleFieldCountDetail}
                        style={styles.textField}
                        select={true}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size={'small'}
                        variant="outlined"
                    >
                        {this.loadOptionForCompany(companyList)}
                    </TextField>
                </Grid>
                <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Button
                        style={styles.countGetBtn}
                        onClick={this.countApi}
                    >Get</Button>
                </Grid>
            </Grid>

            {this.displayCards(basicDetails,loadingForValueCounts,"Basic Details")}
            {this.displayCards(cashfreeDetails,loadingForValueCounts,"Cashfree Details")}
            {this.displayCards(payUDeatils,loadingForValueCounts,"PayU Details")}
            {this.displayCards(waiverRequestDetails,loadingForValueCounts,"Waiver Request Details")}
            {this.displayCards(ptpDetails,loadingForValueCounts,"PTP Details")}
            <div className="block">
                <hr />
                <div className="text">Analytics</div>
                <hr />
            </div>
            <div className="text">Loan Application Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={loanApplicationGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={loanApplicationGraph.countType}
                            onChange={this.handleFieldForLoanApplicationGraph }
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanApplicationGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={loanApplicationGraph.companyName}
                            onChange={this.handleFieldForLoanApplicationGraph }
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    {/* <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanApplicationGraph.chartType}
                            type="select"
                            name="chartType"
                            required
                            value={loanApplicationGraph.chartType}
                            onChange={this.handleFieldForLoanApplicationGraph }
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(["LineChart","PieChart","Histogram"])}
                        </TextField>
                    </Grid> */}
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseLoanApplicationGraph}
                        >Get</Button>
                    </Grid>

                </Grid>
                <CreateChart
                    type={loanApplicationGraph.chartType}
                    options={
                        {
                            title: `Application Counts for ${loanApplicationGraph.companyName}`,
                            curveType: "function",
                        }
                    }
                    data={loanApplicationGraph.graphCount}
                    loading={loanApplicationGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">Loan Application By Status Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={loanApplicationByStatusGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={loanApplicationByStatusGraph.countType}
                            onChange={this.handleFieldForLoanApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanApplicationByStatusGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={loanApplicationByStatusGraph.companyName}
                            onChange={this.handleFieldForLoanApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Status: </Typography>
                        <TextField
                            id={loanApplicationByStatusGraph.statusValue}
                            type="select"
                            name="statusValue"
                            required
                            value={loanApplicationByStatusGraph.statusValue}
                            onChange={this.handleFieldForLoanApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany([1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18])}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOnchangeByStatus}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            title: `Application Counts for ${loanApplicationByStatusGraph.companyName} by status`,
                            curveType: "function",
                        }
                    }
                    data={loanApplicationByStatusGraph.graphCount}
                    loading={loanApplicationByStatusGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">DPD Counts in Given Range</div>
            <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanDpdPieChart.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={loanDpdPieChart.companyName}
                            onChange={this.handleFieldForLoanDpdCount}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>

                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOfDpdCount}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="PieChart"
                    options={
                        {
                            title: `DPD Counts`,
                        }
                    }
                    data={loanDpdPieChart.graphCount}
                    loading={loanDpdPieChart.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">Loan Users Kyc Steps Graph</div>
            <Grid container item xs={12} spacing={1}>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            curveType: "function",
                        }
                    }
                    data={loanUserKycStepsGraph.graphCount}
                    loading={loanUserKycStepsGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">Loan Amount disbursed Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={loanAmountDisbursedGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={loanAmountDisbursedGraph.countType}
                            onChange={this.handleFieldAmountDesbursedGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanAmountDisbursedGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={loanAmountDisbursedGraph.companyName}
                            onChange={this.handleFieldAmountDesbursedGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOnchangeAmountDesbursedGraph}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            title: `Amount Disbursed for ${loanAmountDisbursedGraph.companyName}`,
                            curveType: "function",
                        }
                    }
                    data={loanAmountDisbursedGraph.graphCount}
                    loading={loanAmountDisbursedGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">Loan Payment Collection Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={loanPaymentCollectionGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={loanPaymentCollectionGraph.countType}
                            onChange={this.handleFieldPaymentCollectionGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={loanPaymentCollectionGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={loanPaymentCollectionGraph.companyName}
                            onChange={this.handleFieldPaymentCollectionGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOnchangePaymentCollectionGraph}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            title: `Loan Payment collection for ${loanPaymentCollectionGraph.companyName}`,
                            curveType: "function",
                        }
                    }
                    data={loanPaymentCollectionGraph.graphCount}
                    loading={loanPaymentCollectionGraph.loading}
                    />
            </Grid>


    </Grid>
    <Alert
        open={hasError}
        message={errorMessage}
        type={errorType}
        callback={this.falsifyError}
    />
        </>)
    }
}
const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    container: {
        padding: "20px 60px",
    },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20,
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 180,
        height: 40
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    countGetBtn:{
        marginTop:25,
        marginBottom: 20,
        alignSelf: "center",
        width: 80,
        height: 30,
        background:"#72A0C1",
        color:"white",
        fontWeight:600
    }
}
export default observer(LoanDashboard);