import * as React from 'react';
import { Colors } from '../../styles';
import { CommonIcons } from '../../assets';
import CheckIcon from '@material-ui/icons/Check';

const NotGeneratedLabel = () => (
	<div
		style={{
			padding: '4px 8px',
			borderRadius: '4px',
			backgroundColor: Colors.semanticColorFailed01,
			color: Colors.white,
			fontWeight: '500',
			fontSize: '12px',
			textAlign: 'center',
			width: 'fit-content'
		}}>
		Not Generated
	</div>
);

const IconTextDisabled = ({
	image = 'copy',
	text
}) => {
	const { SmsIconDisabled, WAIconDisabled, CopyIconDisabled } = CommonIcons;
	let icon = null;
	image === 'copy'
		? (icon = CopyIconDisabled)
		: image === 'sms'
			? (icon = SmsIconDisabled)
			: (icon = WAIconDisabled);
	return (
		<span
			style={{
				...styles.verticalCenter,
				color: Colors.typographyColor02
			}}>
			<img src={icon} style={styles.icon} />
			{text.toUpperCase()}
		</span>
	);
};

const IconText = ({
	image = 'copy',
	text,
	onPress
}) => {
	const [sent, setSent] = React.useState(false);
	const { SmsIcon, WAIcon, CopyIcon } = CommonIcons;
	const setStatus = () => {
		if (!sent) {
			setSent(true);
			onPress();
			setTimeout(() => {
				setSent(false);
			}, 10000);
		}
	};
	let icon = null;
	image === 'copy'
		? (icon = CopyIcon)
		: image === 'sms'
			? (icon = SmsIcon)
			: (icon = WAIcon);
	return (
		<span onClick={setStatus} style={styles.verticalCenter}>
			{sent && <CheckIcon style={styles.sentIcon} />}
			{!sent && <img src={icon} style={styles.icon} />}
			{text.toUpperCase()}
		</span>
	);
};

export const LinkWrapper = ({ children }) => {
	return (
		<table style={styles.table}>
			<tbody>{children}</tbody>
		</table>
	);
};

const LinkRowDisabled = (item) => {
	return (
		<tr style={styles.tr}>
			<td style={styles.td}>{item.name}</td>
			<td style={styles.td}>
				<NotGeneratedLabel />
			</td>
			<td style={styles.td}>
				<IconTextDisabled text="Copy" image="copy" />
			</td>
			<td style={styles.td}>
				<IconTextDisabled text="SMS" image="sms" />
			</td>
			<td style={styles.td}>
				<IconTextDisabled text="WhatsApp" image="wa" />
			</td>
		</tr>
	);
};

export const LinkRow = (props) => {
	const { name, link, idx, sendSMS, sendWA, copyLink } = props;
	if (!link) {
		return <LinkRowDisabled {...props} />;
	}
	return (
		<tr style={styles.tr}>
			<td style={styles.td}>{name}</td>
			<td style={styles.td}>
				<a
					style={styles.a}
					href={link}
					target="_blank"
					rel="noreferrer">{`Link ${idx}`}</a>
			</td>
			<td style={{ ...styles.td, ...styles.cursorPointer }}>
				<IconText text="Copy" image="copy" onPress={copyLink} />
			</td>
			<td style={{ ...styles.td, ...styles.cursorPointer }}>
				<IconText text="SMS" image="sms" onPress={sendSMS} />
			</td>
			<td style={{ ...styles.td, ...styles.cursorPointer }}>
				<IconText text="WhatsApp" image="wa" onPress={sendWA} />
			</td>
		</tr>
	);
};

const styles = {
	table: {
		width: '100%',
		borderCollapse: 'collapse'
	},
	tr: {
		border: `1px solid ${Colors.typographyColor06}`
	},
	td: {
		padding: '16px 24px',
		fontSize: '14px'
	},
	icon: {
		height: 16,
		width: 16,
		marginRight: 13
	},
	verticalCenter: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		justifyContent: 'center'
	},
	a: {
		textDecorationLine: 'underline',
		color: Colors.supportingColorInProgress01,
		cursor: 'pointer'
	},
	cursorPointer: {
		cursor: 'pointer'
	},
	sentIcon: {
		marginRight: '8px',
		color: Colors.semanticColorSuccess01
	}
};
