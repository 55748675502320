export const SearchDataDefaultResponse = {
	currentDate: null,
	dpd: 0,
	warningLetter1: true,
	warningLetter2: false,
	warningLetter3: false,
	warningLetter1Url: null,
	warningLetter2Url: null,
	warningLetter3Url: null
};

export const HistoryDataDefaultResponse = {
	warningLetterHistory: [{
		date: null,
		loanId: null,
		mode: null,
		status: null,
		triggeredBy: 'SYSTEM',
		warningLetterType: 'WARNING_LETTER_1',
	}]
};
