import React from "react";
import Detail from "./../../components/detail";
import { findByPathName, convertTimestamptoDate } from "./../../utils/helper";
import { getCreditLineApplicationInfo,  getCreditLineDigiLockerInfo, getCreditLineEmandateInfo, getCreditLineKycInfo } from "./../../service/api";

import { LOAN_REASON_CODES, rolePermission } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";

class CreditLineDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            data: [],
            clApplicationDetails: {},
            clKycDetails: {},
            clEmandateDetails: {},
            clDigilockerDetails: {}
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const userId = findByPathName(location);
        this.callApis(userId);
    }

    callApis = (id) => {

        const getCreditLineApplicationInfoPromise = new Promise((resolve, reject) => {
            getCreditLineApplicationInfo(id, {}, res => resolve(res))
        })
        const getCreditLineKycInfoPromise = new Promise((resolve, reject) => {
            getCreditLineKycInfo(id, {}, res => resolve(res))
        })
        const getCreditLineEmandateInfoPromise = new Promise((resolve, reject) => {
            getCreditLineEmandateInfo(id, {}, res => resolve(res))
        })
        const getCreditLineDigiLockerInfoPromise = new Promise((resolve, reject) => {
            getCreditLineDigiLockerInfo(id, {}, res => resolve(res))
        })

        Promise.all([
            getCreditLineApplicationInfoPromise,
            getCreditLineKycInfoPromise,
            getCreditLineEmandateInfoPromise,
            getCreditLineDigiLockerInfoPromise
        ]).then(res => {
            this.setState({
                clApplicationDetails: res[0],
                clKycDetails: res[1],
                clEmandateDetails: res[2],
                clDigilockerDetails: res[3],
                id: id
            }, () => this.makeColumns())
        }).catch(err => {
            this.setState({
                hasError: true,
                loading: false,
                id: id
            })
        })
    }

    createApplicationDetail = (dat) => {

        if (!dat) return [];

        const showReference = ref => {
            return String('Name ' + (ref[0].name ?? '-') + '\nPhone ' + (ref[0].phone ?? '-'));
        }

        const { applicationId, name, phone, reference1, reference2, company,  creditLineStatus,  nameOnPan,
             approvedAmount, remainingAmount, accountCreationDate, applicationCompleteDate, documentUploadDate,
            tncAcceptDate, outstandingBalance, dueAmount, reasonCode,freeze,collectionMode,userId,creditLineId ,
            disbursing,clDisbursing,dpdCount} = dat;

        return [
            { key: "credit-line-application-id", label: "APPLICATION ID ", value: applicationId },
            { key: "credit-line-name", label: "NAME", value: name },
            { key: "credit-line-phone", label: "PHONE", value: phone },
            { key: "credit-line-reference1", label: "REFERENCE 1", value:reference1? showReference(reference1):"-" },
            { key: "credit-line-reference2", label: "REFERENCE 2", value: reference2?showReference(reference2):"-" },
            { key: "credit-line-company", label: "COMPANY", value: company },
            { key: "credit-line-status", label: "CREDIT LINE STATUS", value: creditLineStatus },
            { key: "credit-line-name-on-pan", label: "NAME ON PAN", value: nameOnPan },

            { key: "credit-line-approved-amount", label: "APPROVED AMOUNT", value: approvedAmount },
            { key: "credit-line-remaining-amount", label: "REMAINING AMOUNT", value: remainingAmount },
            { key: "credit-line-outstanding-balance", label: "OUTSTANDING BALANCE", value: outstandingBalance },
            { key: "credit-line-due-amount", label: "DUE AMOUNT", value: dueAmount },
            { key: "credit-line-account-creation-date", label: "ACCOUNT CREATION DATE", value: convertTimestamptoDate(accountCreationDate) },
            { key: "credit-line-application-complete-date", label: "APPLICATION COMPLETE DATE", value: convertTimestamptoDate(applicationCompleteDate) },
            { key: "credit-line-document-upload-date", label: "DOCUMENT UPLOAD DATE", value: convertTimestamptoDate(documentUploadDate) },
            { key: "credit-line-tnc-accept-date", label: "T&C ACCEPT DATE", value: convertTimestamptoDate(tncAcceptDate) },
            { key: "credit-line-reason-code", label: "REASON CODE", value: LOAN_REASON_CODES[reasonCode] },
            { key: "credit-line-freeze", label: "FREEZE", value: freeze?"YES":"NO" },
            { key: "credit-line-collection-mode", label: "COLLECTION MODE", value: collectionMode??"-" },
            { key: "credit-line-user-id", label: "USER ID", value: userId??'-' },
            { key: "credit-line-cl-id", label: "CREDITLINE ID", value: creditLineId??"-" },
            { key: "credit-line-disbursing", label: "DISBURSING", value: disbursing?"YES":"NO" },
            { key: "credit-line-cl-disbursing", label: "CL DISBURSING", value: clDisbursing?"YES":"NO" },
            { key: "credit-line-dpd-count", label: "DPD", value: dpdCount??"-" },


            // { key: "credit-line-application-id", label: "LENDER", value: lender },
            // { key: "credit-line-last-login", label: "LAST LOGIN", value: convertTimestamptoDate(lastLoginDate) },
            // { key: "credit-line-reference1", label: "APPLIED AMOUNT", value: appliedAmount },
            // { key: "credit-line-statement-due-date", label: "STATEMENT DUE DATE", value: convertTimestamptoDate(statementDueDate) },
            // { key: "credit-line-name", label: "ACCOUNT STATUS", value: accountStatus },
        ]
    }

    createKycDetail = (dat) => {
        if (!dat) return [];
        const { photo, panCard, aadharFront, aadharBack } = dat;
        return [
            { key: "credit-line-photo", label: "PHOTO", value: photo },
            { key: "credit-line-panCard", label: "PANCARD", value: panCard },
            { key: "credit-line-aadharFront", label: "AADHAR FRONT", value: aadharFront },
            { key: "credit-line-aadharBack", label: "AADHAR BACK", value: aadharBack },
        ]
    }

    createBankDetail = (dat) => {
        if (!dat) return [];
        const { bankName, accountNumber, ifscCode, accountHolderName } = dat.bankDetail;
        return [
            { key: 'credit-line-bank-name', label: 'BANK NAME', value: bankName },
            { key: 'credit-line-account-number', label: 'ACCOUNT NUMBER', value: accountNumber },
            { key: 'credit-line-ifsc', label: 'IFSC CODE', value: ifscCode },
            { key: 'credit-line-account-holder-name', label: 'ACCOUNT HOLDER NAME', value: accountHolderName },
        ]
    }

    createEmandateDetail = (dat) => {
        if (!dat) return [];
        const { createdDate, completeDate, emandateStatus, emandateBankName, emandateAccountNumber, emandateIfscCode } = dat;
        return [
            { key: 'credit-line-emandate-createdDate', label: 'CREATED DATE', value: convertTimestamptoDate(createdDate) },
            { key: 'credit-line-emandate-completeDate', label: 'COMPLETE DATE', value: convertTimestamptoDate(completeDate) },
            { key: 'credit-line-emandate-status', label: 'EMANDATE STATUS', value: emandateStatus },
            { key: 'credit-line-emandate-emandateBankName', label: 'BANK NAME', value: emandateBankName },
            { key: 'credit-line-emandate-emandateAccountNumber', label: 'ACCOUNT NUMBER', value: emandateAccountNumber },
            { key: 'credit-line-emandate-emandateIfscCode', label: 'IFSC CODE', value: emandateIfscCode },
        ]
    }

    createDigilockerPanDetail = (dat) => {
        if (!dat) return [];
        const { nameOnPan, dob } = dat;
        return [
            { key: "credit-line-digilocker-name-on-pan", label: "NAME ON PAN", value: nameOnPan },
            { key: "credit-line-digilocker-dob-on-pan", label: "DOB ON PAN", value: dob }
        ]
    }

    createDigilockerAadhaarDetail = (dat) => {
        if (!dat) return [];
        const { aadharPdf } = dat;
        return [
            { key: "credit-line-digilocker-aadhaar-detail", label: "", value: aadharPdf }
        ]
    }

    createViewDetail = () => {
        return [
            { key: "credit-line-statement-detail", label: "STATEMENT", value: 'View', fn: (v) => v },
            { key: "credit-line-emandate-detail", label: "EMANDATE", value: 'View', fn: (v) => v },
            { key: "credit-line-payment", label: "PAYMENT", value: 'View', fn: (v) => v },
            { key: "credit-line-all-payment-detail", label: "ALL PAYMENT", value: 'View', fn: (v) => v },
           // { key: "promise-to-pay-creditLine", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v }
        ]
    }

    generatePaymentLink = () => {
        const { role} = getLocalStorage("LmsUserDetails");

        const checkPermission = (PERMISSIONS)=>{
            let conditionCheck = false;
                for(let item of PERMISSIONS){
                    if(role.includes(item)){
                        conditionCheck=true;
                        break;
                    }
            }
            
            return conditionCheck;
        }

        if(checkPermission(["ROLE_ADMIN","ROLE_CE","ROLE_CRM_ADMIN","ROLE_ADMIN_RO"])){
            return [
                { key: "cl-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
                { key: "promise-to-pay-creditLine", label: "PROMISE TO PAY", value: 'Create', fn: (v) => v }
            ]
        }else{
            return [
                { key: "cl-payment-link", label: "GENERATE", value: 'Generate', fn: (v) => v },
            ]
        }
        
    }

    checkViewPermission = (key) => {
        const role = "roleAdmin";
        return rolePermission[role].includes(key);
    }

    makeColumns = () => {

        const { clApplicationDetails, clKycDetails, clEmandateDetails, clDigilockerDetails } = this.state;

        const detailData = [
            { key: "credit-line-app-detail-col", label: clApplicationDetails ? "APPLICATION DETAIL" : "", value: this.createApplicationDetail(clApplicationDetails), viewPermission: this.checkViewPermission("credit-line-app-detail-col") },
            { key: "credit-line-kyc-detail-col", label: clKycDetails ? "KYC DETAIL" : "", value: this.createKycDetail(clKycDetails), viewPermission: this.checkViewPermission("credit-line-kyc-detail-col") },
            { key: "credit-line-emandate-detail-col", label: clEmandateDetails ? "EMANDATE DETAIL" : "", value: this.createEmandateDetail(clEmandateDetails), viewPermission: this.checkViewPermission("credit-line-emandate-detail-col") },
            { key: "credit-line-digilocker-pan-col", label: clDigilockerDetails ? "DIGILOCKER PAN DETAIL" : "", value: this.createDigilockerPanDetail(clDigilockerDetails), viewPermission: this.checkViewPermission("credit-line-digilocker-pan-col") },
            { key: "credit-line-digilocker-aadhaar-col", label: clDigilockerDetails ? "DIGILOCKER AADHAAR" : "", value: this.createDigilockerAadhaarDetail(clDigilockerDetails), viewPermission: this.checkViewPermission("credit-line-digilocker-aadhaar-col") },
            { key: "view-click-detail", label: "VIEWS", value: this.createViewDetail(), viewPermission: this.checkViewPermission("view-click-detail") },
            { key: "generate-payment-link", label: "GENERATE PAYMENT LINK", value: this.generatePaymentLink(), viewPermission: this.checkViewPermission("generate-payment-link") }
    
        ]

        this.setState({
            data: detailData
        })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, data, id } = this.state;
        return (
            <Detail
                data={data}
                loading={loading}
                handleBack={this.handleBack}
                id={id}
            />
        );
    }
}

export default CreditLineDetail;