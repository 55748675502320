import * as React from 'react';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Colors, Fonts } from '../../styles';

const AccordianView = ({ defaultExpanded = false, title, style, children }) => {
	return (
		<div style={{...styles.container, ...style}}>
			<Accordion style={styles.accordian} defaultExpanded={defaultExpanded}>
				<AccordionSummary
          style={styles.summary}
					expandIcon={<ExpandMoreIcon />}
					aria-controls="panel1-content"
					id="panel1-header">
					{title ?? 'Default Heading'}
				</AccordionSummary>
				<AccordionDetails style={styles.details}>
					{children}
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

const styles = {
	container: {
    margin: '0 32px',
  },
  accordian: {
    boxShadow: 'none',
  },
  summary: {
    height: '56px',
    fontSize: Fonts.size.small,
    fontWeight: Fonts.weight.medium,
    letterSpacing: '2px',
    border: `1px solid ${Colors.typographyColor06}`,
    borderTopRightRadius: '8px',
    borderTopLeftRadius: '8px',
  },
  details: {
    fontSize: '14px',
    border: `0.5px solid ${Colors.typographyColor06}`,
    fontWeight: Fonts.weight.regular,
    padding: 0,
    maxHeight: '500px',
    overflow: 'scroll'
  }
};

export default AccordianView;
