import * as React from 'react'
import { Colors, Fonts } from '../../styles';


export const TabTextViewer = ({ field, value }) => {
	return (
		<div style={styles.tabTextContainer}>
			<span style={styles.tabTextField}>{field}</span> -{' '}
			<span style={styles.tabTextValue}>{value}</span>
		</div>
	);
};

export const TabTextGroup = ({ currentDate, dpd }) => {
	return (
		<div style={styles.tabTextGroup}>
			<TabTextViewer field={'Current Date'} value={currentDate ?? '-'} />
			<TabTextViewer field={'Current DPD Count'} value={dpd ?? '-'} />
		</div>
	);
};

const styles = {
  tabTextGroup: {
		display: 'flex',
		flex: 'row',
		justifyContent: 'center',
		marginTop: '48px'
	},
	tabTextContainer: {
		border: `1px solid ${Colors.primaryColor01}`,
		padding: '12px',
		marginLeft: '9px',
		borderRadius: '4px',
		backgroundColor: Colors.primaryColor06
	},
	tabTextField: {
		fontSize: Fonts.size.small,
		color: Colors.typographyColor02,
		letterSpacing: '1px'
	},
	tabTextValue: {
		color: Colors.typographyColor01,
		fontWeight: Fonts.weight.bold,
		letterSpacing: '2px'
	}
}