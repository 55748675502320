import React from "react";
import {
    Typography,
    Divider,
    IconButton,
} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

function updateKeys(target) {
    const upRes = {}
    for(let key in target) {
        const targetRes = target[key];
        if(key.includes("Loan")) {
            upRes["customerLoanDetail"] = targetRes;
        } else if(key.includes("Call")){
            upRes["customerCallDetail"] = targetRes;
        } else {
            upRes["customerDetail"] = targetRes;
        }
    } return upRes;
}

function mapObject(target) {
        return Object.keys(target).map((item, i) => <div key={i} style={styles.userInfoRow}>
        <span>{item.toUpperCase()}</span>
        <span style={styles.userInfoOutput}>{target[item]}</span>
    </div>)
}

export default function UserInfo(props) {
    const { data, handleClose } = props;
    const diffdData = updateKeys(data);
    return (
        <div style={styles.rootContainer}>
            <IconButton
                variant="contained"
                aria-controls="close-btn"
                aria-haspopup="true"
                aria-label="close"
                onClick={handleClose}
                style={styles.closeBtn}
                ><CloseIcon /></IconButton>
            <Typography
                variant="h5"
            >USER INFO</Typography>
            <Divider style={styles.divider} />
            <Typography
                variant="h6"
                gutterBottom
            >Customer Details</Typography>
            {mapObject(diffdData.customerDetail)}
            <Typography
                variant="h6"
                gutterBottom
            >Customer Loan Details</Typography>
            {mapObject(diffdData.customerLoanDetail)}
            <Typography
                variant="h6"
                gutterBottom
            >Customer Call Logs Details</Typography>
            {mapObject(diffdData.customerCallDetail)}
        </div>
    );
}

const styles = {
    rootContainer: {
        padding: 40,
        background: "#fff",
        position: "absolute",
        right: 0,
        width: "45%",
        height: "100vh",
        overflowY: "scroll",
        borderTopLeftRadius: 10
    },
    closeBtn: {
        float: "right"
    },
    userInfoRow: {
        display: "flex",
        justifyContent: "space-between",
        fontFamily: "Poppins",
    },
    userInfoOutput: {
        fontWeight: 600
    },
    divider: {
        marginTop: 5,
        marginBottom: 10
    }
}