import React from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import Login from "./../screens/login/login";
import Home from "./../screens/home/home";


function Routing () {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Login} />
                <Route exact path="/login" component={Login} />
                <Route path="/home" component={Home} />
                <Route path="*">
                    <Redirect to="/home" />
                </Route>
            </Switch>
        </Router>
    );
}

export default Routing;