import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import { makeFilters, makeSort, convertTimestamptoDate, debounce } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import WaiverStore from "./../../stores/waiverStore";
import {  getWaiverListing } from "./../../service/api";
import {  WAIVER_REQUEST_STATUS } from "./../../common/constants";
import SearchField from "./../../components/searchField";
import { SEARCH_TYPE_CODES } from "../../common/constants";

const COLS = [
    { key: "waiver-id", align: "center", label: "WAIVER REQUEST ID", value: "waiverRequestId", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "waiver-created-date", align: "center", label: "CREATED DATE", value: "createdDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "waiver-last-updated", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "waiver-active", align: "center", label: "WAIVER STATUS", value: "waiverStatus", type: "text", sort: false, fn: (v) => WAIVER_REQUEST_STATUS[v] },
    { key: "waiver-agent-name", align: "center", label: "AGENT NAME", value: "agent", type: "text", sort: false, fn: (v) => v?? "-" },
    { key: "waiver-loanId", align: "center", label: "LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v?? "-"},
    { key: "waiver-applicationId", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: false, fn: (v) => v?? "-"},

    { key: "waiver-promise-amount", align: "center", label: "PROMISE AMOUNT", value: "promiseAmount", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "waiver-promise-date", align: "center", label: "PROMISE DATE", value: "promiseDate", type: "text", sort: false, fn: (v) => v},
    { key: "waiver-statementId", align: "center", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "waiver-waiver-type", align: "center", label: "WAIVER TYPE", value: "waiverType", type: "text", sort: false, fn: (v) => v==="cl"?"CREDIT_LINE":"LOAN" },
    { key: "waiver-waiveOffAmount", align: "center", label: "WAIVE OFF AMOUNT", value: "waiveOffAmount", type: "text", sort: false, fn: (v) => v ?? "-"},

]


class WaiverListing extends React.Component {
    constructor (props) {
        super(props);
        this.store = WaiverStore;
        this.pagination = PaginationStore;
        this.dbounceCaller = debounce(() => this.fetchWaiverRequest(true));
        this.state = {
            searchText: "",
            searchType: "loanId",
            filter: {},
            loading: true,
            hasError: false,
            errorMessage: "",
            sort: {
                sortType: "desc",
                sort: false
            },
            btnStatus:true,
            pageNumberValue:"1"
        }
    }

    componentDidMount () {
        this.fetchWaiverRequest(true);
    }

    fetchWaiverRequest ($hasUpdate = false) {
        const { loading, filter, sort, searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            page: page,
            pageSize: pageSize
        }

        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }

        const updatedFilter = makeFilters(filter, "loan")

        if (sort.sort) {
            Object.assign(params, sort);
        }

        Object.assign(params, { filter: updatedFilter.filter });

        if (searchText) {
            Object.assign(params, { "searchText": searchText, "searchType": searchType });
        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getWaiverListing(
            params,
            (res) => {
                if (res) {
                    const { page,  totalCount ,waiverRequestList} = res.response;
                    console.log(res)
                    this.store.setData(waiverRequestList);
                    this.pagination.goToPage(page);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchWaiverRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchWaiverRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // search methods

    handleSearch = evt => {

        this.setState({ searchText: evt.target.value });
        this.dbounceCaller();
    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    // sort methods
    handleSort = (sortByValue) => {
        const { sort } = this.state;
        let nSort = sort;

        if (!nSort.sort) {
            nSort = {
                "sortLabel": sortByValue,
                "sortType": "desc",
                "sort": sortByValue
            }
        } else {
            // switch sortType and sortBy in state
            if (nSort.sortLabel === sortByValue) {
                nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
            } else {
                nSort = {
                    "sort": sortByValue,
                    "sortLabel": sortByValue,
                    "sortType": "desc"
                }
            }
        }

        this.setState({
            sort: makeSort(nSort, "loan")
        }, () => this.fetchWaiverRequest(true))

    }

    // filter methods
    handleFilter = data => {
        let tempFilter = Object.assign({}, this.state.filter);
        data.forEach(item => {
            const propertyValue = { [item.property]: item.value };
            Object.assign(tempFilter, propertyValue);
        })

        if (!data.length) {
            tempFilter = {};
        }

        this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
        console.log("updating property...", _filter);
        this.setState({
            filter: _filter
        }, () => this.fetchWaiverRequest(true))
    }

    removeProperty = (property) => {
        let tempFilter = Object.assign({}, this.state.filter);
        delete tempFilter[property];
        this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
        this.setState({
            filter: {}
        }, () => this.updateProperty())
    }

    goToSettlement = ()=>{
        const { location, history } = this.props;
        const pathName = location.pathname;
        history.push(pathName + "/settlement");
    }

    render () {
        const { loading, hasError, errorMessage, sort,pageNumberValue } = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>

                <SearchField
                    placeholder={"Loan"}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["waivers"]}
                />
                <div style={styles.btn}></div>
                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={true}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        flexWrap: 'wrap',
    }
}
export default observer(WaiverListing);