const regex = {
  onlyDigit: /^[0-9]+$/
}

function OnlyDigitAllowed(input) {
  return regex.onlyDigit.test(input)
}

export default {
  OnlyDigitAllowed,
}