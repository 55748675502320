import React from "react";
import PNF from "./../assets/notFound.png";
const styles = {
    objectFit: "contain",
    height: "calc(100vh - 204px)",
    width: "100%"
}
export default function PageNotFound() {
    return (
        <div>
            <img src={PNF} alt="page_not_found" style={styles} />
        </div>
    );
}