import axios from "axios";
import createEnvironment from "./../global";
import { getLocalStorage } from "../utils/localStorage";

const ENV_VAR = createEnvironment();

const BASE_URL = ENV_VAR.BASE_URL;
const END_URL = ENV_VAR.END_URL;
const CREDIT_LINE_END_URL = ENV_VAR.CREDIT_LINE_URL;
const LOAN_URL = ENV_VAR.LOAN_URL;
const LOAN_BASE_URL = ENV_VAR.LOAN_BASE_URL;
const UNDERWRITING_URL = ENV_VAR.UNDERWRITING_URL;

export const PATHS = {
    LOGIN: BASE_URL + "/auth/oauth/auth/login",
    CRM_LMS_USER_AUTH: BASE_URL + "/auth/oauth/lmsUserDetails",
    CRM_AGENT: END_URL + "/crm/agent",
    CRM_UPDATE_AGENT: END_URL + "/crm/updateAgent",
    CRM_COLLECTION: END_URL + "/crm/collection",
    CRM_REFERENCE: END_URL + "/crm/reference",
    CRM_LOAN_PAYMENT: END_URL + "/crm/loanPayment",
    CRM_PAYMENT_RECORD: END_URL + "/crm/payment/record",
    CRM_USER_REFERENCE: (id) => END_URL + "/crm/" + id + "/reference",
    CRM_LOANID_PAYMENT: (id) => END_URL + "/crm/" + id + "/payment",
    CRM_USER_CALLLOG: (id) => END_URL + "/crm/" + id + "/callLog",
    CRM_LOAN_COLLECTION: (id) => END_URL + "/crm/" + id + "/collection",
    CRM_USER: LOAN_URL + "/user",
    CRM_USER_DETAIL: (id) => END_URL + "/user/" + id,
    CRM_LOAN: LOAN_URL + "/loan",
    CRM_LOAN_DETAIL: (id) => END_URL + "/loan/" + id,
    CRM_LOAN_STATEMENT: (id) => LOAN_URL + "/loan/statement/" + id,
    CRM_LOAN_USER_PAYMENT: (id) => LOAN_URL + '/payment/' + id,

    CRM_CREDIT_LINE: CREDIT_LINE_END_URL + '/credit-line-application',
    CRM_CREDIT_LINE_DETAIL: (id) => CREDIT_LINE_END_URL + '/credit-line-application/' + id,
    CRM_LOAN_SEARCH: (type, searchType, id) => LOAN_URL + '/' + type + '/search/' + searchType + '/' + id,
    CRM_CREDIT_LINE_SEARCH: (type, searchType, id) => CREDIT_LINE_END_URL + '/' + type + '/search/' + searchType + '/' + id,

    CRM_CREDIT_LINE_STATEMENT: (id) => CREDIT_LINE_END_URL + '/credit-line-statement-detail/' + id,
    CRM_CREDIT_LINE_TRANSACTION: (id) => CREDIT_LINE_END_URL + '/credit-line-statement-detail/' + id,
    CRM_CREDIT_LINE_EMANDATE: (id) => CREDIT_LINE_END_URL + '/credit-line-emandate-detail/' + id,
    CRM_CREDIT_LINE_PAYMENT: (id) => CREDIT_LINE_END_URL + '/credit-line-payment/' + id,

    CRM_LOAN_EMANDATE: (id) => LOAN_URL + '/loan-emandate-detail/' + id,

    // user_details
    CRM_USER_DETAIL_USER_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/user/" + id,
    CRM_USER_DETAIL_LOAN_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/user/" + id + "/loan",
    CRM_USER_DETAIL_CREDITLINE_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/user/" + id + "/creditLine",
    CRM_USER_DETAIL_KYC_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/user/" + id + "/kyc",

    // loan_details

    CRM_LOAN_DETAIL_LOAN_DETAIL: (id) => LOAN_URL + "/loan/" + id,
    CRM_LOAN_DETAIL_USER_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/user",
    CRM_LOAN_DETAIL_APPLICATION_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/application",
    CRM_LOAN_DETAIL_DISBURSEMENT_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/disbursement",
    CRM_LOAN_DETAIL_BANK_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/bank",
    CRM_LOAN_DETAIL_KYC_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/kyc",
    CRM_LOAN_DETAIL_EMANDATE_DETAIL: (id) => LOAN_URL + "/loan/" + id + "/emandate",
    CRM_LOAN_DEDUCTION_DETAILS: (id) => LOAN_BASE_URL + "/lms/crm/loan/" + id + "/deduction",

    CRM_LOAN_DIGILOCKER_DETAILS: (id) => LOAN_BASE_URL + "/lms/crm/loan/" + id + "/digiLocker",
    CRM_LOAN_CKYC_DOCUMENT_DETAILS: (id) => LOAN_BASE_URL + `/lms/crm/loan/${id}/CKycDetail`,


    CRM_LOAN_UPI_AUTOPAY_DETAILS: (id) => LOAN_BASE_URL + "/lms/crm/loan/" + id + "/upiAutoPay",
    CRM_RECORD_UPDATED_PAYMENT: (id) => LOAN_URL + "/refresh/cashfree/payment/status/" + id,


    // credit_details
    CRM_CREDITLINE_APPLICATION_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/credit-line-application/" + id,
    CRM_CREDITLINE_KYC_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/credit-line-application/" + id + "/kyc",
    CRM_CREDITLINE_DIGILOCKER_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/credit-line-application/" + id + "/digiLocker",
    CRM_CREDITLINE_EMANDATE_DETAIL: (id) => CREDIT_LINE_END_URL + "/v2/credit-line-application/" + id + "/emandate",

    CRM_LMS_ADD_USER: BASE_URL + "/auth/oauth/addUser",
    CRM_LMS_USER: BASE_URL + "/auth/oauth/lmsUsers",
    CRM_LMS_USER_DETAILS: (id) => BASE_URL + "/auth/oauth/lmsUser/" + id,
    CRM_LMS_EDIT_USER: (id) => BASE_URL + "/auth/oauth/editUser/" + id,
    CRM_AMORT_SCHEDULE: (id) => LOAN_URL + '/loan/amort/' + id,

    CRM_LOAN_PAYMENT_LINK_GENERATION: (loanId, phone, amount) => `${LOAN_BASE_URL}/lms/operation/payment/link/${phone}/${amount}/${loanId}`,
    CRM_CREDITLINE_PAYMENT_LINK_GENERATION: (applicationId, phone, amount) => `${BASE_URL}/cl/operation/payment/link/${phone}/${amount}/${applicationId}`,



    CRM_UNDERWRITING: UNDERWRITING_URL + '/ana/uw/listing',
    CRM_UNDERWRITING_DETAIL: (id) => UNDERWRITING_URL + `/ana/uw/data/${id}`,
    CRM_UNDERWRITING_UPDATE_UW: (id) => UNDERWRITING_URL + `/ana/uw/submit/${id}`,

    CRM_UNDERWRITING_BASIC_DETAIL: (id) => BASE_URL + "/cl/crm/" + id + "/underwritingDetail",

    CRM_ALL_LOAN_PAYMENT: (id) => LOAN_URL + `/payment/${id}/all`,


    CRM_WAIVER_LISTING: CREDIT_LINE_END_URL + "/loan/waiverList",

    CRM_WAIVER_GENERATION_LOAN: LOAN_BASE_URL + "/lms/crm/loan/waiverRequest ",
    CRM_WAIVER_GENERATION_CREDITLINE: BASE_URL + "/cl/crm/waiverRequest",

    CRM_WAIVER_DETAIL: (id) => `${CREDIT_LINE_END_URL}/loan/waiverRequest/${id}`,
    CRM_WAIVER_ACCEPT_REJECT_LOAN: (id) => `${LOAN_BASE_URL}/lms/crm/loan/waiver/${id}`,
    CRM_WAIVER_ACCEPT_REJECT_CREDITLINE: (id) => `${BASE_URL}/cl/crm/waiver/${id}`,

    CRM_PROMISE_TO_PAY_LOAN: LOAN_URL + "/loanPtp",
    CRM_PROMISE_TO_PAY_CREDITLINE: END_URL + "/clPtp",

    CRM_PTP_LOAN_LISTING: (id) => `${LOAN_URL}/loanPtp/${id}`,
    CRM_PTP_CREDITLINE_LISTING: (id) => `${END_URL}/clPtp/${id}`,

    CRM_DEDUCTION_PROCESS: LOAN_BASE_URL + '/lms/dc/processExcel',
    CRM_DEDUCTION_PROCESS_DEDUCTION: LOAN_BASE_URL + '/lms/dc/processDeduction',
    CRM_DEDUCTION_PROCESS_DEDUCTION_TRANSACTION: LOAN_BASE_URL + '/lms/dc/getDeductionTransaction',


    CRM_DEDUCTION_LISTING: LOAN_BASE_URL + "/lms/dc/getAllTransactions",
    CRM_DEDUCTION_SUMMARY: LOAN_BASE_URL + "/lms/dc/getDeductionSummary",

    CRM_DEDUCTION_REFRESH_TOKENID: LOAN_BASE_URL + "/lms/dc/refreshDeductionTransaction",

    CRM_COMPANY_LIST: BASE_URL + '/lms/application/marketPlaceListing',

    CRM_LOAN_NAME_REVIEW_LISTING: LOAN_BASE_URL + "/lms/crm/disbursal/review/list",
    CRM_LOAN_NAME_MATCH_FLAG: LOAN_BASE_URL + "/lms/crm/v2/name/match",
    CRM_LOAN_NAME_CKYC_MATCH_FLAG: LOAN_BASE_URL + "/lms/crm/v2/ckyc/name/match",
    CRM_LOAN_NAME_MATCH_RESET_BANK_DETAIL: LOAN_BASE_URL + "/lms/crm/bank/detail/reset",
    CRM_LOAN_NAME_MATCH_RESET_BANK_DETAIL_WITH_MANDATE: LOAN_BASE_URL + "/lms/crm/v2/bank/detail/reset",
    CRM_LOAN_NAME_MATCH_DETAIL: (id) => LOAN_BASE_URL + "/lms/crm/disbursal/review/" + id,

    CRM_USER_STAT: LOAN_BASE_URL + "/lms/crm/ptpAgentCollection",
    CRM_USER_CL_STAT: BASE_URL + "/cl/crm/ptpAgentCollection",

    CRM_LOAN_STATEMENT_PDF: (id) => LOAN_BASE_URL + "/lms/app/v1/loan/" + id + "/statement",
    CRM_CREDITLINE_STATEMENT_PDF: (id) => BASE_URL + "/cl/app/v1/credit-line/" + id + "/statement",

    CRM_RAISE_SETTLEMENT_LOAN: LOAN_URL + "/raise/settlement",
    CRM_PENDING_LOAN_SATTLEMENT_LISTING: LOAN_URL + "/settlement/listing",
    CRM_ACTION_SETTLEMENT_LOAN: LOAN_URL + "/settlement/action",
    CRM_LOAN_SETTLEMENT_SUMMARY: (id) => LOAN_URL + "/loan/summary/" + id,

    CRM_UW_COUNTS: LOAN_BASE_URL + "/ana/uw/count",

    CRM_LOAN_MARK_NI: LOAN_BASE_URL + "/lms/crm/mark/loan/not/interested",
    CRM_LOAN_MARK_CANCEL: (id) => LOAN_BASE_URL + `/lms/crm/cancel/loan?loanId=${id}`,

    CRM_ALL_CREDITLINE_PAYMENT: (id) => CREDIT_LINE_END_URL + `/clPayment/${id}/all`,

    CRM_DEDUCTION_DETAILS: (id) => LOAN_URL + `/loan/${id}/deductionDetail`,

    // campaign apis
    CRM_LOAN_CAMPAIGN_EVENT_LIST: LOAN_BASE_URL + "/lms/raven/listLoanEvents",
    CRM_LOAN_CAMPAIGN_EVENT_DETAILS: (evnt) => `${LOAN_BASE_URL}/lms/raven/eventTemplate?eventName=${evnt}`,
    CRM_LOAN_CAMPAIGN_PROCESS_EXCEL: (evnt) => `${LOAN_BASE_URL}/lms/crm/process/campaign/excel?eventName=${evnt}`,


    CRM_CL_CAMPAIGN_EVENT_LIST: BASE_URL + "/cl/raven/listAllCLEvents",
    CRM_CL_CAMPAIGN_EVENT_DETAILS: (evnt) => `${BASE_URL}/cl/raven/eventTemplate?eventName=${evnt}`,
    CRM_CREDITLINE_CAMPAIGN_PROCESS_EXCEL: (evnt) => `${BASE_URL}/cl/crm/process/campaign/excel?eventName=${evnt}`,


    CRM_DOWNLOAD_LOAN_PTP: LOAN_BASE_URL + "/lms/crm/ptp/report/excel",
    CRM_DOWNLOAD_CREDITLINE_PTP: BASE_URL + "/cl/crm/ptp/report/excel",

    CRM_LOAN_PTP_DETAIL: (ptpId) => `${LOAN_BASE_URL}/lms/crm/loanPtp/payments/${ptpId}`,
    CRM_CREDITLINE_PTP_DETAIL: (ptpId) => `${BASE_URL}/cl/crm/clPtp/payments/${ptpId}`,

    CRM_MANUAL_REVIEW_COUNT: `${LOAN_BASE_URL}/lms/crm/disbursal/review/count`,

    CRM_LOAN_PTP_LIST: LOAN_BASE_URL + "/lms/crm/loanPtp/list",
    CRM_CL_PTP_LIST: BASE_URL + "/cl/crm/clPtp/list",

    CRM_LOAN_PTP_AGENT_LIST: LOAN_BASE_URL + "/lms/crm/loanPtp/agentsEmailList",
    CRM_CL_PTP_AGENT_LIST: BASE_URL + "/cl/crm/clPtp/agentsEmailList",

    // cl and prod company list for graph
    CRM_COMPANY_LIST_FOR_GRAPH: LOAN_BASE_URL + "/lms/crm/marketPlaceListing",

    // loan dashboard apis
    CRM_GRAPH_COUNT_FOR_COMPANY: LOAN_BASE_URL + "/lms/crm/loanApplicationGraph",
    CRM_GRAPH_COUNT_FOR_COMPANY_BY_STATUS: LOAN_BASE_URL + "/lms/crm/loanApplicationByStatusGraph",
    CRM_GRAPH_COUNT_DPD_COUNT_PIE_CHART: LOAN_BASE_URL + "/lms/crm/dpd/count",
    CRM_COUNTS_FOR_LOAN: LOAN_BASE_URL + "/lms/crm/dashboardCards",
    CRM_LOAN_GRAPH_COUNT_FOR_PAYMENT_COLLECTION: LOAN_BASE_URL + "/lms/crm/paymentCollectionGraph",
    CRM_COUNTS_FOR_LOAN_OTHERS: LOAN_BASE_URL + "/lms/crm/v2/dashboardCards",

    CRM_LOAN_USERS_IN_KYC_STEPS: LOAN_BASE_URL + "/lms/crm/usersInKycSteps/count",
    CRM_LOAN_AMOUNT_DISBURSED_GRAPH: LOAN_BASE_URL + "/lms/crm/loanAmountDisbursedGraph",
    CRM_LOAN_PAYMENT_COLLECTION_GRAPH: LOAN_BASE_URL + "/lms/crm/paymentCollectionGraph",

    // https://prod.buenofinanceinc.com/lms/crm/usersInKycSteps/count,
    // https://prod.buenofinanceinc.com/lms/crm/loanAmountDisbursedGraph,
    // https://prod.buenofinanceinc.com/lms/crm/paymentCollectionGraph

    // cl dashboard apis
    CRM_CL_GRAPH_COUNT_FOR_COMPANY: CREDIT_LINE_END_URL + "/clApplicationGraph",
    CRM_CL_GRAPH_COUNT_FOR_COMPANY_BY_STATUS: CREDIT_LINE_END_URL + "/clApplicationByStatusGraph",
    CRM_CL_GRAPH_COUNT_DPD_COUNT_PIE_CHART: CREDIT_LINE_END_URL + "/dpd/count",
    CRM_CL_COUNTS_FOR_LOAN: CREDIT_LINE_END_URL + "/dashboardCards",
    CRM_CL_COUNT_EMANDATE_CARDS: CREDIT_LINE_END_URL + "/eMandateDetail/count",


    // amort emi download
    CRM_AMORT_EMI_DOWNLOAD: (id) => LOAN_BASE_URL + `/lms/app/v1/loan/${id}/schedule/emi`,


    //
    CRM_CRON_PAYMENT_PROCESSING: "https://api.buenofinanceinc.com/lms/cron/payment/processing",

    // payout file process
    CRM_MP_FILE_PROCESS: LOAN_BASE_URL + "/ana/uw/process/mp/request/file",
    CRM_MP_FILE_PROCESS_PROCESS_ID: (id) => LOAN_BASE_URL + "/ana/uw/process/status/" + id,
    CRM_MP_FILE_PREV_LIST: LOAN_BASE_URL + "/ana/uw/process/report",

    // reset kyc details
    CRM_LOAN_KYC_DETAIL_RESET: (id) => LOAN_BASE_URL + `/lms/crm/reset/${id}/kycDocument`,


    CRM_LOAN_AGREEMENT_PDF: (id) => LOAN_BASE_URL + "/lms/crm/loan/" + id + "/loanAgreement",

    SEARCH_WARNING_LETTER: (type, value) => LOAN_BASE_URL + `/lms/crm/warningLetter/search/${type}/${value}`,
    WARNING_LETTER_HISTORY: (type, value) => LOAN_BASE_URL + `/lms/crm/warningLetterHistory/${type}/${value}`,
    SEND_WARNING_LETTER_SMS: (type, value, warningLetterType) => LOAN_BASE_URL + `/lms/crm/sendWarningLetter/${type}/${value}?warningLetterType=${warningLetterType}`
}

const authorizeRequest = () => {
    const defaultHeaders = {};
    const AUTH_RESPONSE = getLocalStorage('auth');
    if (AUTH_RESPONSE) {
        return {
            'Authorization': `Bearer ${AUTH_RESPONSE.access_token}`
        }
    } else return defaultHeaders;

}

export const API = {

    GET: (url, body = {}, params = {}, cb) => {
        axios.get(url, {
            headers: authorizeRequest(),
            data: body,
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(error => {
            console.error("ERROR:", error);
            cb(null);
        })
    },
    POST: (url, body = {}, params = {}, cb) => {
        axios.post(url,
            body, {
            headers: authorizeRequest(),
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.log("ERROR: ", err);
            cb(null);
        })
    },
    PATCH: (url, body = {}, params = {}, cb) => {
        axios.patch(url,
            body, {
            headers: authorizeRequest(),
            params: params
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.log("ERROR: ", err);
            cb(null);
        })
    },
    DELETE: (url, body = {}, cb) => {
        axios.delete(url, {
            headers: authorizeRequest(),
            data: body
        }).then(res => {
            cb(res);
        }).catch(err => {
            console.error("ERROR: ", err);
            cb(null);
        })
    },
    GENERAL: (url, method = "get", body = {}, params = {}, headers = {}, cb) => {
        axios({
            url: url,
            method: method,
            data: body,
            params: params,
            headers: headers
        }).then(res => {
            cb(res.data);
        }).catch(err => {
            console.error("ERROR: ", err);
            cb(null);
        })
    },
    DOWNLOAD: (url, body = {}, params = {}, cb) => {
        axios.get(url, {
            responseType: 'blob',
            headers: authorizeRequest(),
            data: body,
            params: params
        })
            .then((response) => {
                cb(response)
            })
            .catch((err) => {
                console.log(err);
            });
    },
    DOWNLOADPOST: (url, body = {}, params = {}, cb) => {
        axios({
            url: url,
            method: 'POST',
            responseType: 'blob', // important
            headers: authorizeRequest(),
            data: body
        }).then((response) => {
            cb(response);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'template.xlsx');
            document.body.appendChild(link);
            link.click()
        }).catch((err) => {
            console.log(err);
        });
    },

}