import React from 'react';
import {
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails
} from "@material-ui/core";
import {  convertTimestamptoDateWithTime } from '../../utils/helper';
import SimpleTable from "../simpleTable";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const COLS = [
    { key: "transaction-id", align: "center", label: "TRANSACTION ID", value: "transactionId", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "transaction-date", align: "left", label: "TRANSACTION DATE", value: "transactionDate", type: "text", sort: false, fn: (v) => v? convertTimestamptoDateWithTime(v) : '-' },
    { key: "amount", align: "left", label: "AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v },
    // { key: "merchant-category-code", align: "left", label: "MERCHANT CATEGORY CODE", value: "merchantCategoryCode", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "merchant-name", align: "left", label: "MERCHANT NAME", value: "merchantName", type: "text", sort: false, fn: (v) => v },
    { key: "payment-id", align: "left", label: "PAYMENT ID", value: "paymentId", type: "text", sort: false, fn: (v) => v },
    { key: "category-type", align: "left", label: "CATEGORY TYPE", value: "categoryType", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "current-cycle", align: "left", label: "CURRENT CYCLE", value: "currentCycle", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "status", align: "left", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ? v : '-' },
    { key: "payment-type", align: "left", label: "PAYMENT TYPE", value: "paymentType", type: "text", sort: false, fn: (v) => v }
]

const CustomisedHeading = props => {
    const { month } = props;
    return (
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="credit-line-billed-statement-summary"
            style={styles.summaryTyography}
        >{month}
        </AccordionSummary>
    );
}

const CustomisedDetail = props => {
    const { transactions, loading } = props;
    return (
        <AccordionDetails style={styles.customisedDetail}>
            <SimpleTable
                cols={COLS}
                data={transactions}
                loading={loading}
            />
        </AccordionDetails>
    );
}

const UnbilledStatement = props => {
    const [expanded, setExpanded] = React.useState(false);
    const { transactions, loading } = props;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <div style={styles.root}>
            <Typography
                variant="h6"
                style={styles.typography}
            >Unbilled Statement
            </Typography>

            <Accordion
                square
                onChange={handleChange('unbilled-accordian')}
                expanded={expanded === 'unbilled-accordian'}
            >
                <CustomisedHeading month={"Transactions"} />
                <CustomisedDetail transactions={transactions} loading={loading} />
            </Accordion>
        </div>
    );
}

export default UnbilledStatement;

const styles = {
    root: {
        backgroundColor: '#fff',
        padding: 10
    },
    typography: {
        margin: "0px 0 15px"
    },
    summaryTyography: {
        fontSize: 18
    },
    summaryContainer: {

    },
    keyValueContainer: {

    },
    rowTitle: {
        fontWeight: '500',
    },
    rowValue: {

    },
    customisedDetail: {
        marginTop: 20
    }
}