import React from "react";
import {lms_role} from "../../common/constants";
import { Button } from "@material-ui/core";
import {
    Typography,
    Grid,
    TextField,
    MenuItem,
    Select,
    Checkbox,ListItemText
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { editLmsUser, getLmsUserDetails } from "../../service/api";
import { findByPathName } from "../../utils/helper";
import Alert from "../../components/alert";
import {REGEX} from "../../common/regex";

const role_active = [
    {key:"Yes", value:true},
    {key:"No", value:false},
]

class EditRole extends React.Component {
    constructor () {
        super();
        this.state = {
            hasError: false,
            errorType:"",
            errorMessage: "",
            name: "",
            email: "",
            phone: "",
            password: "",
            roleActive: false,
            ipBound: false,
            errors: {},
            multipleRole: ["ROLE_CE"],
            company:""
        }
    }

    componentDidMount(){
        const { location  } = this.props;
        const userId = findByPathName(location);
        this.setState({
            id:userId
        })
        this.callApi(userId);

    }


    callApi = (id) =>{
        getLmsUserDetails(id,(res)=>{
            if(res && res.success){
               const { name,email,phone,password,active,ipbound,roles,company}= res.response;
               this.setState({
                 name:name,
                 email:email,
                 phone:phone,
                 password:password,
                 roleActive:active,
                 ipBound:ipbound,
                 multipleRole:roles,
                 company:company?company:""
               })
            }else{
                setTimeout(() => {
                    const { history  } = this.props;
                    history.goBack();
                }, 1500);
            }
        })
    }

    handleBack = () => {
        const { history  } = this.props;
        history.goBack();
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;

        const { email_regex, phone_regex, name_regex, password_regex } = REGEX;

        const { name, email, phone, password ,company} = this.state;

        if (!name) {
            formIsValid = false;
            errorsTemp["name"] = "Cannot be empty";
        } else if (!name_regex.test(name)) {
            formIsValid = false;
            errorsTemp["name"] = "Only letters";
        }

        if (!company) {
            formIsValid = false;
            errorsTemp["email"] = "Cannot be empty";
        }

        if (!email) {
            formIsValid = false;
            errorsTemp["email"] = "Cannot be empty";
        } else if (!email_regex.test(email)) {
            formIsValid = false;
            errorsTemp["email"] = "Email not valid";
        }


        if (!password) {
            formIsValid = false;
            errorsTemp["password"] = "Cannot be empty";
        } else if (!password_regex.test(password)) {
            formIsValid = false;
            errorsTemp["password"] = "Minimum eight characters, at least one letter, one number and one special character";
        }


        if (!phone) {
            formIsValid = false;
            errorsTemp["phone"] = "Cannot be empty";
        } else if (!phone_regex.test(phone)) {
            formIsValid = false;
            errorsTemp["phone"] = "Please enter valid phone number";
        }

        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item.key} value={item.value}>
                {item.key}</MenuItem>
        })
    }


    handleField = (event) => {
        const { name,value}= event.target;
        this.setState({
            [name]: value
        })
    }

    handleChange = (event) => {
        const { value}= event.target;
        this.setState({
            multipleRole:typeof value === 'string' ? value.split(',') : value,
        })
      };

      addRole = e => {
        e.preventDefault();
        const { name, phone, password, roleActive, ipBound, id ,company} = this.state;

        const createUserDetail = {
            "name": name,
            // "email": email,
            "phone": phone,
            "password": password,
            "active": roleActive,
            "ipbound": ipBound,
            "company":company
            // "roles": multipleRole
        }
        if (this.handleValidation()) {
            editLmsUser(id,createUserDetail, (res)=>{
               if(res && res.success){
                this.setState({
                    hasError:true,
                    errorMessage:"LMS user edited succesfully",
                    errorType:"success"
                })
                setTimeout(() => { this.handleBack() }, 2000);
               }else{
                this.setState({
                    hasError:true,
                    errorMessage:"Error in editing  LMS user",
                    errorType:"error"
                })
               }
            })
            this.setToDefault();
        } else {
            console.log("Form has errors." + JSON.stringify(this.state.errors));
        }
    }

    setToDefault= () => {
        this.setState({
            name:"",
            email:"",
            phone:"",
            password:"",
            roleActive:false,
            ipBound:false,
            multipleRole:["ROLE_CE"]
        })
    }

    render () {
        const { name , email, phone, password,roleActive, ipBound,errorMessage,company,hasError,errors,multipleRole,errorType} = this.state;
        return (
            <>
        <div style={styles.rootContainer}>
            <div style={styles.backBar}>
                <Button
                    variant="contained"
                    color="default"
                    startIcon={<ArrowBackIcon  />}
                    onClick={this.handleBack}
                    style={styles.backIcon}
                >
                    Back
                </Button>
            </div>
            <div >
                <div style={{
                     margin:"-40px 0px 20px 650px",
                     fontSize:25
                }}>{"EDIT CRM USER"}</div>
                <form onSubmit={this.addRole}  style={styles.formContainer}>

                    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>

                        <Grid container item xs={12} spacing={1}>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel }>Name:</Typography>
                                <TextField
                                        id={name}
                                        type="text"
                                        name="name"
                                        required
                                        value={name}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={errors["name"]}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Company:</Typography>
                                <TextField
                                        id={company}
                                        type="text"
                                        name="company"
                                        required
                                        value={company}
                                        onChange={this.handleField}
                                        style={styles.textField}

                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={errors["company"]}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Email:</Typography>
                                <TextField
                                        id={email}
                                        type="text"
                                        name="email"
                                        disabled={true}
                                        required
                                        value={email}
                                        onChange={this.handleField}
                                        style={styles.textField}

                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={errors["email"]}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Password:</Typography>
                                <TextField
                                        id={password}
                                        type="password"
                                        name="password"
                                        required
                                        value={password}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={errors["password"]}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Phone:</Typography>
                                <TextField
                                        id={phone}
                                        type="text"
                                        name="phone"
                                        required
                                        value={phone}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        helperText={errors["phone"]}
                                        variant="outlined"
                                        FormHelperTextProps={{
                                            style:{
                                                color:"red",
                                                fontSize:15,
                                                marginLeft:"-1px"
                                            }
                                          }}
                                    >
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Active/Inactive:</Typography>
                                <TextField
                                        id={roleActive}
                                        type="select"
                                        name="roleActive"
                                        required
                                        value={roleActive}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        select={true}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    >
                                        {this.loadOptions(role_active)}
                                </TextField>
                           </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Role Assign: </Typography>
                                    <Select
                                        multiple
                                        disabled={true}
                                        variant="outlined"
                                        style={styles.textField}
                                        value={multipleRole}
                                        onChange={this.handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                    >
                                        {lms_role.map((item) => (
                                            <MenuItem key={item.key} value={item.value} style={{ height: 40 }}>
                                                <Checkbox color="success" checked={multipleRole.indexOf(item.value) > -1} />
                                                <ListItemText primary={item.key} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                            </Grid>
                           <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>IP Bound:</Typography>
                                <TextField
                                        id={ipBound}
                                        type="select"
                                        name="ipBound"
                                        required
                                        value={ipBound}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        select={true}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        variant="outlined"
                                    >
                                        {this.loadOptions(role_active)}
                                </TextField>
                           </Grid>
                        </Grid>
                    </Grid>
                    <div style={styles.btnContainer}>
                            {/* <Button
                                variant="outlined"
                                disableElevation
                                style={styles.clearButton}
                                onClick={this.setToDefault}
                                >CLEAR</Button> */}
                            <Button
                                variant="outlined"
                                type="submit"
                                disableElevation
                                style={styles.addButton}
                                onClick={this.addRole}
                                size="small"
                            >SUBMIT</Button>
                        </div>
                </form>
            </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                    duration="5000"
                />
    </div>
            </>
        );
    }
}
const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    formContainer:{
        margin:"50px 0 0 0",
    },
    backBar: {

    },
    backIcon: {
        // width:50,
        backgroundColor:"rgb(236,246,255)",
        color:"black",
        borderRadius:5,
    },
    backButton:{
        margin:"0px 0px 0px 15px",
        padding:5
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,

    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    btnContainer: {
        background: "#fff",
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    },
    header: {

    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    addButton: {
        width: 120,
        height: 50,
        marginRight: 50,
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    clearButton: {
        width: 120,
        height: 50,
        marginRight: 50,
        "&:hover": {
            background: "rgb(232,244,255)"
        }
    }
}

export default EditRole;