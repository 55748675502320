import React from "react";
import {
    Button,
    TextField,
    Grid,
    MenuItem,
    Typography
} from "@material-ui/core";
import { BOUND, REASON_CODES } from "./../../common/constants";

const FIELD = [
    { key: "user-phone", label: "PHONE", name: "phone", type: "text", option: [] },
    { key: "user-amount", label: "AMOUNT", name: "amount", type: "number", option: [] },
    { key: "user-comment", label: "COMMENT", name: "comment", type: "text", option: [] },
    { key: "scheduled-time", label: "SCHEDULED TIME", name: "scheduledTime", type: "datetime-local", option: [] },
    { key: "reason-code", label: "REASON", name: "reason", type: "select", option: REASON_CODES },
    { key: "user-bound", label: "BOUND", name: "relation", type: "select", option: BOUND }
]

export default function AddCallLog(props) {
    const { handleClose } = props;
    const [field, setField] = React.useState({});

    const handleChange = evt => {
        const { name, value } = evt.target;
        setField({ ...field, [name]: value })
    }
    return (
        <div style={styles.rootContainer}>
            <Grid
                container
                style={styles.innerContainer}
                justify="center"
                alignItems="center"
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    style={styles.typography}
                >ADD CALL LOG</Typography>
                {FIELD.map(item => {
                    const { key, label, name, type, option } = item;
                    const isSelect = type === "select";
                    return <div style={styles.textFieldContainer}>
                        <TextField
                            id={key}
                            name={name}
                            label={label}
                            placeholder={label}
                            type={type}
                            select={isSelect}
                            required
                            defaultValue={isSelect ? option[0].value : null}
                            style={styles.textField}
                            variant="outlined"
                            size="small"
                            onChange={handleChange}
                            InputLabelProps={{
                                shrink: true
                            }}
                        >{isSelect ?
                            option.map(opt => <MenuItem key={opt.value} value={opt.value}>
                                {opt.label}</MenuItem>
                            ) : null}
                        </TextField>
                    </div>
                })}
                <div style={styles.btnContainer}>
                    <Button
                        style={styles.closeBtn}
                        variant="outlined"
                        onClick={handleClose}
                    >CLOSE</Button>
                    <Button
                        style={styles.saveBtn}
                        variant="outlined"
                    >ADD</Button>
                </div>
            </Grid>
        </div>
    );
}

const styles = {
    rootContainer: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    innerContainer: {
        width: 390,
        background: "#fff",
        borderRadius: 6,
        padding: 30
    },
    typography: {
        fontFamily: "Poppins"
    },
    textFieldContainer: {

    },
    textField: {
        width: 300,
        margin: "20px 0"
    },
    btnContainer: {
        margin: "15px 0px",
        display: "inherit",
        width: 300,
        justifyContent: "space-between"
    },
    closeBtn: {
        width: 100
    },
    saveBtn: {
        width: 100,
        background: "#1B2D93",
        color: "#fff"
    }
}