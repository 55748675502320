import React from 'react';
import { Colors, Fonts } from '../../styles';
import { Button, Select, Toast } from '../../components';
import AccordianView from './accordian';
import { HistoryWrapper, HistoryRow } from './warning-letter-history';
import { TabTextGroup } from './tab-text-viewer';
import { LinkWrapper, LinkRow } from './warning-letter-link';
import { CommonIcons } from '../../assets';
import { Notification } from './components';
import NotFound from './not-found';
import { Pattern } from '../../utils';
import { WarningLetterService } from '../../service';
import {
	SearchDataDefaultResponse,
} from './data';

const Search = ({
	setSearchData,
	setHistoryData,
	setNotFound,
	setError
}) => {
	const [loading, setLoading] = React.useState(false);
	const [text, setText] = React.useState('');
	const [type, setType] = React.useState('loanId');

	const handleChange = (evt) => {
		setType(evt.target.value)
	};

	const searchWarningLetter = async () => {
		if (Pattern.OnlyDigitAllowed(text)) {
			setLoading(true);
			const p1 = WarningLetterService.searchWarningLetter(type, text);
			const p2 = WarningLetterService.getWarningLetterHistory(type, text);
			Promise.all([p1, p2])
				.then((result) => {
					const [data1, data2] = result;
					if (data1.success && data2.success) {
						setNotFound(false);
						setSearchData(data1.response);
						setHistoryData(data2.response);
					} else {
						setNotFound(true);
					}
					setLoading(false);
				})
				.catch(error => {
					console.log(error);
					setError(true);
				});
		} else {
			setNotFound(true);
		}
	};

	return (
		<div>
			<h4 style={styles.searchHeading}>Search Warning Letter</h4>
			<div style={styles.searchContainer}>
				<Select
					defaultValue="loanId"
					id="select-warning-letter-type"
					options={[
						{ name: 'Loan Id', value: 'loanId' },
						{ name: 'Phone', value: 'phone' },
						{ name: 'Lender Loan Id', value: 'lenderLoanId' }
					]}
					value={type}
					handleChange={handleChange}
				/>
				<img src={CommonIcons.SearchIcon} style={styles.searchIcon} />
				<input
					type="text"
					id="search-warning-letter-input-id"
					style={styles.inputSearch}
					value={text}
					placeholder="Search by Loan ID/ Phone No."
					onChange={(evt) =>
						setText(evt.target.value)
					}
				/>
			</div>
			<Button
				text="Search"
				btnStyle={styles.searchButton}
				onClick={searchWarningLetter}
				disabled={text.length === 0}
				loading={loading}
			/>
		</div>
	);
};

const WarningLetter = () => {
	const [notification, setNotification] = React.useState(false);
	const [notFound, setNotFound] = React.useState(false);
	const [error, setError] = React.useState(false);
	const [notificationMessage, setNotificationMessage] = React.useState('');
	const [historyData, setHistoryData] = React.useState([]);
	const [searchData, setSearchData] = React.useState(SearchDataDefaultResponse);
	const showAccordian = !notFound && searchData['currentDate'];

	const getWarningLetterHistory = async () => {
		const searchElement = document.getElementById(
			'search-warning-letter-input-id'
		);
		const selectElement = document.getElementById(
			'select-warning-letter-type'
		).nextElementSibling;
		if (searchElement && selectElement) {
			// loanId/phoneId/ndxId = ele.value
			const result = await WarningLetterService.getWarningLetterHistory(
				selectElement.value,
				searchElement.value
			);
			if (result.success) {
				setHistoryData(result.response.warningLetterHistory);
			} else {
				setError(true);
			}
		}
	};

	const sendWarningLetterSMS = async (type) => {
		const searchElement = document.getElementById(
			'search-warning-letter-input-id'
		);
		const selectelement = document.getElementById(
			'select-warning-letter-type'
		)?.nextElementSibling;
		const warningLetterType = `WARNING_LETTER_${type}`;
		if (searchElement && selectelement) {
			try {
				const result = await WarningLetterService.sendWarningLetterSMS(
					selectelement.value,
					searchElement.value,
					warningLetterType);
				if (result.success) {
					showToasNotif('The SMS has been sent successfully');
					getWarningLetterHistory();
				} else {
					setError(true);
				}
			} catch (error) {
				console.log('warning letter service', error);
				setError(true);
			}
		} else {
			console.log('no element with id found', searchElement);
		}
	};

	const sendWarningLetterWhatsApp = () => { };

	const copyElementLink = (type) => {
		const data = searchData[`warningLetter${type}Url`] ?? null;
		if (data) {
			navigator.clipboard.writeText(data);
			showToasNotif('Copied to the clipboard');
		}
	};

	const showToasNotif = (message) => {
		setNotificationMessage(message);
		setNotification(true);

		setTimeout(() => {
			setNotification(false);
		}, 1500);
	};

	return (
		<div style={styles.root}>
			<h2 style={styles.heading}>Warning Letter</h2>
			<Search
				setSearchData={(data) =>
					setSearchData(data)
				}
				setHistoryData={(data) =>
					setHistoryData(data.warningLetterHistory)
				}
				setNotFound={(data) => setNotFound(data)}
				setError={() => setError(true)}
			/>
			{showAccordian && (
				<TabTextGroup
					dpd={searchData.dpd}
					currentDate={searchData.currentDate}
				/>
			)}
			{showAccordian && (
				<AccordianView
					title={'Warning Letter Links'}
					style={{ marginTop: '16px' }}
					defaultExpanded={true}>
					<LinkWrapper>
						{[1, 2, 3].map(item => (
							<LinkRow
								key={`warning-letter-key-${item}`}
								idx={item}
								name={`Warning Letter Link ${item}`}
								link={
									searchData[`warningLetter${item}Url`] ??
									null
								}
								sendSMS={() => sendWarningLetterSMS(item)}
								sendWA={sendWarningLetterWhatsApp}
								copyLink={() => copyElementLink(item)}
							/>
						))}
					</LinkWrapper>
				</AccordianView>
			)}
			{showAccordian && (
				<AccordianView
					title={'Warning Letter Sent - History'}
					style={{ marginTop: '16px' }}
					defaultExpanded={false}>
					<HistoryWrapper>
						{historyData.map(item => (
							<HistoryRow
								key={`${item.date}`}
								date={item.date}
								type={item.warningLetterType}
								triggeredBy={item.triggeredBy}
								mode={item.mode}
								status={item.status}
							/>
						))}
					</HistoryWrapper>
				</AccordianView>
			)}
			<Notification
				visible={notification}
				message={notificationMessage}
				onPress={() => setNotification(false)}
			/>
			<Toast
				isOpen={error}
				message={null}
				onClose={() => setError(false)}
			/>
			{notFound && <NotFound />}
		</div>
	);
};

const styles = {
	root: {
		background: Colors.primaryColor06,
		marginBottom: '100px',
		overflowY: 'scroll',
	},
	heading: {
		fontSize: '25px',
		fontWeight: Fonts.weight.regular,
		textAlign: 'center'
	},
	searchContainer: {
		width: 'min-content',
		margin: '0 auto',
		display: 'flex',
		position: 'relative'
	},
	searchIcon: {
		marginTop: '15px',
		height: '24px',
		width: '24px',
		position: 'absolute',
		marginLeft: '30%'
	},
	inputSearch: {
		border: `1px solid ${Colors.typographyColor05}`,
		width: '442px',
		borderRadius: '4px',
		backgroundColor: Colors.white,
		padding: '16px 0px 16px 52px',
		fontSize: Fonts.size.medium,
		marginLeft: '20px'
	},
	searchHeading: {
		fontSize: '18px',
		textAlign: 'center',
		fontWeight: '400'
	},
	searchButton: {
		height: '48px',
		minWidth: '118px',
		fontSize: Fonts.size.small,
		color: Colors.white,
		backgroundColor: Colors.primaryColor01,
		display: 'block',
		margin: 'auto',
		marginTop: '40px',
		textTransform: 'capitalize'
	}
};

export default WarningLetter;
