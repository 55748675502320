import React from "react";
import {
    Typography,
    IconButton,
    Grid,
    TextField,
    MenuItem,
    Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import {REGEX} from '../../common/regex';
import { getPromiseToPayCreditLineListing, postPromiseToPayCreditLine } from "../../service/api";
import Alert from "./../../components/alert";
import { convertTimestamptoDateWithTime } from "../../utils/helper";
import ContentLoader from "../../components/contentLoader";

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';
import CustomModal from "../modal";
import CreditlinePtpDetails from "./clPtpDetails"
import FeedbackIcon from '@material-ui/icons/Feedback';

const COLS = [
    { key: "cl-ptp-id", align: "left", label: "PTP ID", value: "id", type: "text", sort: false, fn: (v) => v?? '-' },
    { key: "cl-ptp-created-date", align: "left", label: "CREATED DATE", value: "createdDate", type: "text", sort: false, fn: (v) => convertTimestamptoDateWithTime(v)},
    { key: "cl-ptp-agent", align: "left", label: "AGENT", value: "agent", type: "text", sort: false, fn: (v) => v ?? '-' },
    { key: "cl-ptp-promise-amount", align: "left", label: "PROMISE AMOUNT", value: "promiseAmount", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-promise-date", align: "left", label: "PROMISE DATE", value: "promiseDate", type: "text", sort: false, fn: (v) => v ?? '-'},
    // { key: "cl-ptp-loan-id", align: "left", label: "APPLICATION ID", value: "applicationId", type: "text", sort: false, fn: (v) => v ?? '-'},
    // { key: "cl-ptp-user-id", align: "left", label: "USER ID", value: "userId", type: "text", sort: false, fn: (v) => v?? '-' },
    { key: "cl-ptp-statement-id", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-order-id", align: "left", label: "ORDER ID", value: "orderId", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-status", align: "left", label: "STATUS", value: "status", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-dpd-count", align: "left", label: "DPD", value: "dpdCount", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-comment", align: "left", label: "COMMENT", value: "comment", type: "commentType", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-amount-Paid", align: "left", label: "AMOUNT PAID", value: "amountPaid", type: "text", sort: false, fn: (v) => v ?? '-'},
    { key: "cl-ptp-view-detail", align: "left", label: "PTP DETAIL", value: "amountPaid", type: "detailView", sort: false, fn: (v) => v ?? '-'},


]

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 30,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

class PromiseToPayCreditLine extends React.Component {
    constructor () {
        super();
        this.state = {
            hasError:"",
            errorType:"",
            errorMessage:"",
            promiseAmount:"",
            promiseDate:"",
            promiseComment:"",
            phoneNumber:"",
            waiverCommitted:"0",
            errors:{},
            data:[],
            minDate:"",
            maxDate:"",
            isOpen:false,
            ptpId:""
        }
    }

    componentDidMount(){
        const {id}=this.props;
        this.callApi(id);
        this.dateRange();
    }

    callApi =(loanId)=>{
       getPromiseToPayCreditLineListing(loanId,(res)=>{
        if(res && res.success){
            this.setState({
                data:res.response,
                loading:false
            })
       }else{
            this.setState({
                data:[],
                loading:false,
                // hasError:true,
                // errorMessage:res?res.message:"",
                // errorType:"error"
            })
       }
       })
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    loadOptions = opts => {
        console.log(opts[0])
        return opts.map(item => {
            return <MenuItem value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        console.log(name, value)
        this.setState({
            [name]: value
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { amount_regex,phone_regex  } = REGEX;
        const { promiseAmount,promiseDate,promiseComment,phoneNumber,maxDate,minDate,waiverCommitted} = this.state;

        if (!promiseAmount) {
            formIsValid = false;
            errorsTemp["promiseAmount"] = "Cannot be empty";
        } else if (!amount_regex.test(promiseAmount)) {
            formIsValid = false;
            errorsTemp["promiseAmount"] = "Please enter valid amount";
        }

        if (!promiseDate) {
            formIsValid = false;
            errorsTemp["promiseDate"] = "Please mention promise date";
        }

        if (!waiverCommitted) {
            formIsValid = false;
            errorsTemp["waiverCommitted"] = "Please mention committed amount";
        }

        if (maxDate<promiseDate || minDate>promiseDate) {
            formIsValid = false;
            errorsTemp["promiseDate"] = "Please enter correct date";
        }

        // if (!phoneNumber) {
        //     formIsValid = false;
        //     errorsTemp["phoneNumber"] = "Cannot be empty";
        // }else
        if (!phone_regex.test(phoneNumber)&& phoneNumber) {
            formIsValid = false;
            errorsTemp["phoneNumber"] = "Please enter valid phone number";
        }

        if (!promiseComment) {
            formIsValid = false;
            errorsTemp["promiseComment"] = "Cannot be empty";
        }


        this.setState({ errors: errorsTemp });
        return formIsValid;
    }


    promiseToPay= e => {
        const { handleClose } = this.props;
        const { promiseAmount,promiseDate,phoneNumber,promiseComment,waiverCommitted}= this.state;
        const { id}=this.props;
        let finalDetails = {
                "promiseAmount":promiseAmount,
                "applicationId":id,
                "promiseDate":promiseDate,
                "phone":phoneNumber,
                "comment":promiseComment,
                "waiverCommitted":waiverCommitted
        }
        e.preventDefault();
        if (this.handleValidation()) {
            postPromiseToPayCreditLine(finalDetails,(res)=>{
                if(res&&res.success){
                    this.setState({
                        hasError:true,
                        errorMessage:res.message,
                        errorType:"success"
                    })
                    console.log(finalDetails)
                    setTimeout(() => { handleClose() }, 5000);
                    this.setToDefault()
                }else{
                    this.setState({
                        hasError:true,
                        errorMessage:res?res.message:"",
                        errorType:"error"
                    })
                }
            })
        } else {
            this.setState({
            hasError:true,
            errorMessage:"Please fill the details correctly ",
            errorType:"error"
            })
         }
    }

    setToDefault=()=>{
        this.setState({
            waiveOffAmount:"",
            promiseAmount:"",
            typeOfFee:"",
            reason:"",
            errors:{}
        })
    }

    giveDateFormate = (newDate)=>{
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        return `${year}-${month<10?`0${month}`:`${month}`}-${date<10?`0${date}`:`${date}`}`;
    }

    dateRange = ()=>{
        let newDate = new Date()
        let minDate = this.giveDateFormate(newDate);
        newDate.setDate(newDate.getDate() + 2);
        let maxDate = this.giveDateFormate(newDate)
        this.setState({
            minDate:minDate,
            maxDate:maxDate,
            promiseDate:minDate
        })

    }


    openModal = (data)=>{
        const {id}= data;
        this.setState({
            isOpen:true,
            ptpId:id
        })
    }

    handleClose = ()=>{
        this.setState({
            isOpen:false
        })
    }

    handleCols=(data,column,id)=>{
        const { type} = column;
        switch (type) {
            case "detailView":
                return <IconButton
                onClick={() => this.openModal(data)}
                ><RemoveRedEyeIcon/></IconButton>
            case "commentType":
                return <div title={column.fn(data[column.value])}><FeedbackIcon/></div>
            default:
                return column.fn(data[column.value])
        }
    }

    renderTable = (cols,data,loading)=>{
        const {classes} = this.props;
        return (<>
        <TableContainer style={{
            height:`calc(100vh - ${550}px)`
        }} >
        <Table stickyHeader style={styles.table} aria-label="simple-table">
            <TableHead className={classes.tableHead}>
                <TableRow >
                    {cols.map(it => <TableCell key={it.key}>
                        {it.label}
                    </TableCell>)}
                </TableRow>
            </TableHead>
            <TableBody style={styles.tableBody} >
                { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                    data.map((it,i )=> <StyledTableRow  style={styles.tableRow}>
                        {cols.map(col => <TableCell style={styles.bodyTableCell}>
                            {this.handleCols(it,col,i)}
                            </TableCell>
                        )}
                        </StyledTableRow>
                    )
                }
            </TableBody>
        </Table>
    </TableContainer>
        </>)
    }

    loadModuleFilter = () => {
        const comps = [];
        const {  promiseAmount,promiseDate,errors,promiseComment,phoneNumber,waiverCommitted}= this.state;
        const {id} = this.props;
        const dispatchfields = [
             { id:"promise-loan-id",type:"number",name:"applicationId",label:"APPLICATION ID",value:id,isSelect:false,option:[],isDisable:true,isMultiline:false},
             { id:"promiseAmount",type:"number",name:"promiseAmount",label:"PROMISE AMOUNT",value:promiseAmount,isSelect:false,option:[],isDisable:false,isMultiline:false},
             { id:"phoneNumber",type:"number",name:"phoneNumber",label:"PHONE NUMBER",value:phoneNumber,isSelect:false,option:[],isDisable:false,isMultiline:false},
             { id:"promiseComment",type:"text",name:"promiseComment",label:"COMMENT",value:promiseComment,isSelect:false,option:[],isDisable:false,isMultiline:false},
             { id:"promiseDate",type:"date",name:"promiseDate",label:"PROMISE DATE",value:promiseDate,isSelect:false,option:[],isDisable:false,isMultiline:false},
             { id:"waiverCommitted",type:"number",name:"waiverCommitted",label:"WAIVER COMMITTED",value:waiverCommitted,isSelect:false,option:[],isDisable:false,isMultiline:false},
        ]
        for (let obj of dispatchfields) {
                const { id,type,label ,value,multiline,isSelect,isDisable ,option} = obj;
                let TextFieldProps = {
                    id: id,
                    key: id,
                    disable:isDisable,
                    multiline:multiline,
                    name: id,
                    select: isSelect,
                    label: label,
                    type: type,
                    value: value,
                    defaultValue: isSelect ? option[0]: "",
                    isSelect: isSelect,
                    option: option,
                    helperText:errors[id]
                }
                comps.push(TextFieldProps);
        } return comps;
    }

    render () {
        const { classes, handleClose } = this.props;
        const { hasError, errorMessage, errorType ,loading,data,minDate,maxDate,isOpen,ptpId} = this.state;
        const comps = this.loadModuleFilter();
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >PROMISE TO PAY FOR CREDITLINE</Typography>
                    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            {comps.map(comp => {
                                const { id, name, type, isSelect, label, option, value ,helperText,isDisable} = comp;
                                return  <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>{label}</Typography>
                                <TextField
                                    id={id}
                                    key={id}
                                    name={name}
                                    type={type}
                                    select={isSelect}
                                    value={value}
                                    defaultValue={isSelect ? option[0] : null}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    disable={isDisable}
                                    helperText={helperText}
                                    InputProps={{inputProps: { min: minDate, max: maxDate} }}
                                    onChange={(evt) => this.handleField(evt, {
                                        label: label
                                    })}
                                    SelectProps={{
                                        MenuProps: {
                                            MenuListProps: {
                                                style: {
                                                    maxHeight: 210
                                                }
                                            }
                                        }
                                    }}

                                    FormHelperTextProps={{
                                        style: {
                                            color: "red",
                                            fontSize: 12,
                                            marginLeft: "-1px",

                                    }
                                    }}
                                    style={styles.textField}
                                    size={'small'}
                                    variant="outlined"
                            >{isSelect ? this.loadOptions(option) : null}</TextField>
                            </Grid>
                            })}

                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    variant="contained"
                                    disableElevation
                                    disableFocusRipple
                                    style={styles.addButton}
                                    onClick={this.promiseToPay}
                                >CREATE</Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/* <SimpleTable
                        cols={COLS}
                        data={data}
                        loading={loading}
                        customeHeight={500}
                    /> */}

                    {this.renderTable(COLS,data,loading)}
                    <CustomModal
                        isOpen={isOpen}
                        handleClose={this.handleClose}
                    >
                        <CreditlinePtpDetails
                          ptpId={ptpId}
                          handleClose={this.handleClose}
                        />

                    </CustomModal>
                </div>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                    duration="5000"
                />
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 5px 25px"
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    addButton: {
        width: 120,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    tableHead:{
        "& .MuiTableCell-head": {
            color: "black",
            fontWeight:"bold",
            backgroundColor: "rgb(218,233,188)"
        },
    }
}
export default withStyles(styles)(PromiseToPayCreditLine);
