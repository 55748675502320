import React from 'react';
import Fade from '@material-ui/core/Fade';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import {
    Typography,
    Grid,
    TextField,
    Button,
    MenuItem
} from "@material-ui/core";
import { clCampaignProcessExcel, getCampaignEventDetails, getCampaignEventList, getCLCampaignEventDetails, getCLCampaignEventList, loanCampaignProcessExcel } from '../../service/api';
import { getLocalStorage } from '../../utils/localStorage';
import Alert from '../../components/alert';

function SimpleFade(props) {
  const {clEventList,loanEventList} =props;
  const [checked, setChecked] = React.useState(false);

  const [value, setValue] = React.useState('true');

  const handleChange = (event) => {
    setValue(event.target.value);
    setChecked((prev) => !prev);
  };

  return (
    <div>

      <RadioGroup row aria-label="type" name="campaignType" value={value} onChange={handleChange}>
        <FormControlLabel value="true" control={<Radio style={{color:"green"}}/>} label="Loan Campaign" />
      </RadioGroup>

      <div >
        <Fade in={!checked} style={{height:0}}>
          <div ><LoanCampaign eventList={loanEventList}/></div>
        </Fade>
        <Fade in={checked}>
          <div><CreditLineCampaign eventList={clEventList}/></div>
        </Fade>
      </div>
    </div>
  );
}

class CreditLineCampaign extends React.Component{
    constructor(props){
        super(props);
        this.state={
            eventName:"LOAN_APPLICATION_COMPLETED",
            errors:{},
            eventType:"smsType",
            paramCountPushNotification: null,
            paramCountSms: null,
            paramCountVoice: null,
            paramCountWhatsapp: null,
            pushNotificationTemplate: null,
            smsTemplate: null,
            voiceTemplate: null,
            whatsappTemplate:null,
            selectedFile:null,
            showOptions:false,
            hasError:false,
            errorMessage:"",
            errorType:""

        }
    }



    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }
    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    getEventDetails =()=>{
        const { eventName}=this.state;
        getCLCampaignEventDetails(eventName,(res)=>{
           if(res){
            const {  paramCountPushNotification,  paramCountSms, paramCountVoice, paramCountWhatsapp,  pushNotificationTemplate,
                smsTemplate,  voiceTemplate, whatsappTemplate}=res;
               this.setState({
                showOptions:true,
                paramCountPushNotification:paramCountPushNotification,
                paramCountSms: paramCountSms,
                paramCountVoice: paramCountVoice,
                paramCountWhatsapp:paramCountWhatsapp,
                pushNotificationTemplate: pushNotificationTemplate,
                smsTemplate: smsTemplate,
                voiceTemplate:voiceTemplate,
                whatsappTemplate:whatsappTemplate
               })
           }else{
               this.setState({
                   showOptions:false
               })
           }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
      };

    renderDetails = (type)=>{
      const { pushNotificationTemplate,smsTemplate, voiceTemplate, whatsappTemplate}=this.state;
      switch(type){
        case "smsType" :
            return <>
                {smsTemplate??"NA"}
            </>
        case "whatsAppType" :
            return <>
                   {whatsappTemplate??"NA"}
            </>
        case "pushNotificationType" :
            return <>
                   {pushNotificationTemplate??"NA"}
            </>
        case "voiceType" :
            return<>
                   {voiceTemplate??"NA"}
            </>
        default :
            return null;
      }
    }


    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=> this.handleValidation());
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { selectedFile} = this.state;

        if (!selectedFile) {
            formIsValid = false;
            errorsTemp["selectedFile"] = "Please select file";
        }
        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    sendEventExcel = e =>{

        e.preventDefault();
        const {selectedFile,eventName} = this.state;
        let formData = new FormData();
        formData.append("file", selectedFile);
        const AUTH_RESPONSE = getLocalStorage('auth');
        const headers = {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${AUTH_RESPONSE.access_token}`
        }

        if(this.handleValidation()){
             clCampaignProcessExcel(eventName,formData, headers,async (res)=>{
                const json = await res;
                if(json && json.success){
                   this.setState({
                       hasError:true,
                       errorMessage:json.message,
                       errorType:"success",
                       showOptions:false
                   })
                }else{
                    this.setState({
                        hasError:true,
                        errorMessage:json.message,
                        errorType:"error",
                        showOptions:false
                    })
                }
            })
        } else {
            this.setState({
            hasError:true,
            errorMessage:"Please fill the details correctly ",
            errorType:"error"
            })
         }
    }



    render(){
        const {eventName ,eventType,errors,showOptions,hasError,errorMessage,errorType}= this.state;
        const { eventList}= this.props;
        return(<>
        <div style={styles.rootContainerLoan}>
            <div style={styles.rootContainer}>
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Event Name </Typography>
                                <TextField
                                    id={eventName}
                                    type="select"
                                    name="eventName"
                                    required
                                    value={eventName}
                                    onChange={this.handleField}
                                    style={styles.textField}
                                    select={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                    {this.loadOptions(eventList??[])}
                                </TextField>
                        </Grid>
                        <Grid item xs={3} style={styles.fieldWrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                disableElevation
                                disableFocusRipple
                                style={styles.addButton}
                                onClick={this.getEventDetails}

                            >GET EVENT DETAILS</Button>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
            {showOptions?<>
            <div style={{padding:10}}>
            <RadioGroup row aria-label="type" name="eventType" value={eventType} onChange={this.handleChange}>
                <FormControlLabel value="smsType"control={<Radio style={{color:"green"}} />} label="SMS" />
                <FormControlLabel value="whatsAppType" control={<Radio style={{color:"green"}}/>} label="Whatsapp" />
                <FormControlLabel value="pushNotificationType" control={<Radio style={{color:"green"}} />} label="Push Notification" />
                <FormControlLabel value="voiceType" control={<Radio style={{color:"green"}} />} label="Voice SMS" />
            </RadioGroup>
               <div style={{border:"1px solid",borderColor:"brown",padding:10 }}>
               {this.renderDetails(eventType)}
               </div>
            </div>
            <div style={styles.rootContainer}>
            <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Upload File </Typography>
                        <Button variant="contained" style={{height:40,width:250}} component="label" >
                        <input
                            type="file"
                            //hidden
                            onChange={this.onFileChange}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </Button>
                        {errors["selectedFile"]?
                        <div style={{margin:2,color:"red"}}>{errors["selectedFile"]}</div>
                        :null}
                    </Grid>
                    <Grid item xs={3} style={styles.fieldWrapper}>
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            disableFocusRipple
                            style={styles.addButton}
                            onClick={this.sendEventExcel}
                        >PROCESS DETAILS</Button>
                    </Grid>
                </Grid>
            </Grid>
            </div>
            </>
            :null}
        </div>
        <Alert
            open={hasError}
            message={errorMessage}
            callback={this.falsifyError}
            type={errorType}
            duration={5000}
        />
        </>)
    }
}
class LoanCampaign extends React.Component{
    constructor(props){
        super(props);
        this.state={
            eventName:"LOAN_APPLICATION_COMPLETED",
            errors:{},
            eventType:"smsType",
            paramCountPushNotification: null,
            paramCountSms: null,
            paramCountVoice: null,
            paramCountWhatsapp: null,
            pushNotificationTemplate: null,
            smsTemplate: null,
            voiceTemplate: null,
            whatsappTemplate:null,
            selectedFile:null,
            showOptions:false,
            hasError:false,
            errorMessage:"",
            errorType:""

        }
    }



    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }
    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    getEventDetails =()=>{
        const { eventName}=this.state;
        getCampaignEventDetails(eventName,(res)=>{
           if(res){
            const {  paramCountPushNotification,  paramCountSms, paramCountVoice, paramCountWhatsapp,  pushNotificationTemplate,
                smsTemplate,  voiceTemplate, whatsappTemplate}=res;
               this.setState({
                showOptions:true,
                paramCountPushNotification:paramCountPushNotification,
                paramCountSms: paramCountSms,
                paramCountVoice: paramCountVoice,
                paramCountWhatsapp:paramCountWhatsapp,
                pushNotificationTemplate: pushNotificationTemplate,
                smsTemplate: smsTemplate,
                voiceTemplate:voiceTemplate,
                whatsappTemplate:whatsappTemplate
               })
           }else{
               this.setState({
                   showOptions:false
               })
           }
        })
    }

    handleChange = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
      };

    renderDetails = (type)=>{
      const { pushNotificationTemplate,smsTemplate, voiceTemplate, whatsappTemplate}=this.state;
      switch(type){
        case "smsType" :
            return <>
                {smsTemplate??"NA"}
            </>
        case "whatsAppType" :
            return <>
                   {whatsappTemplate??"NA"}
            </>
        case "pushNotificationType" :
            return <>
                   {pushNotificationTemplate??"NA"}
            </>
        case "voiceType" :
            return<>
                   {voiceTemplate??"NA"}
            </>
        default :
            return null;
      }
    }


    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=> this.handleValidation());
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { selectedFile} = this.state;

        if (!selectedFile) {
            formIsValid = false;
            errorsTemp["selectedFile"] = "Please select file";
        }
        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    sendEventExcel = e =>{

        e.preventDefault();
        const {selectedFile,eventName} = this.state;
        let formData = new FormData();
        formData.append("file", selectedFile);
        const AUTH_RESPONSE = getLocalStorage('auth');
        const headers = {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${AUTH_RESPONSE.access_token}`
        }

        if(this.handleValidation()){
             loanCampaignProcessExcel(eventName,formData, headers,async (res)=>{
                const json = await res;
                if(json && json.success){
                   this.setState({
                       hasError:true,
                       errorMessage:json.message,
                       errorType:"success",
                       showOptions:false
                   })
                }else{
                    this.setState({
                        hasError:true,
                        errorMessage:json.message,
                        errorType:"error",
                        showOptions:false
                    })
                }
            })
        } else {
            this.setState({
            hasError:true,
            errorMessage:"Please fill the details correctly ",
            errorType:"error"
            })
         }
    }



    render(){
        const {eventName ,eventType,errors,showOptions,hasError,errorMessage,errorType}= this.state;
        const { eventList}= this.props;
        return(<>
        <div style={styles.rootContainerLoan}>
            <div style={styles.rootContainer}>
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Grid container item xs={12} spacing={1}>
                        <Grid item xs={3} style={styles.fieldWrapper}>
                                <Typography style={styles.fieldLabel}>Event Name </Typography>
                                <TextField
                                    id={eventName}
                                    type="select"
                                    name="eventName"
                                    required
                                    value={eventName}
                                    onChange={this.handleField}
                                    style={styles.textField}
                                    select={true}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    size={'small'}
                                    variant="outlined"
                                >
                                    {this.loadOptions(eventList??[])}
                                </TextField>
                        </Grid>
                        <Grid item xs={3} style={styles.fieldWrapper}>
                            <Button
                                type="submit"
                                variant="contained"
                                disableElevation
                                disableFocusRipple
                                style={styles.addButton}
                                onClick={this.getEventDetails}

                            >GET EVENT DETAILS</Button>
                        </Grid>

                    </Grid>
                </Grid>
            </div>
            {showOptions?<>
            <div style={{padding:10}}>
            <RadioGroup row aria-label="type" name="eventType" value={eventType} onChange={this.handleChange}>
                <FormControlLabel value="smsType"control={<Radio style={{color:"green"}} />} label="SMS" />
                <FormControlLabel value="whatsAppType" control={<Radio style={{color:"green"}}/>} label="Whatsapp" />
                <FormControlLabel value="pushNotificationType" control={<Radio style={{color:"green"}} />} label="Push Notification" />
                <FormControlLabel value="voiceType" control={<Radio style={{color:"green"}} />} label="Voice SMS" />
            </RadioGroup>
               <div style={{border:"1px solid",borderColor:"brown",padding:10 }}>
               {this.renderDetails(eventType)}
               </div>
            </div>
            <div style={styles.rootContainer}>
            <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                <Grid container item xs={12} spacing={1}>
                    <Grid item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Upload File </Typography>
                        <Button variant="contained" style={{height:40,width:250}} component="label" >
                        <input
                            type="file"
                            //hidden
                            onChange={this.onFileChange}
                            accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            />
                        </Button>
                        {errors["selectedFile"]?
                        <div style={{margin:2,color:"red"}}>{errors["selectedFile"]}</div>
                        :null}
                    </Grid>
                    <Grid item xs={3} style={styles.fieldWrapper}>
                        <Button
                            type="submit"
                            variant="contained"
                            disableElevation
                            disableFocusRipple
                            style={styles.addButton}
                            onClick={this.sendEventExcel}
                        >PROCESS DETAILS</Button>
                    </Grid>
                </Grid>
            </Grid>
            </div>
            </>
            :null}
        </div>
        <Alert
            open={hasError}
            message={errorMessage}
            callback={this.falsifyError}
            type={errorType}
            duration={5000}
        />
        </>)
    }
}

class SmsCampaign extends React.Component{
    constructor(props){
        super(props);
        this.state={
            loanEventList:[],
            clEventList:[]
        }
    }

    componentDidMount(){
      this.callAPis();
    }

    callAPis = ()=>{
        const getLoanCampaignEventListPromise = new Promise((resolve, reject) => {
            getCampaignEventList( res => resolve(res))
        })
        // const getCLCampaignEventListPromise = new Promise((resolve, reject) => {
        //     getCLCampaignEventList(res => resolve(res))
        // })

        Promise.all([
            getLoanCampaignEventListPromise,
        ]).then(res => {
            this.setState({
                loanEventList: res[0],
                clEventList: [],
            })
        }).catch(err => {
            this.setState({
                hasError: true,
                loading: false
            })
        })
    }
    render(){
        const {loanEventList,clEventList}=this.state;
        return(<>
        <SimpleFade loanEventList={loanEventList} clEventList={clEventList}/>
        </>)
    }
}

const  styles = {
    mainContainer:{
        flowGrow: 1,
        background: "#fff",
        padding: 0,
        height: "calc(100vh - 0px)",
        overflowY: "scroll"

    },
    rootContainercl: {
        flowGrow: 1,
        background: "#fff",
        padding: 5,
        height: "calc(100vh - 210px)",
        overflowY: "scroll",
        margin:5
    },
    rootContainerLoan: {
        flowGrow: 1,
        background: "#fff",
        padding: 5,
        height: "calc(100vh - 210px)",
        overflowY: "scroll",
        margin:5
    },

    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "0%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 25px",
        fontWeight:400
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    fileUpload:{
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        background:"red"
    },
    addButton: {
        width: 250,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "rgb(16,12,109)",
    },
    tableContainer: {
        height: "calc(60vh - 120px)",
        backgroundColor:"white",
    },
    table: {
    },
    tableRow: {
    },
    tableBody: {
    },
    tableCell: {
    },
    bodyTableCell:{
        padding:"3px 10px"
    },

}

export default SmsCampaign;