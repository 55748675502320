import React from "react";
import { observer } from "mobx-react";

import '../../css/main.css';
import "./main.css";
import { getCompanyListForGraph, postCountForCompany } from "../../service/api";
import LoanDashboard from "./loanDashboard";
import CreditlineDashboard from "./creditlineDashboard";


class DashboardScreen extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            DATALIST:[],
            countType:"Daily",
            companyName:"Swiggy",
            companyList:[],
            hasError:false,
            errorMessage:"",
            errorType:"",
            loading:false,
            loanDetailCounts:{},
            countsBlockList:[],
            loadingForValueCounts:false,
            dashboardType:"loanDashboard"
        }
    }


    componentDidMount () {
    this.callApis();
    }

    callApis=()=>{
        getCompanyListForGraph((res)=>{
            if(res && res.success){
              this.setState({
                  companyList:res.response?res.response:[],
                  companyName:res.response[0]?res.response[0]:"SWIGGY"
              })
            }else{
                console.log("problem");
            }
        })



    }



    responseOnchange=()=>{
        const { companyName,countType}=this.state;
        let params ={
            "type": countType,
            "company":companyName
        }
        this.setState({
            loading:true
        })
        postCountForCompany(params,(res)=>{
            if(res && res.success){
                this.setState({
                    DATALIST:res.response?res.response:[],
                    loading:false
                })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    onchangeLoan =()=>{
        this.setState({dashboardType:"loanDashboard"})
    }
    onchangeCl =()=>{
        this.setState({dashboardType:"creditlineDashboard"})
    }

    toDashboard =(type)=>{
        const {  companyList }=this.state;
        switch(type){
            case "loanDashboard":
                return <LoanDashboard
                companyList={companyList}
                changeTo={()=>this.onchangeCl()}
                />

            case "creditlineDashboard":
                return <CreditlineDashboard
                companyList={companyList}
                changeTo={()=>this.onchangeLoan()}
                />
             default :
                return ;
        }

    }


    render () {
        const {  dashboardType }=this.state;
        return (
            <>
        <div style={styles.rootContainer}>

             {this.toDashboard(dashboardType)}

        </div>
            </>
        );
    }
}

const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    container: {
        padding: "20px 60px",
    },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20,
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        height: 40
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
}
export default observer(DashboardScreen);