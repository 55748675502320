import React from "react";
import Detail from "./../../components/detail";
import { findByPathName, convertTimestamptoDate } from "./../../utils/helper";
import { getUserCreditlineDetail,  getUserInfo, getUserKycDetail, getUserLoanDetail } from "./../../service/api";
import { LOAN_REASON_CODES } from "./../../common/constants";
import Alert from "./../../components/alert";

import { rolePermission } from "../../common/constants";

class UserDetail extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            data: [],
            id: null,
            userDetails: {},
            loanHistory: [],
            kycStatus: [],
            creditLineDetail: {}
        }
    }

    async componentDidMount () {
        const { location } = this.props;
        const userId = findByPathName(location);
        this.fetchUserDetail(userId);
    }

    fetchUserDetail = async (id) => {

        const getUserDetailPromise = new Promise(function (resolve, reject) {
            getUserInfo(id, {}, res => resolve(res))
        })
        const getUserLoanDetailPromise = new Promise(function (resolve, reject) {
            getUserLoanDetail(id, {}, res => resolve(res))
        })
        const getUserKycDetailPromise = new Promise(function (resolve, reject) {
            getUserKycDetail(id, {}, res => resolve(res))
        })
        const getUserCreditlineDetailPromise = new Promise(function (resolve, reject) {
            getUserCreditlineDetail(id, {}, res => resolve(res))
        })

        Promise.all([
            getUserDetailPromise,
            getUserLoanDetailPromise,
            getUserKycDetailPromise,
            // getUserCreditlineDetailPromise
        ]).then(res => {
            this.setState({
                userDetails: res[0],
                loanHistory: res[1],
                kycStatus: res[2],
                // creditLineDetail: res[3],
                id: id
            }, () => this.makeColumns())
        }).catch(err => {
            this.setState({
                hasError: true,
                loading: false,
                id: id
            })
        })
    }

    createUserDetail = (dat) => {
        if (!dat) return [];
        const { company, userId, phone, name, dob, createdDate } = dat;
        return [
            { key: "user-id", label: "USER ID", value: userId },
            { key: "user-name", label: "USER NAME", value: name },
            { key: "user-phone", label: "PHONE", value: phone },
            { key: "user-company", label: "COMPANY", value: company },
            { key: "user-dob", label: "DATE OF BIRTH", value: dob ? convertTimestamptoDate(dob) : "-" },
            { key: "user-created-date", label: "CREATED DATE", value: convertTimestamptoDate(createdDate) },
        ]
    }

    createKycDetail = (dat) => {
        if (!dat) return [];
        const { kycStatus } = dat;
        return [
            { key: "user-aadhar", label: "AADHAR STATUS", value: kycStatus },
            { key: "user-pan", label: "PAN STATUS", value: kycStatus }
        ]
    }

    createLoanHistory = (dat) => {
        if (!dat) return [];
        return dat;
    }

    createCreditLineDetail = (dat) => {
        if (!dat) return [];
        const { createdDate, approvedCreditLimit, remainingCreditLimit, status, applicationCompleteDate, collectionMode, processDate, signedDate, reasonCode } = dat;
        return [
            { key: "credit-line-created-date", label: "CREATED DATE", value: convertTimestamptoDate(createdDate) },
            { key: "credit-line-approved-credit-limit", label: "APPROVED CREDIT LIMIT", value: approvedCreditLimit },
            { key: "credit-line-remaining-credit-limit", label: "REMAINING CREDIT LIMIT", value: remainingCreditLimit },
            { key: "credit-line-status", label: "STATUS", value: status },
            { key: "credit-line-application-complete-date", label: "APPLICATION COMPLETE DATE", value: convertTimestamptoDate(applicationCompleteDate) },
            { key: "credit-line-collection-mode", label: "COLLECTION MODE", value: collectionMode },
            { key: "credit-line-process-date", label: "PROCESS DATE", value: convertTimestamptoDate(processDate) },
            { key: "credit-line-signed-date", label: "SIGNED DATE", value: convertTimestamptoDate(signedDate) },
            { key: "credit-line-reason-code", label: "REASON CODE", value: LOAN_REASON_CODES[reasonCode] }
        ]
    }

    checkViewPermission = (key) => {
        const role = "roleAdmin";
        return rolePermission[role].includes(key);
    }

    makeColumns = () => {
        const { userDetails, loanHistory, kycStatus, creditLineDetail } = this.state;

        const detailData = [
            { key: "user-detail-col", label: userDetails ? "USER DETAIL" : "", value: this.createUserDetail(userDetails), viewPermission: this.checkViewPermission("user-detail-col") },
            { key: "kyc-detail-col", label: kycStatus?"KYC DETAIL":"", value: this.createKycDetail(kycStatus), viewPermission: this.checkViewPermission("kyc-detail-col") },
            // { key: "loan-history-col", label: loanHistory?"LOAN HISTORY":"", value: this.createLoanHistory(loanHistory), viewPermission: this.checkViewPermission("loan-history-col") },
            // { key: "credit-line-col", label: creditLineDetail?"CREDIT LINE DETAIL":"", value: this.createCreditLineDetail(creditLineDetail), viewPermission: this.checkViewPermission("credit-line-col") }
        ]

        this.setState({
            data: detailData
        })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render () {
        const { loading, data, hasError, errorMessage, id } = this.state;
        return (
            <>
                <Detail
                    data={data}
                    loading={loading}
                    handleBack={this.handleBack}
                    id={id}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>

        );
    }
}

export default UserDetail;