import React from "react";
import { Button, Grid, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Typography,LinearProgress,Box } from "@material-ui/core";

import '../../css/main.css';
import { withStyles } from "@material-ui/core/styles";
import ContentLoader from "../../components/contentLoader";
import {  postDeductionResponse } from "../../service/api";
import Alert from "../../components/alert";
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const COLSLIST =  [
    { key: "dc-statement-id", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "dc-loan-id", align: "left", label: "LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-emi-uploaded", align: "left", label: "EMI UPLOADED", value: "emiUploaded", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-emi-deducted", align: "left", label: "EMI DEDUCTED", value: "emiDeducted", type: "text", sort: false, fn: (v) => v?? "-" },
    //{ key: "dc-button", align: "left", label: "DELETE", value: "", type: "button", sort: false, fn: (v) => v },
]




class FilteredDataList extends React.Component{

    delete(el){
        if (window.confirm( `Are you sure want to delete Statement Id = ${el.statementId} ?`))
        {
          this.props.delete(el);
        }
    }

    render(){
       const { ChildData,cols,loading}= this.props;
       return(
          <div>
                <TableContainer style={styles.tableContainer} >
                    <Table stickyHeader style={styles.table} aria-label="simple-table">
                        <TableHead style={styles.tableHead}>
                            <TableRow style={styles.tableRow}>
                                {cols.map(it => <TableCell key={it.key}>
                                    {it.label}
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody style={styles.tableBody}>
                            { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                                ChildData.map((item,i) => <StyledTableRow  style={styles.tableRow}>
                                    {cols.map(col =>
                                    <TableCell style={styles.tableCell}>
                                        {  col.type==="text"?
                                          col.fn(item[col.value]):
                                          <IconButton style={{color:"red"}} size={"large"} onClick={this.delete.bind(this, item)}>
                                              <DeleteOutlineIcon></DeleteOutlineIcon>
                                          </IconButton>
                                        //   <Button style={{background:"red"}} size={"small"} onClick={this.delete.bind(this, item)}>Delete</Button>
                                        }
                                        </TableCell>
                                    )}
                                    </StyledTableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
          </div>
       )
    }
 }


 class FaultyDataList extends React.Component{

    render(){
       const { ChildData,cols,loading,title}= this.props;
       return(

          <div>
              <div style={styles.listHeading}>{title}</div>
                <TableContainer style={styles.tableContainer} >
                    <Table stickyHeader style={styles.table} aria-label="simple-table">
                        <TableHead style={styles.tableHead}>
                            <TableRow style={styles.tableRow}>
                                {cols.map(it => <TableCell key={it.key}>
                                    {it.label}
                                </TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody style={styles.tableBody}>
                            { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                                ChildData.map((item,i) => <StyledTableRow  style={styles.tableRow}>
                                    {cols.map(col =>
                                    <TableCell style={styles.tableCell}>
                                        {
                                          col.fn(item[col.value])
                                        }
                                        </TableCell>
                                    )}
                                    </StyledTableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
          </div>
       )
    }
 }

class ListItems extends React.Component{
    giveList = ()=>{
        const {filteredDataCount,filteredDataTotalEmi,faultyDataCount,faultyDataTotalEmi,
            negativeAmountCount,negativeAmountTotalEmi}=this.props.listItems;
        return [
            { key: "dc-filtered-data-count", label: "FILTERED DATA COUNT", value: filteredDataCount??"-" },
            { key: "dc-filtered-data-total-emi", label: "FILTERED DATA TOTAL EMI", value: filteredDataTotalEmi??"-" },
            { key: "dc-faulty-data-count", label: "FAULTY DATA COUNT", value: faultyDataCount ??"-"},
            { key: "dc-faulty-data-total-emi", label: "FAULTY DATA TOTAL EMI", value: faultyDataTotalEmi ??"-"},
            { key: "dc-negative-amount-count", label: "NEGATIVE AMOUNT COUNT", value:negativeAmountCount ??"-"},
            { key: "dc-negative-amount-total-emi", label: "NEGATIVE AMOUNT TOTAL EMI", value: negativeAmountTotalEmi??"-" }
        ]
    }

    render(){
        const data = this.giveList();
        return (<>
        <Grid style={styles.tableContainer} container item xs={12} spacing={3}>
                {data.map((item, i) => <Grid key={item.key} item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>{item.label}</Typography>
                    <Typography style={styles.fieldValue}>{item.value ?? "-"}</Typography>
                </Grid>
                )}
            </Grid>
        </>)
    }
}
function LinearProgressWithLabel(props) {
    return (
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

class ChangeResponse extends React.Component {
    constructor(){
        super();
        this.state = {
          tempData: [],
          tempFaultyDataList:[],
          transactionId:"",
          hasError: false,
          errorMessage: "",
          errorType:"",
          showSuccess:false,
          showLoading:false,
          responseMessage:"Wait for a while . . . .",
          isLast:false,
          progressPercent:0
        }
        this.delete = this.delete.bind(this);
     }

     componentDidMount(){
         const { filteredDataList,faultyDataList}= this.props;
         this.setState({
             tempData:filteredDataList,
             tempFaultyDataList:faultyDataList
         })
     }

     delete = (elm)=>{
        this.setState(prevState => ({
            tempData: prevState.tempData.filter(el => el !== elm )
        }));
     }
    float2int= (value)=> {
        return value | 0;
    }
    returnValue = (value)=>{
        return value | 1;
    }

    uploadFilteredData =()=>{
        const { COMPANY, callBackIt}= this.props;
        const { tempData}= this.state;
        const totalLengthOFData = tempData.length;
        this.setState({
            showLoading:true
        })
        if(tempData.length<2000){
            postDeductionResponse({
                "filteredDataList":tempData,
                "company":COMPANY,
                "transactionId": null,
                "last" :true,
            },(res)=>{
               if(res&& res.success){
                this.setState({
                    progressPercent:(totalLengthOFData/totalLengthOFData)*100,
                    showSuccess:true,
                    hasError:true,
                    errorMessage:"Data succesfully uploaded",
                    errorType:"success",
                    responseMessage:"Your response saved"
                })
                setInterval(() => {
                    callBackIt()
                    window.location.reload(false);
                }, 3000);
               }else{
                    this.setState({
                        hasError:true,
                        errorMessage:"",
                        errorType:"error"
                    })
               }
            })

        }else{
            let varlength = 2000;
            const items = tempData.splice(0,varlength);
            this.setState({
                progressPercent:100-(tempData.length/totalLengthOFData)*100
            })
            postDeductionResponse({
                "filteredDataList":items,
                "company":COMPANY,
                "transactionId": null,
                "last" :false,
            },(res)=>{
                if(res && res.success){
                    const { transactionId} = res.data;
                    delete tempData[items];
                    let timer = setInterval(() => {
                        this.setState({
                            progressPercent:100-(tempData.length/totalLengthOFData)*100
                        })
                        if(tempData.length<2000){
                            postDeductionResponse({
                                "filteredDataList":tempData,
                                "company":COMPANY,
                                "transactionId": transactionId,
                                "last" :true,
                            },(result)=>{
                                if(result&& result.success){
                                    this.setState({
                                        isLast:true,
                                        progressPercent:100,
                                        showSuccess:true,
                                        hasError:true,
                                        errorMessage:"Data succesfully uploaded",
                                        errorType:"success",
                                        responseMessage:"Your response saved"
                                    })
                                    setInterval(() => {
                                        callBackIt()
                                        window.location.reload(false);
                                    }, 3000);
                                    clearInterval(timer)
                                }else{
                                    this.setState({
                                        hasError:true,
                                        errorMessage:"",
                                        errorType:"error"
                                    })
                                }
                            })

                        }else {
                                const selectedItems = tempData.splice(0,varlength)
                                postDeductionResponse({
                                    "filteredDataList":selectedItems,
                                    "company":COMPANY,
                                    "transactionId": transactionId,
                                    "last" :false,
                                },(response)=>{
                                  if(response && response.success){
                                    delete tempData[selectedItems];
                                  }else{
                                    this.setState({
                                        hasError:true,
                                        errorMessage:"",
                                        errorType:"error"
                                    })
                                  }
                            })
                        }
                    }, 2500);
                }else{
                    this.setState({
                        hasError:true,
                        errorMessage:"",
                        errorType:"error"
                    })
                }

            })
        }
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    render(){
        const { cols,loading,callBackIt,listItems} = this.props;
        const {tempData,tempFaultyDataList,hasError,errorMessage,errorType,showLoading,responseMessage,progressPercent}= this.state;
        return(<>

        <div style={styles.mainRootContainer}>
            {!showLoading?
            <>
             <div style={styles.btnBlock}>
                <div style={styles.uploadBtn}>
                <Button style={{background:"green",color:"white"}} onClick={this.uploadFilteredData} >Upload Filter Data List</Button>
                </div>
                <IconButton variant="contained"
                            style={{float: "right",marginTop:0,color:"black"}}
                            onClick={() => callBackIt()}
                        ><CloseIcon />
                </IconButton>
            </div>
            <div style={styles.mainContainer} className="scrollbar-hidden">

                <ListItems listItems={listItems}/>
                <div style={styles.listHeading}>FILTERED DATA  </div>
                <FilteredDataList delete={this.delete} loading={loading}  cols={cols} ChildData={tempData}/>
                <FaultyDataList delete={this.delete} title={"ALREADY EXIST DATA "} loading={loading}  cols={COLSLIST} ChildData={tempFaultyDataList}/>
                <FaultyDataList delete={this.delete} title={"NEGATIVE AMOUNT DATA"} loading={loading}  cols={COLSLIST} ChildData={listItems.negativeAmountList?listItems.negativeAmountList:[]}/>
            </div>
            </>

            :<>
                <div style={styles.loader}>
                   <div style={{color:responseMessage.includes("saved")?"green":"black"}}>
                   {responseMessage}
                   <LinearProgressWithLabel value={progressPercent} />
                   </div>
                </div>


             </>}
            <Alert
                open={hasError}
                message={errorMessage}
                callback={this.falsifyError}
                type={errorType}
                />
        </div>
        </>)
    }

}

const styles = {
    listHeading:{
        padding:10,
        margin:10,
        fontFamily:"inherit"
    },
    btnBlock:{
        background: "#fff",
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position: '-webkit-sticky',
        top: 0,
        bottom: 0,
        zIndex: 5,
        padding:"15px 0px 0px 0px"
    },
    mainRootContainer:{
        height: "calc(100vh - 280px)",
        background: "#fff",
        outline: "none",
        padding: "0px 25px 25px 25px",
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    mainContainer:{

    },
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute"
    },
    loader:{
        height: "calc(100vh - 280px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        color:"black",
        fontSize:"20px",
        overflowY: "scroll",
        fontWeight:600
    },
    uploadBtn:{
        // listStyle: "none",
        // display: "flex",
        // alignItems: "center",
        // justifyContent: "flex-end",
        // flexWrap: 'wrap',
        // marginRight:100,
        // marginBottom:-50
     },
    responseEdit:{
        position: 'absolute',
        top:"9%",
        background:"white",
        color:"black",
        padding:10,
        margin:"4% 2% 4% 2.5%",
        width:"92%",
        heigth:"auto",
        border:"2px solid",
        borderRadius:10,

        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "0%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 25px",
        fontWeight:400
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    fileUpload:{
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        background:"red",
    },
    addButton: {
        width: 250,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "#1B2D93",
        "&:hover": {
            background: "#1B2D93"
        }
    },
    tableContainer: {
        height: "calc(50vh - 200px)",
        margin:"1%",
        border:"1px solid",
        width:"98%"
        // height: "auto"
    },
    table: {

    },
    tableRow: {

    },
    tableBody: {

    },
    tableCell: {
    }
}

export default ChangeResponse;