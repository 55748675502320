import React from "react";
import {
    Button,
    Grid,
    Typography,
    TextField,
    MenuItem
} from "@material-ui/core";
import { getAgent } from "../../service/api";

const FIELD = [
    { key: "assign-agent", name: "agentId", label: "AGENT", type: "select", option: [{
        "value": 2,
        "label": "Vashu"
    },
    {
        "value": 3,
        "label": "Jitender"
    },
    {
        "value": 4,
        "label": "Priyanaka"
    }] }
]

export default function AssignAgent(props) {
    const { handleClose } = props;
    const [field, setField] = React.useState({});

    React.useEffect(() => {
        fetchAgent();
    });

    const fetchAgent = () => {
        getAgent((res) => {
            console.log("getAgent", res);
        })
    }

    const handleChange = evt => {
        const { name, value } = evt.target;
        setField({ ...field, [name]: value });
    }

    return (
        <div style={styles.rootContainer}>
            <Grid
                container
                justify="center"
                style={styles.innerContainer}
            >
                <Typography
                    variant="h5"
                    gutterBottom
                    style={styles.typography}
                >ASSIGN AGENT</Typography>
                {FIELD.map(item => {
                    const { key, name, label, type, option } = item;
                    const isSelect = type === "select";
                    return <TextField
                        id={key}
                        name={name}
                        label={label}
                        placeholder={label}
                        select={isSelect}
                        type={type}
                        defaultValue={isSelect ? option[0].value : null}
                        required
                        style={styles.textField}
                        variant="outlined"
                        size="small"
                        onChange={handleChange}
                        InputLabelProps={{
                            shrink: true
                        }}
                    >{isSelect?
                        option.map(item => <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)
                    :null}</TextField>
                })}
                <div style={styles.btnContainer}>
                    <Button
                        style={styles.closeBtn}
                        variant="outlined"
                        onClick={handleClose}
                    >CLOSE</Button>
                    <Button
                        style={styles.saveBtn}
                        variant="outlined"
                    >ADD</Button>
                </div>
            </Grid>

        </div>
    );
}

const styles = {
    rootContainer: {
        display: "flex",
        height: "100vh",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    innerContainer: {
        width: 390,
        background: "#fff",
        borderRadius: 6,
        padding: 30
    },
    typography: {
        fontFamily: "Poppins"
    },
    textField: {
        width: 300,
        margin: "20px 0"
    },
    btnContainer: {
        margin: "15px 0px",
        display: "inherit",
        width: 300,
        justifyContent: "space-between"
    },
    closeBtn: {
        width: 100
    },
    saveBtn: {
        width: 100,
        background: "#1B2D93",
        color: "#fff"
    }
}