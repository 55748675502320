import React from "react";
import {
    TableContainer,
    Table,
    TableRow,
    TableHead,
    TableBody,
    TableCell
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import ContentLoader from "./contentLoader";

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

class SimpleTable extends React.Component {
    render() {
        const { classes, cols, data, loading,customeHeight } = this.props;
        return (
            <TableContainer style={{
                height: customeHeight?`calc(100vh - ${customeHeight}px)`:"calc(100vh - 260px)",
            }}>
                <Table stickyHeader className={classes.table} aria-label="simple-table">
                    <TableHead className={classes.tableHead}>
                        <TableRow className={classes.tableRow}>
                            {cols.map(it => <TableCell key={it.key}>
                                {it.label}
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                            data.map(it => <StyledTableRow className={classes.tableRow}>
                                {cols.map(col => <TableCell className={classes.tableCell}>
                                    {col.fn((it[col.value]))}</TableCell>
                                )}
                                </StyledTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        );
    }
}

const styles = {
    tableContainer: {
        height: "calc(100vh - 260px)",
        // height: "auto"
    },
    table: {

    },
    tableRow: {

    },
    tableBody: {

    },
    tableCell: {

    },
    tableHead:{
        "& .MuiTableCell-head": {
            color: "black",
            fontWeight:"bold",
            backgroundColor: "rgb(236,246,255)"
        },
    }
}

export default withStyles(styles)(SimpleTable);