import React from "react";

import '../../css/main.css';
import { Chart } from "react-google-charts";
import { Grid } from "@material-ui/core";
import "./main.css";

export default function CreateChart(props){
    const {data,options,type,loading} = props;

  
    return (<>
        <div className="center" style={{display:loading?"flex":"none"}}>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
            <div className="wave"></div>
        </div>
        <Grid container item xs={12} spacing={3} 
            style={{
                fontFamily: "Poppins",
                padding: 20,
                display:!loading?"flex":"none"
            }}>
            <Chart
                chartType={type}
                width="100%"
                height="400px"
                data={data}
                options={options}
                />
        </Grid> 
        
    </>)
}
