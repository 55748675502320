import React, {  } from "react";
import { observer } from "mobx-react";

import '../../css/main.css';
import { Button, Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import "./main.css";
import { postClCountForCompany, postClCountForCompanyByStatus, postCountsForcl, postDpdCountForclPieChart,getClEmandateCountsForCards } from "../../service/api";
import Alert from "../../components/alert";
import { convertTimestamptoDate } from "../../utils/helper";
import CreateChart from "./customChart";
const count_Type= [
    { key: "Daily", value: "Daily" },
    { key: "Weekly", value: "Weekly" },
    { key: "Monthly", value: "Monthly" },
]

// function CreateChart(props){
//     const {data,options,type} = props;
//     return (<>
//         <Grid container item xs={12} spacing={3}
//             style={styles.fieldWrapper}>
//               <Chart
//                 chartType={type}
//                 width="100%"
//                 height="400px"
//                 data={data}
//                 options={options}
//                 />
//         </Grid>

//     </>)
// }

class CreditlineDashboard extends React.Component{
    constructor(props){
        super(props);
        this.state={
            ApplicationCountGraph:[],
            loadingForGraph:false,
            hasError:false,
            errorMessage:"",
            errorType:"",
            countType:"Daily",
            companyName:"All",
            clDetailCounts:{},
            countsBlockList:[],
            loadingForValueCounts:false,
            countDetailStartDate:this.getCurrentDate("lastMonth"),
            countDetailEndDate:this.getCurrentDate("currentMonth"),
            countDetailCompany:"All",
            clApplicationGraph:{
                countType:"Daily",
                companyName:"All",
                loading:false,
                graphCount:[]
            },
            clApplicationByStatusGraph:{
                countType:"Daily",
                companyName:"All",
                statusValue:2,
                loading:false,
                graphCount:[]
            },

            clDpdPieChart:{
                companyName:"All",
                loading:false,
                graphCount:[]
            },
            clEmandateDetailsCardsLoading:false,
            clEmandateDetailsCardsDetails:{},
            countsBlockListForEmandate:[]
        }
    }

    componentDidMount(){
     this.countApi()
     this.responseclApplicationGraph();
     this.responseOnchangeByStatus();
     this.responseOfDpdCount();
     this.responseclEmandateForCards();
    }
    getCurrentDate=(type)=>{
        const separator="-";
        let newDate = new Date()
        let date = newDate.getDate();
        let month = type==="currentMonth"?newDate.getMonth() + 1:newDate.getMonth();
        let year = newDate.getFullYear();
        return `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date}`
    }
    countApi =()=>{
        const {
            countDetailCompany,
        }=this.state;
        let params ={
            // "startDate":countDetailStartDate,
            // "endDate":countDetailEndDate,
            "company":countDetailCompany
        }
        this.makeColumns()
        this.setState({
            loadingForValueCounts:true
        })
        postCountsForcl(params,(res)=>{
            if(res && res.success){
                this.setState({
                    clDetailCounts:res.response,
                    loadingForValueCounts:false
                })
                this.makeColumns()

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"error in cl detail api",
                    errorType:"error",
                    loadingForValueCounts:false
                })
            }
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item.key} value={item.value}>
                {item.key}</MenuItem>
        })
    }
    loadOptionForCompany = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    handleFieldForclApplicationGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                clApplicationGraph: Object.assign({},
                state.clApplicationGraph, {
                [name]: value
              })
            }
          })
    }



    handleFieldCountDetail = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }
    responseclApplicationGraph=()=>{
        const { companyName,countType}=this.state.clApplicationGraph;
        let params ={
            "type": countType,
            "company":companyName
        }
        this.setState((state)=> {
            return {
                clApplicationGraph: Object.assign({},
                state.clApplicationGraph, {
                   loading:true
              })
            }
          })
        postClCountForCompany(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        clApplicationGraph: Object.assign({},
                        state.clApplicationGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    /// for emandate

    responseclEmandateForCards=()=>{
        this.setState({
            clEmandateDetailsCardsLoading:true
        })
        getClEmandateCountsForCards({},(res)=>{
            if(res && res.success){
                const {response} = res;
                this.setState({
                    clEmandateDetailsCardsDetails:response,
                    clEmandateDetailsCardsLoading:false
                },()=>console.log(this.state.clEmandateDetailsCardsDetails))
                this.makeColumnForEmandate();
            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error",
                    clEmandateDetailsCardsLoading:false
                })
            }
        })

    }

    /// by status

    handleFieldForclApplicationByStatusGraph = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                clApplicationByStatusGraph: Object.assign({},
                state.clApplicationByStatusGraph, {
                [name]: value
              })
            }
          })
    }

    responseOnchangeByStatus=()=>{
        const { companyName,countType,statusValue}=this.state.clApplicationByStatusGraph;
        let params ={
            "type": countType,
            "company":companyName,
            "status":statusValue
        }
        this.setState((state)=> {
            return {
                clApplicationByStatusGraph: Object.assign({},
                state.clApplicationByStatusGraph, {
                loading:true
              })
            }
          })
        postClCountForCompanyByStatus(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        clApplicationByStatusGraph: Object.assign({},
                        state.clApplicationByStatusGraph, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    //// dpd

    handleFieldForclDpdCount = (event) => {
        const { name, value } = event.target;
        this.setState((state)=> {
            return {
                clDpdPieChart: Object.assign({},
                state.clDpdPieChart, {
                [name]: value
              })
            }
          })
    }

    responseOfDpdCount=()=>{
        const { companyName}=this.state.clDpdPieChart;
        let params ={
            "company":companyName,
        }
        this.setState((state)=> {
            return {
                clDpdPieChart: Object.assign({},
                state.clDpdPieChart, {
                loading:true
              })
            }
          })
          postDpdCountForclPieChart(params,(res)=>{
            if(res && res.success){
                this.setState((state)=> {
                    return {
                        clDpdPieChart: Object.assign({},
                        state.clDpdPieChart, {
                            graphCount:res.response?res.response:[],
                            loading:false
                      })
                    }
                  })

            }else{
                this.setState({
                    hasError:true,
                    errorMessage:"",
                    errorType:"error"
                })
            }
        })

    }

    makeColumns = () => {
        const {


            clActiveCount,
            clApplicationCount,
            clApprovedCount,
            clDataCompletionCount,
            clRejectCount,
            mostActiveUserId,
            mostActiveUserName,
            mostActiveUserNumberOfTransactions,
            noOfPayments ,
            paymentCollection,
            transactionAmountFailed ,
            transactionAmountPending,
            transactionAmountSuccess,
            transactionFailed ,
            transactionPending ,
            transactionSuccess ,
            userStuckCount


        }=this.state.clDetailCounts;



        const detailData = [
            { key: "dash-count-1", label: "ACTIVE CREDITLINES", value: clActiveCount??"---", bgColor:"#C8FACD" },
            { key: "dash-count-2", label: "TOTAL APPLICATIONS", value: `${clApplicationCount??"---"}`, bgColor:"#5BE584"  },
            { key: "dash-count-3", label: "TOTAL APPROVED ", value: clApprovedCount??"---", bgColor:"#00AB55" },

            { key: "dash-count-4", label: "DATA COMPLETE", value: clDataCompletionCount??"---", bgColor:"#C8FACD" },
            { key: "dash-count-5", label: "TOTAL REJECTED", value: `${clRejectCount??"---"}`, bgColor:"#5BE584"  },
            { key: "dash-count-9", label: "NO. OF PAYMENTS ", value: noOfPayments??"---", bgColor:"#00AB55" },

            { key: "dash-count-6", label: "MOST ACTIVE USER ID ", value: mostActiveUserId??"---", bgColor:"#00AB55" },
            { key: "dash-count-7", label: "MOST ACTIVE USER NAME", value: mostActiveUserName??"---", bgColor:"#C8FACD" },
            { key: "dash-count-8", label: "MOST ACTIVE USER TRANSACTIONS", value: `${mostActiveUserNumberOfTransactions??"---"}`, bgColor:"#5BE584"  },

            { key: "dash-count-10", label: "PAYMENT COLLECTION", value: paymentCollection??"---", bgColor:"#C8FACD" },
            { key: "dash-count-11", label: "TRANSACTION AMOUNT FAILED", value: `₹${transactionAmountFailed??"---"}`, bgColor:"#5BE584"  },
            { key: "dash-count-12", label: "TRANSACTION AMOUNT PENDING ", value: transactionAmountPending??"---", bgColor:"#00AB55" },

            { key: "dash-count-13", label: "TRANSACTION AMOUNT SUCCESS", value: transactionAmountSuccess??"---", bgColor:"#C8FACD" },
            { key: "dash-count-14", label: "TRANSACTION FAILED", value: `${transactionFailed??"---"}`, bgColor:"#5BE584"  },
            { key: "dash-count-15", label: "TRANSACTION PENDING ", value: transactionPending??"---", bgColor:"#00AB55" },

            { key: "dash-count-16", label: "TRANSACTION SUCCESS", value: transactionSuccess??"---", bgColor:"#C8FACD" },
            { key: "dash-count-17", label: "USERS STUCK", value: `${userStuckCount??"---"}`, bgColor:"#5BE584"  },
        ]

        this.setState({
            countsBlockList:detailData
        })

    }

    makeColumnForEmandate = () => {
        const {
            clEMandateStatusPending,
            clEMandateStatusSuccess,
            clEMandateStatusFailed
        }=this.state.clEmandateDetailsCardsDetails;

        console.log(this.state.clEmandateDetailsCardsDetails);


        const detailData = [
            { key: "dash-count-e1", label: "TOTAL PENDING", value: clEMandateStatusPending===0?"0":clEMandateStatusPending??"---", bgColor:"#C8FACD" },
            { key: "dash-count-e2", label: "TOTAL SUCCESS", value: clEMandateStatusSuccess===0?"0":clEMandateStatusSuccess??"---", bgColor:"#5BE584"  },
            { key: "dash-count-e3", label: "TOTAL FAILED ", value: clEMandateStatusFailed===0?"0":clEMandateStatusFailed??"---", bgColor:"#00AB55" },
        ]

        this.setState({
            countsBlockListForEmandate:detailData
        })

    }

    falsifyError = () => {
        this.setState({
            hasError:false
        })
    }
    dateValidation =(type)=>{
        const {
            countDetailStartDate,
            countDetailEndDate,
        }=this.state;
        const tempEndDate = Date.parse(countDetailEndDate)-24*60*60*1000;
        const tempStartDate = Date.parse(countDetailStartDate)+24*60*60*1000;
        switch(type){
            case "startDate":
                return convertTimestamptoDate(tempEndDate);
            case "endDate":
                return convertTimestamptoDate(tempStartDate);
            default :
                 return "";
        }

    }

    loadingFunction =()=>{

        return(<>

            <div class="flexbox" >
                <div>

                    <div class="nb-spinner">
                    </div>
                </div>
            </div>

        </>)
    }

    render(){
        const {companyList} = this.props;
        const { hasError,errorMessage,errorType,countsBlockList,loadingForValueCounts,
        countDetailCompany,clApplicationByStatusGraph,clDpdPieChart,
        clApplicationGraph,countsBlockListForEmandate,clEmandateDetailsCardsLoading} = this.state;
        const { changeTo} = this.props;
        return(<>
         <div className="start-container">
        <div className="MuiCardContent-root css-16ffwbg">
            <h4 className="MuiTypography-root MuiTypography-h4 MuiTypography-gutterBottom css-1w7rmh">
                CreditLine Dashboard
            </h4>
            <p className="MuiTypography-root MuiTypography-body2 css-1v6tuzf">
            Here you will get all the information about the Creditline App, you will be able to find different types of information here.
            </p>

        </div>
    </div>
    <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
            <div className="block">
                <hr />
                <div className="text">Details</div>
                <hr />
            </div>
            <div className="text">Basic CL Application Details</div>

            <Grid container item xs={12} spacing={1}>
                <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Typography style={styles.fieldLabel}>Company: </Typography>
                    <TextField
                        id={countDetailCompany}
                        type="select"
                        name="countDetailCompany"
                        required
                        value={countDetailCompany}
                        onChange={this.handleFieldCountDetail}
                        style={styles.textField}
                        select={true}
                        InputLabelProps={{
                            shrink: true
                        }}
                        size={'small'}
                        variant="outlined"
                    >
                        {this.loadOptionForCompany(companyList)}
                    </TextField>
                </Grid>
                <Grid  item xs={3} style={styles.fieldWrapper}>
                    <Button
                        style={styles.countGetBtn}
                        onClick={this.countApi}
                    >Get</Button>
                </Grid>
            </Grid>


            <Grid container item xs={12} spacing={1}>

                {loadingForValueCounts?
                <>
                {countsBlockList.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    <div className="spinner-box-small" style={{padding:7}}>
                                        <div className="circle-border-small">
                                            <div className="circle-core-small"></div>
                                        </div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>:
                <>

                {countsBlockList.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    {item.value}
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>
                }
            </Grid>
            <hr className="hrClass"/>
            <div className="text">Emandate Details</div>
            <Grid container item xs={12} spacing={1}>

                {clEmandateDetailsCardsLoading?
                <>
                {countsBlockListForEmandate.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    <div className="spinner-box-small" style={{padding:7}}>
                                        <div className="circle-border-small">
                                            <div className="circle-core-small"></div>
                                        </div>
                                    </div>
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>:
                <>

                {countsBlockListForEmandate.map((item,key)=>{
                    return  <div className="css-19egsyp">
                        <div className="css-81o1ld">
                            <div className="css-i9gxme">
                                <h6 className="css-yemnbq">
                                    {item.label}
                                </h6>
                                <h3 className="css-dq3y6p">
                                    {item.value}
                                </h3>
                            </div>
                        </div>
                    </div>
                })}
                </>
                }
            </Grid>
            <div className="block">
                <hr />
                <div className="text">Analytics</div>
                <hr />
            </div>
            <div className="text">CL Application Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={clApplicationGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={clApplicationGraph.countType}
                            onChange={this.handleFieldForclApplicationGraph }
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={clApplicationGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={clApplicationGraph.companyName}
                            onChange={this.handleFieldForclApplicationGraph }
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseclApplicationGraph}
                        >Get</Button>
                    </Grid>

                </Grid>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            title: `Application Counts for ${clApplicationGraph.companyName}`,
                            curveType: "function",
                        }
                    }
                    data={clApplicationGraph.graphCount}
                    loading={clApplicationGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">CL Application By Status Graph</div>
            <Grid container item xs={12} spacing={1}>
                <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Time Period: </Typography>
                        <TextField
                            id={clApplicationByStatusGraph.countType}
                            type="select"
                            name="countType"
                            required
                            value={clApplicationByStatusGraph.countType}
                            onChange={this.handleFieldForclApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptions(count_Type)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={clApplicationByStatusGraph.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={clApplicationByStatusGraph.companyName}
                            onChange={this.handleFieldForclApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Status: </Typography>
                        <TextField
                            id={clApplicationByStatusGraph.statusValue}
                            type="select"
                            name="statusValue"
                            required
                            value={clApplicationByStatusGraph.statusValue}
                            onChange={this.handleFieldForclApplicationByStatusGraph}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany([1, 2, 3, 4, 6])}
                        </TextField>
                    </Grid>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOnchangeByStatus}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="LineChart"
                    options={
                        {
                            title: `Application Counts for ${clApplicationByStatusGraph.companyName} by status`,
                            curveType: "function",
                        }
                    }
                    data={clApplicationByStatusGraph.graphCount}
                    loading={clApplicationByStatusGraph.loading}
                    />
            </Grid>
            <hr className="hrClass"/>
            <div className="text">DPD Counts in Given Range</div>
            <Grid container item xs={12} spacing={1}>
            <Grid container item xs={12} spacing={1}>
                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Typography style={styles.fieldLabel}>Company: </Typography>
                        <TextField
                            id={clDpdPieChart.companyName}
                            type="select"
                            name="companyName"
                            required
                            value={clDpdPieChart.companyName}
                            onChange={this.handleFieldForclDpdCount}
                            style={styles.textField}
                            select={true}
                            InputLabelProps={{
                                shrink: true
                            }}
                            size={'small'}
                            variant="outlined"
                        >
                            {this.loadOptionForCompany(companyList)}
                        </TextField>
                    </Grid>

                    <Grid  item xs={3} style={styles.fieldWrapper}>
                        <Button
                            style={styles.countGetBtn}
                            onClick={this.responseOfDpdCount}
                        >Get</Button>
                    </Grid>
                </Grid>
                <CreateChart
                    type="PieChart"
                    options={
                        {
                            title: `DPD Counts`,
                        }
                    }
                    data={clDpdPieChart.graphCount}
                    loading={clDpdPieChart.loading}
                    />
            </Grid>

    </Grid>
    <Alert
        open={hasError}
        message={errorMessage}
        type={errorType}
        callback={this.falsifyError}
    />
        </>)
    }
}
const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    container: {
        padding: "20px 60px",
    },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20,
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 180,
        height: 40
    },
    fieldLabel: {
        fontWeight: "bold",
        fontSize: 16,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    countGetBtn:{
        marginTop:25,
        marginBottom: 20,
        alignSelf: "center",
        width: 80,
        height: 30,
        background:"rgb(21, 27, 128)",
        color:"white",
        fontWeight:600
    }
}
export default observer(CreditlineDashboard);