import React from "react";
import {
    Alert,
 } from "@material-ui/lab";
 import {
     Snackbar,
     IconButton
    } from "@material-ui/core";
 import CloseIcon from '@material-ui/icons/Close';
 import { withStyles } from "@material-ui/core/styles";

const defaultErrorMsg = "Something went wrong. Please try again after some time.";

const anchorOrigin = { vertical: "top", horizontal: "right" };


class CustomAlert extends React.Component {
    render() {
        const { classes, type, message, open, callback,duration } = this.props;
        return (
            <div className={classes.alertContainer}>
                <Snackbar
                    autoHideDuration={duration?duration:4000}
                    open={open}
                    key={"error-snackbar"}
                    anchorOrigin={anchorOrigin}
                    onClose={callback}
                ><Alert
                    severity={type ? type : "error"}
                    action={
                        <>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={callback}>
                            <CloseIcon fontSize="small" />
                            </IconButton>
                        </>
                    }
                >
                    {message
                        ? message
                        : defaultErrorMsg}
                    </Alert></Snackbar>
            </div>
        );
    }
}

const styles = {
    alertContainer: {

    }
}

export default withStyles(styles)(CustomAlert);