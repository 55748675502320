import React from "react";
import { observer } from "mobx-react";
import Alert from "./../../components/alert";
import {  getCompanyList, getDeductionListing, postDeductionFile, postDeductionRefreshTokenId } from "./../../service/api";

import '../../css/main.css';
import {
    Typography,
    Grid,
    TextField,
    MenuItem,
    IconButton
} from "@material-ui/core";
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core";
import { COMPANY } from "../../common/constants";
import { withStyles } from "@material-ui/core/styles";
import ChangeResponse from "./changeResponse";
import Skeleton from '@material-ui/lab/Skeleton';
import { getLocalStorage } from "../../utils/localStorage";
import ContentLoader from "../../components/contentLoader";
import CustomModal from "../../components/modal";
import DeductionSummary from "./deductionSummary";
import RefreshIcon from '@material-ui/icons/Refresh';

import RemoveRedEyeIcon from '@material-ui/icons/RemoveRedEye';

const StyledTableRow = withStyles((theme) => ({
    root: {
        height: 30,
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
    },
}))(TableRow);

const COLS = [
    { key: "dc-statement-id", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "dc-loan-id", align: "left", label: "LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-emi-uploaded", align: "left", label: "EMI UPLOADED", value: "emiUploaded", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-emi-deducted", align: "left", label: "EMI DEDUCTED", value: "emiDeducted", type: "text", sort: false, fn: (v) => v?? "-" },
    { key: "dc-button", align: "left", label: "DELETE", value: "", type: "button", sort: false, fn: (v) => v },
    ]

const LISTING_COLS = [
    { key: "dc-company", align: "left", label: "COMPANY", value: "company", type: "text", sort: false, fn: (v) => v ?? "-" },
    { key: "dc-date", align: "left", label: "DATE", value: "date", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-record-excluded", align: "left", label: "RECORDS EXCLUDED", value: "recordsExcluded", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "dc-record-processed", align: "left", label: "RECORDS PROCESSED", value: "recordsProcessed", type: "text", sort: false, fn: (v) => v?? "-" },
    { key: "dc-total-emi-amount", align: "left", label: "TOTAL EMI AMOUNT", value: "totalEmiAmount", type: "text", sort: false, fn: (v) => v },
    { key: "dc-summary", align: "left", label: "SUMMARY", value: "totalEmiAmount", type: "button", sort: false, fn: (v) => v },
    { key: "dc-refresh-button", align: "left", label: "REFRESH", value: "", type: "refresh", sort: false, fn: (v) => v },
]
const errorResponse = {
    "INVALID_NUMBER_OF_COLUMNS":"Please upload file with only 4 columns",
    "INVALID_COLUMN_ORDER":"Please upload file with columns in this order :statement_id,loan_id,emi_uploaded,emi_deducted",
    "INVALID_COLUMN_TYPE":"Invalid data in some rows , Columns cannot be null and must contain numeric value.",
    "DUPLICATE_STATEMENT_ID":"Multiple records found for some statement id, Please upload file with unique statement id"
}
class PayDeduction extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            filteredDataList: [] ,
            faultyDataList:[],
            hasError: false,
            errorMessage: "",
            companyName:"SWIGGY",
            selectedFile:null,
            loadingForEdit:true,
            showNext:false,
            errors:{},
            companyList:COMPANY,
            data:[],
            transactionId:"",
            listItems:{}
        }
    }

    componentDidMount () {
        this.callApis()
    }

    callApis = ()=>{
        getDeductionListing({},
            (res) => {
                if (res && res.success) {
                    const { data } = res;
                    this.setState({
                        loading: false,
                        data: data
                    })
                } else {
                    this.setState({
                        hasError: true,
                    })
                }
            }
        )
        getCompanyList((res)=>{
            if(res && res.success){
              this.setState({
                  companyList:res.response?res.response:COMPANY,
                  companyName:res.response[0]?res.response[0]:"SWIGGY"
              })
            }else{
                this.setState({
                   // hasError: true,
                    errorMessage:"Something wrong with company name list"
                })
            }
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    onFileChange = event => {
        this.setState({
            selectedFile: event.target.files[0]
        },()=> this.handleValidation());
    }

    handleValidation = () => {
        let errorsTemp = {};
        let formIsValid = true;
        const { selectedFile} = this.state;

        if (!selectedFile) {
            formIsValid = false;
            errorsTemp["selectedFile"] = "Please select file";
        }
        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    handleField = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        })
    }

    sendPayDeduction = e =>{

        e.preventDefault();
        const {selectedFile} = this.state;
        let formData = new FormData();
        formData.append("file", selectedFile);
        const AUTH_RESPONSE = getLocalStorage('auth');
        const headers = {
            "Content-Type": "multipart/form-data",
            'Authorization': `Bearer ${AUTH_RESPONSE.access_token}`
        }

        if(this.handleValidation()){
            this.setState({
                showNext:true,
            })
             postDeductionFile(formData, headers,async (res)=>{
                const json = await res;
                if(json && json.success){
                    const { filteredDataList , faultyDataList} = json.data;
                    this.setState({
                        loadingForEdit:false,
                        filteredDataList: filteredDataList?filteredDataList:[],
                        faultyDataList:faultyDataList?faultyDataList:[],
                        listItems:json.data?json.data:[]
                    })
                }else{
                  this.setState({
                    showNext:false,
                    hasError:true,
                    errorMessage:json?errorResponse[json.errorCode]:"",
                    errorType:"error",
                    selectedFile:null
                  })
                }
            })
        } else {
            this.setState({
            hasError:true,
            errorMessage:"Please fill the details correctly ",
            errorType:"error"
            })
         }
    }

    loadingModule = () => {
        return (
            <>
            <div style={{fontSize:"22px",marginLeft:"45%"}}>Processing</div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <div >
                {[ ...Array(4).keys() ].map((i) => <Skeleton style={{height:50}} key={i} animation="wave"  />)}
            </div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            <div >
                {[ ...Array(4).keys() ].map((i) => <Skeleton style={{height:50}} key={i} animation="wave"  />)}
            </div>
            <Skeleton style={{height:40,width:100}}  animation="wave"  />
            </>
        )
    }

    openModal = (data)=>{
     const {transactionId} = data;
      this.setState({
            isOpen:true,
            transactionId:transactionId
      })
    }

    handleClose = _ => {
        this.setState({
            showNext: false
        })
    }

    handleRefresh =(dat,i)=>{
        const { transactionId} = dat;
        const { data} = this.state;
        let temp = data;
        postDeductionRefreshTokenId({"transactionId":transactionId},(res)=>{
           if(res && res.success){
               const { company,recordsExcluded,recordsProcessed,totalEmiAmount} = res.data;
              temp[i].company= company;
              temp[i].recordsExcluded= recordsExcluded;
              temp[i].recordsProcessed= recordsProcessed;
              temp[i].totalEmiAmount=totalEmiAmount;
              this.setState({
                  data:temp
              })
           }else{
               console.log("error")
           }
        })
    }
    handleCols=(data,column,id)=>{
        const { type} = column;
        switch (type) {
            case "button":
                return <IconButton
                onClick={() => this.openModal(data)}
                ><RemoveRedEyeIcon/></IconButton>
            case "refresh":
                return <IconButton
                style={{color:"black"}}
                onClick={() => this.handleRefresh(data,id)}
                ><RefreshIcon /></IconButton>

            default:
                return column.fn(data[column.value])
        }
    }

    listingFunc = (cols,data,loading)=>{
        return (<>
        <TableContainer style={styles.tableContainer} >
                <Table stickyHeader style={styles.table} aria-label="simple-table">
                    <TableHead style={styles.tableHead}>
                        <TableRow >
                            {cols.map(it => <TableCell key={it.key}>
                                {it.label}
                            </TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody style={styles.tableBody} >
                        { loading ? <ContentLoader numberOfLines={4} module="statement"/> :
                            data.map((it,i )=> <StyledTableRow  style={styles.tableRow}>
                                {cols.map(col => <TableCell style={styles.bodyTableCell}>
                                    {this.handleCols(it,col,i)}
                                    </TableCell>
                                )}
                                </StyledTableRow>
                            )
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </>)
    }

    render () {
        const { hasError, errorMessage ,companyName,loadingForEdit,filteredDataList,
            showNext,errors,companyList, faultyDataList,data,isOpen,transactionId,listItems} = this.state;
        return (
            <>
                {!showNext?<>
                    <div style={styles.btn}></div>
                <form onSubmit={this.sendPayDeduction}>
                <div style={styles.rootContainer}>
                    <Typography
                        variant="h5"
                        gutterBottom
                        style={styles.typography}
                    >UPLOAD FILE </Typography>
                   <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Company </Typography>
                                    <TextField
                                        id={companyName}
                                        type="select"
                                        name="companyName"
                                        required
                                        value={companyName}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        select={true}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size={'small'}
                                        variant="outlined"
                                    >
                                        {this.loadOptions(companyList)}
                                    </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Upload File </Typography>
                                    <Button variant="contained" style={{height:40,width:250}} component="label" >
                                    <input
                                        type="file"
                                        //hidden
                                        onChange={this.onFileChange}
                                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        />
                                    </Button>
                                    {errors["selectedFile"]?
                                    <div style={{margin:2,color:"red"}}>{errors["selectedFile"]}</div>
                                    :null}
                                </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disableElevation
                                    disableFocusRipple
                                    style={styles.addButton}

                                >PROCESS DETAILS</Button>
                            </Grid>

                        </Grid>
                    </Grid>
                </div>
                </form>
                {this.listingFunc(LISTING_COLS,data,false)}
                <CustomModal
                  isOpen={isOpen}
                  handleClose={() => this.setState({isOpen:false})}
                >
                    <DeductionSummary id={transactionId} handleClose={() => this.setState({isOpen:false})} />
                </CustomModal>


                </>:null}
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
                {showNext?
                 <div style={styles.responseEdit}>
                  {loadingForEdit?
                    <div style={styles.loadingModule}>{this.loadingModule()}</div>
                   :
                    <>
                    <CustomModal
                        isOpen={showNext}
                        handleClose={this.handleClose}
                    >{<>
                        <ChangeResponse  cols={COLS} loading={loadingForEdit}  filteredDataList={filteredDataList}
                            faultyDataList={faultyDataList} listItems={listItems} COMPANY={companyName} callBackIt={()=>{
                            this.setState({
                                showNext:false,
                                selectedFile:null,
                                filteredDataList: [] ,
                                faultyDataList:[],
                                loadingForEdit:true
                            })
                    }} />
                    </>}</CustomModal>

                    </>
                   }
                 </div>:
                 null
                }

            </>
        );
    }
}

const styles = {
    // headTitle: {
    //     margin: "20px 0px 0px 40vw",
    //     position: "absolute",
    //     size: "55px"
    // },
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute"
    },
    loadingModule:{
        height: "calc(100vh - 310px)",
        backgroundColor:"transparent"
    },
    responseEdit:{
        // position: 'absolute',
        color:"black",
        padding:10,
        margin:"4% 2% 4% 2.5%",
        width:"92%",
        heigth:"auto",
        borderRadius:10,
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
    },
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "0%",
        overflowY: "scroll",
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 25px",
        fontWeight:400
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    fileUpload:{
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
        background:"red"
    },
    addButton: {
        width: 250,
        height: 40,
        margin: "25px 0px 0px 60px",
        color: "#fff",
        background: "rgb(16,12,109)"
    },
    tableContainer: {
        height: "calc(60vh - 120px)",
        backgroundColor:"white",
    },
    table: {
    },
    tableRow: {
    },
    tableBody: {
    },
    tableCell: {
    },
    bodyTableCell:{
        padding:"3px 10px"
    }
}
export default observer(PayDeduction);