import React from "react";
import {
    Button,
    Grid,
    MenuItem,
    TextField,
    Typography
} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { convertTimestamptoDate, findByPathName } from "../../utils/helper";
import { getUnderWritingDetails, patchUnderWritingDetails } from "../../service/api";
import { UW_STATUS_OPTIONS, UW_FINAL_DICISION_OPTIONS, UW_REF_CONTACTABLE, UW_REF_RELATION } from "../../common/constants"
import {REGEX} from "../../common/regex";
import Alert from "../../components/alert";
import UnderWritingDetail from "./uwDetails";


class UnderWritingEditScreen extends React.Component {
    constructor (props) {
        super(props);
        this.state = {
            isImageZoom: "",
            errors: {},
            hasError: false,
            errorMessage: "",
            errorType: "",
            buttonFlag: true,
            checkDetails: {},
            applicationId: "",
            docsCheckFlag: false,
            isAadhaarFrontVerified: 0,
            isAadhaarBackVerified: 0,
            isPanVerified: 0,
            isPhotoVerified: 0,
            docsComment: "",
            finalDecisionDocs: 0,
            refCheckFlag: false,
            isRef1Contactable: 0,
            isRef2Contactable: 0,
            isCustomerContactable: 0,
            ref1Relation: 10,
            ref2Relation: 10,
            isRef1HelpfulFrFuture: 0,
            isRef2HelpfulFrFuture: 0,
            anyOtherRef1Name: "",
            anyOtherRef1Phone: "",
            anyOtherRef2Name: "",
            anyOtherRef2Phone: "",
            refComments: "",
            finalDecisionRef: 0,
            reviewTag: "",
            underwritingDate: ""
        }
    }

    componentDidMount () {
        const { location } = this.props;
        const userId = findByPathName(location);
        this.callApis(userId);
    }

    callApis = (id) => {

        getUnderWritingDetails(
            id, (res) => {
                const { underwritingData } = res.response;
                if (res.success) {
                    const { applicationId, docsCheckFlag, isAadhaarFrontVerified, isAadhaarBackVerified, isPanVerified,
                        isPhotoVerified, docsComment, finalDecisionDocs, refCheckFlag, isRef1Contactable, isRef2Contactable,
                        isCustomerContactable, ref1Relation, ref2Relation, isRef1HelpfulFrFuture, isRef2HelpfulFrFuture,
                        anyOtherRef1Name, anyOtherRef1Phone, anyOtherRef2Name, anyOtherRef2Phone,
                        refComments, finalDecisionRef, reviewTag, underwritingDate } = underwritingData;
                    this.setState({
                        applicationId: applicationId,
                        docsCheckFlag: docsCheckFlag,
                        isAadhaarFrontVerified: isAadhaarFrontVerified,
                        isAadhaarBackVerified: isAadhaarBackVerified,
                        isPanVerified: isPanVerified,
                        isPhotoVerified: isPhotoVerified,
                        docsComment: docsComment,
                        finalDecisionDocs: finalDecisionDocs,
                        refCheckFlag: refCheckFlag,
                        isRef1Contactable: isRef1Contactable,
                        isRef2Contactable: isRef2Contactable,
                        isCustomerContactable: isCustomerContactable,
                        ref1Relation: ref1Relation,
                        ref2Relation: ref2Relation,
                        isRef1HelpfulFrFuture: isRef1HelpfulFrFuture,
                        isRef2HelpfulFrFuture: isRef2HelpfulFrFuture,
                        anyOtherRef1Name: anyOtherRef1Name,
                        anyOtherRef1Phone: anyOtherRef1Phone,
                        anyOtherRef2Name: anyOtherRef2Name,
                        anyOtherRef2Phone: anyOtherRef2Phone,
                        refComments: refComments,
                        finalDecisionRef: finalDecisionRef,
                        reviewTag: reviewTag,
                        underwritingDate: underwritingDate,
                        checkDetails: {
                            "isAadhaarFrontVerified": isAadhaarFrontVerified,
                            "isAadhaarBackVerified": isAadhaarBackVerified,
                            "isPanVerified": isPanVerified,
                            "isPhotoVerified": isPhotoVerified,
                            "docsComment": docsComment,
                            "finalDecisionDocs": finalDecisionDocs,
                            "isRef1Contactable": isRef1Contactable,
                            "isRef2Contactable": isRef2Contactable,
                            "isCustomerContactable": isCustomerContactable,
                            "ref1Relation": ref1Relation,
                            "ref2Relation": ref2Relation,
                            "isRef1HelpfulFrFuture": isRef1HelpfulFrFuture,
                            "isRef2HelpfulFrFuture": isRef2HelpfulFrFuture,
                            "anyOtherRef1Name": anyOtherRef1Name,
                            "anyOtherRef1Phone": anyOtherRef1Phone,
                            "anyOtherRef2Name": anyOtherRef2Name,
                            "anyOtherRef2Phone": anyOtherRef2Phone,
                            "refComments": refComments,
                            "finalDecisionRef": finalDecisionRef,
                        }
                    })
                } else {
                    this.setState({
                        hasError: true,
                        loading: false,
                        id: id
                    })
                }
            })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    handleField = (event) => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            this.checkChangeStatus();
        })
    }

    loadOptions = opts => {
        return opts.map(item => {
            return <MenuItem key={item.key} value={item.value}>
                {item.key}</MenuItem>
        })
    }

    handleValidation = (dat) => {
        let errorsTemp = {};
        let formIsValid = true;
        const { anyOtherRef1Name, anyOtherRef1Phone, anyOtherRef2Name, anyOtherRef2Phone } = this.state;
        const { phone_regex, name_regex } = REGEX;

        if (dat === 1) {
            if (!anyOtherRef1Name) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Name"] = "Cannot be empty";
            } else if (!name_regex.test(anyOtherRef1Name)) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Name"] = "Only letters";
            }


            if (!anyOtherRef1Phone) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Phone"] = "Cannot be empty";
            } else if (!phone_regex.test(anyOtherRef1Phone)) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Phone"] = "Please enter valid phone number";
            }
        }

        if (dat === 2) {
            if (!anyOtherRef2Name) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Name"] = "Cannot be empty";
            } else if (!name_regex.test(anyOtherRef2Name)) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Name"] = "Only letters";
            }

            if (!anyOtherRef2Phone) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Phone"] = "Cannot be empty";
            } else if (!phone_regex.test(anyOtherRef2Phone)) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Phone"] = "Please enter valid phone number";
            }
        }

        if (dat === 12) {
            if (!anyOtherRef1Name) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Name"] = "Cannot be empty";
            } else if (!name_regex.test(anyOtherRef1Name)) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Name"] = "Only letters";
            }


            if (!anyOtherRef1Phone) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Phone"] = "Cannot be empty";
            } else if (!phone_regex.test(anyOtherRef1Phone)) {
                formIsValid = false;
                errorsTemp["anyOtherRef1Phone"] = "Please enter valid phone number";
            }
            if (!anyOtherRef2Name) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Name"] = "Cannot be empty";
            } else if (!name_regex.test(anyOtherRef2Name)) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Name"] = "Only letters";
            }

            if (!anyOtherRef2Phone) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Phone"] = "Cannot be empty";
            } else if (!phone_regex.test(anyOtherRef2Phone)) {
                formIsValid = false;
                errorsTemp["anyOtherRef2Phone"] = "Please enter valid phone number";
            }
        }

        this.setState({ errors: errorsTemp });
        return formIsValid;
    }

    checkChangeStatus = () => {
        let detailTemp = {};

        const { isAadhaarFrontVerified, isAadhaarBackVerified, isPanVerified,
            isPhotoVerified, docsComment, finalDecisionDocs, isRef1Contactable, isRef2Contactable,
            isCustomerContactable, ref1Relation, ref2Relation, isRef1HelpfulFrFuture, isRef2HelpfulFrFuture,
            anyOtherRef1Name, anyOtherRef1Phone, anyOtherRef2Name, anyOtherRef2Phone, refComments, finalDecisionRef } = this.state;

        if (isAadhaarFrontVerified !== this.state.checkDetails["isAadhaarFrontVerified"]) {
            detailTemp["isAadhaarFrontVerified"] = isAadhaarFrontVerified;
        } else {
            delete detailTemp["isAadhaarFrontVerified"];
        }

        if (this.state.checkDetails["isAadhaarBackVerified"] !== isAadhaarBackVerified) {
            detailTemp["isAadhaarBackVerified"] = isAadhaarBackVerified;
        } else {
            delete detailTemp["isAadhaarBackVerified"];
        }

        if (this.state.checkDetails["isPanVerified"] !== isPanVerified) {
            detailTemp["isPanVerified"] = isPanVerified;
        } else {
            delete detailTemp["isPanVerified"];
        }

        if (this.state.checkDetails["isPhotoVerified"] !== isPhotoVerified) {
            detailTemp["isPhotoVerified"] = isPhotoVerified;
        } else {
            delete detailTemp["isPhotoVerified"];
        }

        if (this.state.checkDetails["docsComment"] !== docsComment) {
            detailTemp["docsComment"] = docsComment;
        } else {
            delete detailTemp["docsComment"];
        }

        if (this.state.checkDetails["finalDecisionDocs"] !== finalDecisionDocs) {
            detailTemp["finalDecisionDocs"] = finalDecisionDocs;
        } else {
            delete detailTemp["finalDecisionDocs"];
        }

        if (this.state.checkDetails["isRef1Contactable"] !== isRef1Contactable) {
            detailTemp["isRef1Contactable"] = isRef1Contactable;
        } else {
            delete detailTemp["isRef1Contactable"];
        }

        if (this.state.checkDetails["isRef2Contactable"] !== isRef2Contactable) {
            detailTemp["isRef2Contactable"] = isRef2Contactable;
        } else {
            delete detailTemp["isRef2Contactable"];
        }

        if (this.state.checkDetails["isCustomerContactable"] !== isCustomerContactable) {
            detailTemp["isCustomerContactable"] = isCustomerContactable;
        } else {
            delete detailTemp["isCustomerContactable"];
        }

        if (this.state.checkDetails["ref1Relation"] !== ref1Relation) {
            detailTemp["ref1Relation"] = ref1Relation;
        } else {
            delete detailTemp["ref1Relation"];
        }

        if (this.state.checkDetails["ref2Relation"] !== ref2Relation) {
            detailTemp["ref2Relation"] = ref2Relation;
        } else {
            delete detailTemp["ref2Relation"];
        }

        if (this.state.checkDetails["isRef1HelpfulFrFuture"] !== isRef1HelpfulFrFuture) {
            detailTemp["isRef1HelpfulFrFuture"] = isRef1HelpfulFrFuture;
        } else {
            delete detailTemp["isRef1HelpfulFrFuture"];
        }

        if (this.state.checkDetails["isRef2HelpfulFrFuture"] !== isRef2HelpfulFrFuture) {
            detailTemp["isRef2HelpfulFrFuture"] = isRef2HelpfulFrFuture;
        } else {
            delete detailTemp["isRef2HelpfulFrFuture"];
        }

        if (this.state.checkDetails["anyOtherRef1Name"] !== anyOtherRef1Name) {
            detailTemp["anyOtherRef1Name"] = anyOtherRef1Name;
        } else {
            delete detailTemp["anyOtherRef1Name"];
        }

        if (this.state.checkDetails["anyOtherRef1Phone"] !== anyOtherRef1Phone) {
            detailTemp["anyOtherRef1Phone"] = anyOtherRef1Phone;
        } else {
            delete detailTemp["anyOtherRef1Phone"];
        }

        if (this.state.checkDetails["anyOtherRef2Name"] !== anyOtherRef2Name) {
            detailTemp["anyOtherRef2Name"] = anyOtherRef2Name;
        } else {
            delete detailTemp["anyOtherRef2Name"];
        }

        if (this.state.checkDetails["anyOtherRef2Phone"] !== anyOtherRef2Phone) {
            detailTemp["anyOtherRef2Phone"] = anyOtherRef2Phone;
        } else {
            delete detailTemp["anyOtherRef2Phone"];
        }

        if (this.state.checkDetails["refComments"] !== refComments) {
            detailTemp["refComments"] = refComments;
        } else {
            delete detailTemp["refComments"];
        }

        if (this.state.checkDetails["finalDecisionRef"] !== finalDecisionRef) {
            detailTemp["finalDecisionRef"] = finalDecisionRef;
        } else {
            delete detailTemp["finalDecisionRef"];
        }

        if (Object.getOwnPropertyNames(detailTemp).length > 0) {
            this.setState({
                buttonFlag: false
            })
        } else {
            this.setState({
                buttonFlag: true
            })
        }

        this.setState({
            finalDetails: detailTemp
        })

    }

    createUnderWriting = e => {
        e.preventDefault();
        const { location } = this.props;
        let valTocheck = "";
        const userId = findByPathName(location);
        const { anyOtherRef1Name, anyOtherRef1Phone, anyOtherRef2Name, anyOtherRef2Phone, finalDetails } = this.state;
        if (anyOtherRef1Phone || anyOtherRef1Name) {
            valTocheck = 1;
        }
        if (anyOtherRef2Phone || anyOtherRef2Name) {
            valTocheck = 2;
        }
        if ((anyOtherRef1Phone || anyOtherRef1Name) && (anyOtherRef2Phone || anyOtherRef2Name)) {
            valTocheck = 12;
        }
        console.log(valTocheck)
        if (valTocheck === 1 || valTocheck === 2 || valTocheck === 12) {
            if (this.handleValidation(valTocheck)) {
                patchUnderWritingDetails(userId, finalDetails, (res) => {
                    if (res != null) {
                        if (res.success) {
                            this.setState({
                                hasError: true,
                                errorMessage: "Details submitted succesfully",
                                errorType: "success",
                                errors: {}
                            })
                            setTimeout(() => { this.handleBack() }, 2000);
                        } else {
                            this.setState({
                                hasError: true,
                                errorMessage: "Problem in submitting details ",
                                errorType: "error",
                                errors: {}
                            })
                        }
                    } else {
                        this.setState({
                            hasError: true,
                            errorMessage: "Problem in submitting details ",
                            errorType: "error",
                            errors: {}
                        })
                    }
                })
            } else {
                this.setState({
                    hasError: true,
                    errorMessage: "Please fill the details correctly ",
                    errorType: "error"
                })
            }
        } else {
            patchUnderWritingDetails(userId, finalDetails, (res) => {
                if (res != null) {
                    if (res.success) {
                        this.setState({
                            hasError: true,
                            errorMessage: "Details submitted succesfully",
                            errorType: "success",
                            errors: {}
                        })
                        setTimeout(() => { this.handleBack() }, 2000);
                    } else {

                    }
                } else {
                    this.setState({
                        hasError: true,
                        errorMessage: "Problem in submitting details ",
                        errorType: "error",
                        errors: {}
                    })
                }
            })
        }


    }

    commonDispatch = () => {
        const { applicationId, docsCheckFlag, isAadhaarFrontVerified, isAadhaarBackVerified, isPanVerified,
            isPhotoVerified, docsComment, finalDecisionDocs, refCheckFlag, isRef1Contactable, isRef2Contactable,
            isCustomerContactable, ref1Relation, ref2Relation, isRef1HelpfulFrFuture, isRef2HelpfulFrFuture,
            anyOtherRef1Name, anyOtherRef1Phone, anyOtherRef2Name, anyOtherRef2Phone,
            refComments, finalDecisionRef, reviewTag, underwritingDate, errors, buttonFlag } = this.state;


        const dispatchCONSTANT = [
            { id: "applicationId", type: "text", name: "applicationId", label: "APPLICATION ID", value: applicationId, isSelect: false, option: [], isDisable: true, isMultiline: false },
            { id: "docsCheckFlag", type: "text", name: "docsCheckFlag", label: "DOCS CHECK FLAG", value: docsCheckFlag ? 'YES' : 'NO', isSelect: false, option: [], isDisable: true, isMultiline: false },
            { id: "refCheckFlag", type: "text", name: "refCheckFlag", label: "REF CHECK FLAG", value: refCheckFlag ? 'YES' : 'NO', isSelect: false, option: [], isDisable: true, isMultiline: false },
            { id: "reviewTag", type: "text", name: "reviewTag", label: "REVIEW TAG", value: reviewTag, isSelect: false, option: [], isDisable: true, isMultiline: false },
            { id: "underwritingDate", type: "text", name: "underwritingDate", label: "UNDERWRITING DATE", value: convertTimestamptoDate(underwritingDate), isSelect: false, option: [], isDisable: true, isMultiline: false }
        ]

        const dispatchDOCS = [
            { id: "isAadhaarFrontVerified", type: "select", name: "isAadhaarFrontVerified", label: "IS AADHAAR FRONT VERIFIED", value: isAadhaarFrontVerified, isSelect: true, option: UW_STATUS_OPTIONS, isDisable: false, isMultiline: false },
            { id: "isAadhaarBackVerified", type: "select", name: "isAadhaarBackVerified", label: "IS AADHAR BACK VERIFIED", value: isAadhaarBackVerified, isSelect: true, option: UW_STATUS_OPTIONS, isDisable: false, isMultiline: false },
            { id: "isPanVerified", type: "select", name: "isPanVerified", label: "IS PAN VERIFIED", value: isPanVerified, isSelect: true, option: UW_STATUS_OPTIONS, isDisable: false, isMultiline: false },
            { id: "isPhotoVerified", type: "select", name: "isPhotoVerified", label: "IS PHOTO VERIFIED", value: isPhotoVerified, isSelect: true, option: UW_STATUS_OPTIONS, isDisable: false, isMultiline: false },
            { id: "docsComment", type: "text", name: "docsComment", label: "DOCS COMMENT", value: docsComment, isSelect: false, option: [], isDisable: false, isMultiline: true },
            { id: "finalDecisionDocs", type: "select", name: "finalDecisionDocs", label: "FINAL DECISION DOCS", value: finalDecisionDocs, isSelect: true, option: UW_FINAL_DICISION_OPTIONS, isDisable: false, isMultiline: false },
        ]

        const dispatchREF = [
            { id: "isRef1Contactable", type: "select", name: "isRef1Contactable", label: "IS REF1 CONTACTABLE", value: isRef1Contactable, isSelect: true, option: UW_REF_CONTACTABLE, isDisable: false, isMultiline: false },
            { id: "ref1Relation", type: "select", name: "ref1Relation", label: "REF1 RELATION", value: ref1Relation, isSelect: true, option: UW_REF_RELATION, isDisable: false, isMultiline: false },
            { id: "isRef1HelpfulFrFuture", type: "select", name: "isRef1HelpfulFrFuture", label: "IS REF1 HELPFUL FOR FUTURE", value: isRef1HelpfulFrFuture, isSelect: true, option: UW_REF_CONTACTABLE, isDisable: false, isMultiline: false },
            { id: "isRef2Contactable", type: "select", name: "isRef2Contactable", label: "IS REF2 CONTACTABLE", value: isRef2Contactable, isSelect: true, option: UW_REF_CONTACTABLE, isDisable: false, isMultiline: false },
            { id: "ref2Relation", type: "select", name: "ref2Relation", label: "REF2 RELATION", value: ref2Relation, isSelect: true, option: UW_REF_RELATION, isDisable: false, isMultiline: false },
            { id: "isRef2HelpfulFrFuture", type: "select", name: "isRef2HelpfulFrFuture", label: "IS REF2 HELPFULL FOR FUTURE", value: isRef2HelpfulFrFuture, isSelect: true, option: UW_REF_CONTACTABLE, isDisable: false, isMultiline: false },

            { id: "anyOtherRef1Name", type: "text", name: "anyOtherRef1Name", label: "ANY OTHER REF1 NAME", value: anyOtherRef1Name, isSelect: false, option: [], isDisable: false, isMultiline: false },
            { id: "anyOtherRef1Phone", type: "number", name: "anyOtherRef1Phone", label: "ANY OTHER REF1 PHONE", value: anyOtherRef1Phone, isSelect: false, option: [], isDisable: false, isMultiline: false },
            { id: "anyOtherRef2Name", type: "text", name: "anyOtherRef2Name", label: "ANY OTHER REF2 NAME", value: anyOtherRef2Name, isSelect: false, option: [], isDisable: false, isMultiline: false },
            { id: "anyOtherRef2Phone", type: "number", name: "anyOtherRef2Phone", label: "ANY OTHER REF2 PHONE", value: anyOtherRef2Phone, isSelect: false, option: [], isDisable: false, isMultiline: false },

            { id: "isCustomerContactable", type: "select", name: "isCustomerContactable", label: "IS CUSTOMER CONTACTABLE", value: isCustomerContactable, isSelect: true, option: UW_REF_CONTACTABLE, isDisable: false, isMultiline: false },
            { id: "refComments", type: "text", name: "refComments", label: "REF COMMENTS", value: refComments, isSelect: false, option: [], isDisable: false, isMultiline: true },
            { id: "finalDecisionRef", type: "select", name: "finalDecisionRef", label: "FINAL DECISION REF", value: finalDecisionRef, isSelect: true, option: UW_FINAL_DICISION_OPTIONS, isDisable: false, isMultiline: false },

        ]

        return (
            <>
                <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                    <Typography variant="h6" gutterBottom style={styles.header}>MANUAL UNDERWRITING</Typography>
                    <form onSubmit={this.createUnderWriting} >
                        <Typography variant="h6" gutterBottom style={styles.barTitle}>CONSTANT FIELDS</Typography>
                        <Grid container item xs={12} spacing={1}>
                            {dispatchCONSTANT.map((item) => {
                                return (<>
                                    <Grid item xs={3} style={styles.fieldWrapper}>
                                        <Typography style={styles.fieldLabel}>{item.label} </Typography>
                                        <TextField
                                            id={item.id}
                                            type={item.type}
                                            name={item.name}
                                            required
                                            disabled={item.isDisable}
                                            select={item.isSelect}
                                            value={item.value}
                                            onChange={this.handleField}
                                            style={styles.textField}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            multiline={item.isMultiline}
                                            helperText={errors[item.id]}
                                            variant="outlined"
                                            size={'small'}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: "red",
                                                    fontSize: 15,
                                                    marginLeft: "-1px"
                                                }
                                            }}
                                        >
                                            {item.isSelect ? this.loadOptions(item.option) : null}
                                        </TextField>
                                    </Grid>
                                </>)
                            })}

                        </Grid>
                        <Typography variant="h6" gutterBottom style={styles.barTitle}>DOCUMENTS CHECK</Typography>
                        <Grid container item xs={12} spacing={1}>
                            {dispatchDOCS.map((item) => {
                                return (<>
                                    <Grid item xs={3} style={styles.fieldWrapper}>
                                        <Typography style={styles.fieldLabel}>{item.label} </Typography>
                                        <TextField
                                            id={item.id}
                                            disabled={!docsCheckFlag}
                                            type={item.type}
                                            name={item.name}
                                            required
                                            select={item.isSelect}
                                            value={item.value}
                                            onChange={this.handleField}
                                            style={styles.textField}
                                            rows={1}
                                            rowsMax={3}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            multiline={item.isMultiline}
                                            helperText={errors[item.id]}
                                            variant="outlined"
                                            size={'small'}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: "red",
                                                    fontSize: 15,
                                                    marginLeft: "-1px"
                                                }
                                            }}
                                        >
                                            {item.isSelect ? this.loadOptions(item.option) : null}
                                        </TextField>
                                    </Grid>
                                </>)
                            })}

                        </Grid>
                        <Typography variant="h6" gutterBottom style={styles.barTitle}>REFERENCE CHECK</Typography>
                        <Grid container item xs={12} spacing={1}>
                            {dispatchREF.map((item) => {
                                return (<>
                                    <Grid item xs={3} style={styles.fieldWrapper}>
                                        <Typography style={styles.fieldLabel}>{item.label} </Typography>
                                        <TextField
                                            id={item.id}
                                            disabled={!refCheckFlag}
                                            type={item.type}
                                            name={item.name}
                                            required
                                            select={item.isSelect}
                                            value={item.value}
                                            onChange={this.handleField}
                                            style={styles.textField}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            rows={1}
                                            rowsMax={3}
                                            multiline={item.isMultiline}
                                            helperText={errors[item.id]}
                                            variant="outlined"
                                            size={'small'}
                                            FormHelperTextProps={{
                                                style: {
                                                    color: "red",
                                                    fontSize: 15,
                                                    marginLeft: "-1px"
                                                }
                                            }}
                                        >
                                            {item.isSelect ? this.loadOptions(item.option) : null}
                                        </TextField>
                                    </Grid>
                                </>)
                            })}
                        </Grid>
                    </form>
                </Grid>
                <div style={styles.btnContainer}>
                    <Button
                        variant="outlined"
                        type="submit"
                        disabled={buttonFlag}
                        disableElevation
                        style={{
                            width: 120,
                            height: 40,
                            position: "float",
                            color: "#fff",
                            background: `${buttonFlag ? "#cccccc" : "#1B2D93"}`,
                        }}
                        onClick={this.createUnderWriting}
                        size="small"
                    >SUBMIT
                    </Button>

                </div>
            </>
        )
    }

    render () {
        const { hasError, errorMessage, errorType } = this.state;
        const { location } = this.props;
        const userId = findByPathName(location);
        return (<>
            <div style={styles.rootContainer}>
                <div style={styles.backBar}>
                    <Button
                        variant="contained"
                        color="default"
                        startIcon={<ArrowBackIcon  />}
                        onClick={this.handleBack}
                        style={styles.backIcon}
                    >
                        Back
                    </Button>
                </div>
                <UnderWritingDetail id={userId} />
                {this.commonDispatch()}
                <Alert
                    open={hasError}
                    message={errorMessage}
                    type={errorType}
                    callback={this.falsifyError}
                />
            </div>
        </>)
    }

}

const styles = {
    rootContainer: {
        flowGrow: 1,
        background: "#fff",
        padding: 20,
        height: "calc(100vh - 180px)",
        overflowY: "scroll"
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            textDecoration: 'underline'
        }
    },
    btnContainer: {
        background: "#fff",
        listStyle: "none",
        margin: "0px 40px 0px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    },
    container: {
        padding: "0 60px"
    },
    backBar: {
        position: 'absolute',
        marginLeft:-10,
    },
    backIcon: {
        backgroundColor:"rgb(218,233,188)",
        color:"black",
        borderRadius:5,
    },
    barTitle: {
        margin: 5,
        padding: 10
    },
    // backIcon: {
    //     marginLeft: 10
    // },
    header: {
        marginTop: 30,
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    fieldLabel: {
        fontSize: 14,
        fontFamily: "inherit",
        color: "#9E9E9E"
    },
    fieldValue: {
        fontSize: 17,
        fontFamily: "inherit",
        whiteSpace: 'break-spaces'
    },
    singleHead: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 30,
        borderBottom: "1px solid"
    },
    singleRowContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        height: 40,

    },
    borderContainer: {
        border: "1px solid"
    },
    singleHeadCell: {
        width: 200,
        textAlign: "left",
        fontSize: 25,
        padding: "20px 50px 10px",

    },
    singleRowCell: {
        width: 200,
        textAlign: "center"
    },
    textField: {
        marginBottom: 10,
        alignSelf: "center",
        width: 240,
        height: 30,
        maxHeigth: 80,
    },
    addButton: {
        // width: 120,
        // height: 40,
        // position:"float",
        // color: "#fff",
        // background: "#1B2D93",
        // "&:hover": {
        //     background: "#1B2D93",
        // }
    }
}

export default UnderWritingEditScreen;