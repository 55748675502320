import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import { convertTimestamptoDate, debounce } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import SettlementStore from "./../../stores/settlementstore";
import {  getPendingLoanSettlement } from "./../../service/api";
import SearchField from "./../../components/searchField";
import { SEARCH_TYPE_CODES } from "../../common/constants";

const COLS = [
    { key: "settlement-id", align: "center", label: "SETTLEMENT ID", value: "id", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "settlement-created-date", align: "center", label: "CREATED DATE", value: "createdDate", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "settlement-last-updated", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "settlement-active", align: "center", label: "SETTLEMENT STATUS", value: "settlementStatus", type: "text", sort: false, fn: (v) => v },
    { key: "settlement-loanId", align: "center", label: "LOAN ID", value: "loanId", type: "text", sort: false, fn: (v) => v?? "-"},
    { key: "settlement-amount", align: "center", label: "SETTLEMENT AMOUNT", value: "amount", type: "text", sort: false, fn: (v) => v ?? "-"},
    { key: "settlement-action", align: "center", label: "TAKE ACTION", value: " ", type: "loanSettlementAction", sort: false, fn: (v) => v },
    { key: "settlement-summary", align: "center", label: "SUMMARY", value: " ", type: "loanSettlementSummary", sort: false, fn: (v) => v }

]

class LoanSettlement extends React.Component {
    constructor (props) {
        super(props);
        this.store = SettlementStore;
        this.pagination = PaginationStore;
       this.dbounceCaller = debounce(() => this.fetchSearch(true));
        this.state = {
            searchText: "",
            loading: true,
            hasError: false,
            errorMessage: "",
            sort: {
                sortType: "desc",
                sort: false
            },
            pageNumberValue:"1",
            pageButton:true
        }
    }

    componentDidMount () {
        this.fetchSettlementRequest(true);
    }

    fetchSettlementRequest ($hasUpdate = false) {
        const { loading, searchText} = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            pageNo: page,
            pageSize: pageSize
        }
        if ($hasUpdate) {
            params = {
                pageNo: 1,
                pageSize: pageSize
            }
        }
        if (searchText) {
            Object.assign(params, { "loanId": searchText, });
        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getPendingLoanSettlement(
            params,
            (res) => {
                if (res && res.success) {
                    const { pageNo, pageSize,settlements} = res.response;
                    this.store.setData(settlements);
                    this.pagination.goToPage(pageNo);
                    this.pagination.updateMaxPage(pageSize);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchSettlementRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchSettlementRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // search methods
    fetchSearch () {
        const { searchText } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            pageNo: page,
            pageSize: pageSize
        }

        if (searchText) {
            this.setState({
                pageButton:false
            })
            Object.assign(params, { "loanId": searchText, });

            getPendingLoanSettlement(
                params,
                (res) => {
                    if (res && res.success) {
                        this.store.setData(res.response);
                        this.setState({ loading: false });
                    } else {
                        this.setState({
                            hasError: true,
                            loading: false
                        })
                    }
                })
        } else {
            this.setState({
                pageButton:true
            })
            this.fetchSettlementRequest(true);
        }
    }

    handleSearch = evt => {

        this.setState({ searchText: evt.target.value });

        this.dbounceCaller();
    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    handleBack = () => {
        const { history } = this.props;
        history.goBack();
    }

    render () {
        const { loading, hasError, errorMessage, sort ,pageNumberValue,pageButton} = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>

                <SearchField
                    placeholder={"Loan Settlement"}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["settlement"]}
                />
                <div style={styles.btn}>
                </div>
                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={pageButton}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}
const styles = {
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        flexWrap: 'wrap',

    },
 }

export default observer(LoanSettlement);
