import React from "react";
import { 
    Skeleton
} from "@material-ui/lab";
import { withStyles } from "@material-ui/core/styles";

class ContentLoader extends React.Component {

    collectionModule = () => {
        const { classes, numberOfLines } = this.props;
        return (
            <div className={classes.rootContainer}>
                {[ ...Array(numberOfLines).keys() ].map((i) => <Skeleton key={i} animation="wave" className={classes.collection} />)}
            </div>
        )
    }

    statement = () => {
        const { classes, numberOfLines } = this.props;
        return (
            <div style={{ height: 200 }}>
                {[ ...Array(numberOfLines).keys() ].map((i) => <Skeleton key={i} animation="wave" className={classes.statement} />)}
            </div>
        )
    }
    render() {
        const { 
            module
         } = this.props;
        switch(module) {
            case "collection":
                return this.collectionModule();
            case "statement":
                return this.statement();
            default:
                return (
                    this.collectionModule()
                )
        }
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
    },
    collection: {
        height: 80
    },
    statement: {
        height: 60
    }
}

export default withStyles(styles)(ContentLoader);