import * as React from 'react'
import { CommonIcons } from '../../assets'
import { ReadMore } from './components'
import { Colors, Fonts } from '../../styles'

const NotFound = () => {
  const [open, setOpen] = React.useState(false)
  const toggleReadMore = () => {
    setOpen(prevState => !prevState)
  }
  return (
    <div style={styles.root}>
      <img src={CommonIcons.DataNotFound} alt="question-mark-not-found" style={styles.icon}/>
      <div style={styles.heading}>Loan ID/ Phone Number Not Found</div>
      <div style={styles.description}>We couldn&apos;t find an active loan with this loan ID/ phone number.</div>
      <ReadMore open={open} onPress={toggleReadMore} />
    </div>
  )
}

export default NotFound;

const styles = {
  root: {
    textAlign: 'center',
    margin: '48px auto',
  },
  heading: {
    marginTop: '16px',
    fontWeight: Fonts.weight.medium,
    fontSize: Fonts.size.small,
  },
  description: {
    marginTop: '10px',
    color: Colors.typographyColor03
  },
  icon: {
    height: '64px',
    width: '64px',
  }
}