import './App.css';
import Routing from "././routes/routes";
// import { Offline, Online } from "react-detect-offline";
// import { OfflineWatch } from "./components/offline";
import { saveLocalStorage } from './utils/localStorage';

function App() {
  saveLocalStorage("LoginStatus", false);
  return (
    <>
        <Routing/>
    </>
  );
}

export default App;
