import * as React from 'react';
import { Icon } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { Colors, Fonts } from '../../../styles';

const ReadMore = ({ open = false, onPress }) => {
	return (
		<div style={styles.root} onClick={onPress}>
			<div style={styles.readMoreIcon}>
				Read {open ? 'less' : 'more'}
				<Icon style={styles.icon}>
					{open ? <ExpandLess /> : <ExpandMore />}
				</Icon>
			</div>
			{open && (
				<div style={styles.messageboard}>
					The loan associated with the entered loan ID or phone number
					has been closed, or the loan ID/ phone number entered is
					incorrect. Please search again with an active & valid phone
					number/ loan ID.
				</div>
			)}
		</div>
	);
};

export default ReadMore;

const styles = {
	root: {
		color: Colors.primaryColor01,
		fontSize: Fonts.size.small,
		fontWeight: Fonts.weight.medium,
		marginTop: '44px'
	},
	readMoreIcon: {
		textAlign: 'center',
		position: 'relative'
	},
	icon: {
		top: -2,
    marginLeft: 8,
		color: Colors.primaryColor01,
		position: 'absolute'
	},
	messageboard: {
		backgroundColor: Colors.white,
		padding: '16px 20px',
		fontSize: '14px',
		color: Colors.typographyColor02,
		width: '600px',
		margin: '44px auto',
		fontWeight: Fonts.weight.regular,
		borderRadius: '4px',
	}
};
