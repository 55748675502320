import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import {  makeFilters, convertTimestamptoDate, makeSort,  debounce } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import { getUnderWritingCount, getUnderWritingList } from "./../../service/api";
import SearchField from "./../../components/searchField";
import FilterBar from "./../../components/filterBar";
import { UW_CREDIT_LINE_STATUS_CODES, SEARCH_TYPE_CODES, UW_FINAL_DECISION_REASON_CODES } from "../../common/constants";
import UnderWritingStore from "../../stores/underWritingStore";
import { IconButton } from "@material-ui/core";
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import FloatingDetail from "../../components/floatingDetails";
const COLS = [
    { key: "uw-application-id", align: "center", label: "APPLICATION ID", value: "applicationId", type: "text", sort: false, fn: (v) => v },
    { key: "uw-docs-check-flag", align: "center", label: "DOCS CHECK ", value: "docsCheckFlag", type: "text", sort: false, fn: (v) => Boolean(v) ? "YES":"NO" },
    { key: "uw-final-decision-docs", align: "center", label: "FINAL DECISION DOCS", value: "finalDecisionDocs", type: "text", sort: false, fn: (v) =>UW_FINAL_DECISION_REASON_CODES[v] },
    { key: "uw-ref-check-flag", align: "center", label: "REF CHECK ", value: "refCheckFlag", type: "text", sort: false, fn: (v) => Boolean(v) ? "YES":"NO"},
    { key: "uw-final-decision-ref", align: "center", label: "FINAL DECISION REF", value: "finalDecisionRef", type: "text", sort: false, fn: (v) => UW_FINAL_DECISION_REASON_CODES[v] },
    { key: "uw-user-id", align: "center", label: "USER ID", value: "userId", type: "text", sort: false, fn: (v) => v},
    { key: "uw-phone", align: "center", label: "PHONE", value: "phone", type: "text", sort: false, fn: (v) =>v },
    { key: "uw-date", align: "center", label: "UW DATE", value: "underwritingDate", type: "text", sort: false, fn: (v) =>convertTimestamptoDate(v)},
    { key: "uw-application-status", align: "center", label: "APPLICATION STATUS", value: "applicationStatus", type: "text", sort: false, fn: (v) => UW_CREDIT_LINE_STATUS_CODES[v] },
    { key: "uw-last-updated", align: "center", label: "LAST UPDATED", value: "lastUpdated", type: "text", sort: false, fn: (v) => convertTimestamptoDate(v) },
    { key: "uw-last-updated-by", align: "center", label: "LAST UPDATED BY", value: "lastUpdatedBy", type: "text", sort: false, fn: (v) => v?v:"-"},

];

class UnderWriting extends React.Component {
    constructor (props) {
        super(props);
        this.store = UnderWritingStore;
        this.pagination = PaginationStore;
        this.dbounceCaller = debounce(() => this.fetchUnderwritingRequest(true));
        this.state = {
            searchText: "",
            searchType: "applicationId",
            loading: true,
            hasError: false,
            errorMessage: "",
            filter: {},
            sort: {
                sortType: "desc",
                sort: false
            },
            btnStatus:true,
            showResults:false,
            countDetails:{},
            pageNumberValue:"1"
        }
    }

    componentDidMount () {
        this.fetchUnderwritingRequest();
        this.callForCount();
    }

    callForCount = ()=>{
        getUnderWritingCount((res)=>{
            if(res&& res.success){
               this.setState({
                   countDetails:res.response
               })
            }else{
                this.setState({
                    countDetails:{}
                })
            }
        })
    }

    fetchUnderwritingRequest ($hasUpdate = false) {
        const { loading, filter, sort, searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            page: page,
            pageSize: pageSize
        }

        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }

        const updatedFilter = makeFilters(filter, "underWriting")

        if (sort.sort) {
            Object.assign(params, sort);
        }

        Object.assign(params, { filter: updatedFilter.filter });

        if (searchText) {
            Object.assign(params, { "searchText": searchText, "searchType": searchType });
        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getUnderWritingList(
            params,
            (res) => {
                if (res && res.success) {
                    const { page, totalCount,underwritingList } = res.response;
                    this.store.setData(underwritingList);
                    this.pagination.goToPage(page);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            }
        );
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchUnderwritingRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchUnderwritingRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }
    // search methods

    handleSearch = evt => {
        this.setState({ searchText: evt.target.value });
        this.dbounceCaller();

    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    // sort methods
    handleSort = (sortByValue) => {

        const { sort } = this.state;
        let nSort = sort;

        if (!nSort.sort) {
            nSort = {
                "sortLabel": sortByValue,
                "sort": sortByValue,
                "sortType": "desc"
            }
        } else {
            if (nSort.sortLabel === sortByValue) {
                nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
            } else {
                nSort = {
                    "sortLabel": sortByValue,
                    "sort": sortByValue,
                    "sortType": "desc"
                }
            }
        }
        this.setState({
            sort: makeSort(nSort, "underWriting")
        }, () => this.fetchUnderwritingRequest(true))
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // filter bar methods
    handleFilter = data => {
        let tempFilter = Object.assign({}, this.state.filter);
        data.forEach(item => {
            const propertyValue = { [item.property]: item.value };
            Object.assign(tempFilter, propertyValue);
        })

        if (!data.length) {
            tempFilter = {};
        }

        this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
        console.log("updating property...", _filter);
        this.setState({
            filter: _filter
        }, () => this.fetchUnderwritingRequest(true))
    }

    removeProperty = (property) => {
        let tempFilter = Object.assign({}, this.state.filter);
        delete tempFilter[property];
        this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
        this.setState({
            filter: {}
        }, () => this.updateProperty())
    }

    manualCheck = ()=>{
        const { location, history } = this.props;
        const pathName = location.pathname;
        history.push(pathName + "/manualCheck");
    }

    onMouseEnterFunc =()=>{
        this.setState({
            showResults:true
        })
    }

    onMouseLeaveFunc =()=>{
        this.setState({
            showResults:false
        })
    }

    createCounDetatil= (dat) => {
        if (!dat) return [];
        const { todayCount,yesterdayCount,totalCount} = dat;
        return [
            { key: "uwc-today-count",  label: "LEADS TODAY", value:todayCount},
            { key: "uwc-yesterday-count",  label: "LEADS YESTERDAY", value: yesterdayCount},
            { key: "uwc-total-count",  label: "PENDING LEADS", value: totalCount},
        ]
    }

    render () {
        const { loading, hasError, errorMessage, sort, filter,showResults,countDetails,pageNumberValue } = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>
                <SearchField
                    placeholder={"Underwriting "}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["underwriting"]}
                />
                <div style={styles.Detailbtn}>
                    <IconButton
                    style={{
                        fontSize:10,
                        color:"white",
                        backgroundColor:"rgb(16,12,109)"
                    }}
                    onClick={this.onMouseEnterFunc}
                    ><KeyboardArrowLeft fontSize="small"/>
                    </IconButton>
                </div>
                {showResults?
                <FloatingDetail data={this.createCounDetatil(countDetails)} selectedSide={"right"} closeEvent={this.onMouseLeaveFunc} />
                :null}
                <FilterBar
                    screen={"underWriting"}
                    appliedFilter={filter}
                    callback={{
                        handleFilter: this.handleFilter,
                        removeProperty: this.removeProperty,
                        clearFitler: this.clearFilter
                    }}
                />

                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={true}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}


const styles = {
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute",
        left:10,

    },
    Detailbtn:{
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
        position:"absolute",
        bottom:5,
        right:15
    }
 }

export default observer(UnderWriting);