import * as React from 'react';
import {
	Select,
	MenuItem,
} from '@material-ui/core';
import './styles.css';

const SelectDropdown = ({
	id,
	defaultValue,
	options,
	style,
	value,
	handleChange
}) => {
	return (
		<Select
			defaultValue={defaultValue}
			style={style}
			value={value}
			variant='outlined'
			id={id}
			className="custom-select"
			onChange={handleChange}>
			{options.map(item => (
				<MenuItem
					key={item.value}
					className="custom-menu-item"
					value={item.value}>
					{item.name}
				</MenuItem>
			))}
		</Select>
	);
};

export default SelectDropdown;
