export function saveLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
}

export function getLocalStorage(key) {
    const Item = localStorage.getItem(key);
    return JSON.parse(Item);
}

export function clearLocalStorage() {
    localStorage.clear();
}