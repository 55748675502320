import { makeObservable, observable, action } from "mobx";

class ManualCheckStore {

    data = [];

    constructor(){
        makeObservable(this, {
            data: observable,
            setData: action
        })
    }

    setData(data) {
        this.data = data;
    }

    getData() {
        return this.data;
    }
}

export default new ManualCheckStore();