import React from "react";
import {
    Typography,
    IconButton
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import SimpleTable from "./../../components/simpleTable";

const COLS = [
    { key: "payment-mode", align: "left", label: "PAYMENT MODE", value: "paymentMode", type: "text", sort: false },
    { key: "payment-amount", align: "left", label: "AMOUNT", value: "amount", type: "text", sort: false },
    { key: "payment-message", align: "left", label: "MESSAGE", value: "message", type: "text", sort: false },
    { key: "payment-username", align: "left", label: "USERNAME", value: "userName", type: "text", sort: false },
    { key: "payment-statement-id", align: "left", label: "STATEMENT ID", value: "statementId", type: "text", sort: false },
    { key: "payment-created-date", align: "left", label: "CREATED DATE", value: "createdDate", type: "text", sort: false },
    { key: "payment-transaction-type", align: "left", label: "TRANSACTION TYPE", value: "transactionType", type: "text", sort: false },
    { key: "payment-waiver", align: "left", label: "WAIVER", value: "waiver", type: "text", sort: false }
]

const DATA = [{
    "paymentMode": "UPI",
    "amount": "1200",
    "message": "hello testing",
    "userName": "Sanjay Siddharth",
    "statementId": "283",
    "createdDate": "2012-02-01",
    "transactionType": "NONE",
    "waiver": "NONE"
}, {
    "paymentMode": "UPI",
    "amount": "1200",
    "message": "hello testing",
    "userName": "Sanjay Siddharth",
    "statementId": "283",
    "createdDate": "2012-02-01",
    "transactionType": "NONE",
    "waiver": "NONE"
}, {
    "paymentMode": "NET BANKING",
    "amount": "1230",
    "message": "hello testing",
    "userName": "Sanjay Mishra",
    "statementId": "923",
    "createdDate": "2018-10-10",
    "transactionType": "NONE",
    "waiver": "NONE"
}];

class Payment extends React.Component {
    constructor() {
        super();
        this.state = {
            loading: false
        }
    }
    render() {
        const { classes, handleClose } = this.props;
        return (<div className={classes.rootContainer}>
            <IconButton variant="contained"
                    className={classes.closeBtn}
                    onClick={() => handleClose()}
                ><CloseIcon /></IconButton>
            <Typography
                variant="h5"
                gutterBottom
                className={classes.typography}
            >VIEW PAYMENTS</Typography>
            
            <SimpleTable
                cols={COLS}
                data={DATA}
            />
        </div>);
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "30px 5px 15px"
    },
    closeBtn: {
        float: "right"
    }
}

export default withStyles(styles)(Payment);