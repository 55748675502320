import React from "react";
import {
    Typography,
    IconButton,
    Grid,
    TextField,
    MenuItem
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import { COMPANY } from "../../common/constants";

class UploadFile extends React.Component {
    constructor () {
        super();
        this.state = {
            loading: true,
            data: [],
            hasError: false,
            errorMessage: "",
            companyName:["SWIGGY"],
            selectedFile:null
        }
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    onFileChange = event => {
        this.setState({ selectedFile: event.target.files[0] });
    };

    loadOptions = opts => {
        console.log(opts[0])
        return opts.map(item => {
            return <MenuItem  value={item}>
                {item}</MenuItem>
        })
    }

    handleField = (event) => {
        const { name,value}= event.target;
        console.log(name,value)
        this.setState({
            [name]: value
        })
    }

    render () {
        const { classes, handleClose } = this.props;
        const { companyName} = this.state;
        return (
            <>
                <div className={classes.rootContainer}>
                    <IconButton variant="contained"
                        className={classes.closeBtn}
                        onClick={() => handleClose()}
                    ><CloseIcon /></IconButton>
                    <Typography
                        variant="h5"
                        gutterBottom
                        className={classes.typography}
                    >UPLOAD FILE </Typography>
                   <Grid container direction="column" justifyContent="flex-start" style={styles.container}>
                        <Grid container item xs={12} spacing={1}>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>Company </Typography>
                                    <TextField
                                        id={companyName}
                                        type="select"
                                        name="companyName"
                                        required
                                        value={companyName}
                                        onChange={this.handleField}
                                        style={styles.textField}
                                        select={true}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        size={'small'}
                                        variant="outlined"
                                    >
                                        {this.loadOptions(COMPANY)}
                                    </TextField>
                            </Grid>
                            <Grid item xs={3} style={styles.fieldWrapper}>
                                    <Typography style={styles.fieldLabel}>File </Typography>
                                    <input style={styles.fileUpload} type="file" onChange={this.onFileChange} />
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }
}

const styles = {
    rootContainer: {
        background: "#fff",
        outline: "none",
        padding: 25,
        borderRadius: 6,
        margin: "5%",
        overflowY: "scroll"
    },
    typography: {
        fontFamily: "Poppins",
        margin: "25px 5px 25px"
    },
    closeBtn: {
        float: "right"
    },
    fieldWrapper: {
        fontFamily: "Poppins",
        padding: 20
    },
    container: {
        padding: "0 60px"
    },
    fieldLabel: {
        fontWeight:"bold",
        fontSize:18,
        fontFamily: "inherit",
        color: "#9E9E9E",
    },
    textField: {
        marginBottom: 20,
        alignSelf: "center",
        width: 250,
    },
    fileUpload:{
        width: 500,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-end"
    }
}

export default withStyles(styles)(UploadFile);
