import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import { makeFilters, makeSort, convertTimestamptoDate, debounce } from "./../../utils/helper";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import LoanStore from "./../../stores/loanStore";
import { getLoan, getSearch } from "./../../service/api";
import SearchField from "./../../components/searchField";
import FilterBar from "./../../components/filterBar";
import { SEARCH_TYPE_CODES } from "../../common/constants";
import { getLocalStorage } from "../../utils/localStorage";

const COLS = [
    { key: "loan-id", align: "center", label: "LOAN ID", value: "loanId", type: "text", sort: true, fn: (v) => v },
    { key: "loan-type", align: "center", label: "LOAN TYPE", value: "loanType", type: "text", sort: false, fn: (v) => v },
    { key: "loan-status", align: "center", label: "LOAN STATUS", value: "loanStatus", type: "text", sort: false, fn: (v) => v },
    { key: "reason-code", align: "center", label: "REASON CODE", value: "reasonCode", type: "text", sort: true, fn: (v) => v??"-" },
    { key: "dpd-count", align: "center", label: "DPD COUNT", value: "dpdCount", type: "text", sort: true, fn: (v) => v },
    { key: "due-date", align: "center", label: "DUE DATE", value: "dueDate", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },
    { key: "applied-loan-amount", align: "center", label: "APPLIED LOAN AMOUNT", value: "appliedLoanAmount", type: "text", sort: false, fn: (v) => v },
    { key: "application-completion-date", align: "center", label: "APPLICATION COMPLETION DATE", value: "applicationCompletionDate", type: "text", sort: true, fn: (v) => convertTimestamptoDate(v) },
    { key: "approved-loan-amount", align: "center", label: "APPROVED LOAN AMOUNT", value: "approvedLoanAmount", type: "text", sort: false, fn: (v) => v },
    { key: "outstanding-principle", align: "center", label: "OUTSTANDING PRINCIPAL", value: "outstandingPrinciple", type: "text", sort: true, fn: (v) => v },
    { key: "amort-detail", align: "center", label: "AMORT SCHEDULE", value: " ", type: "amortSchedule", sort: false, fn: (v) => v },
    { key: "loan-statement-download", align: "center", label: "DOWNLOAD STATEMENT", value: " ", type: "loanStatementDownload", sort: false, fn: (v) => v },
    { key: "loan-agreement", align: "center", label: "AGREEMENT", value: " ", type: "loanAgreementDownload", sort: false, fn: (v) => v },
    { key: "loan-reset-kyc-doc", align: "center", label: "RESET KYC DOC", value: " ", type: "loanResetKycDoc", sort: false, fn: (v) => v },
    // { key: "loan-cancel-loan", align: "center", label: "CANCEL LOAN", value: "reasonCode", type: "cancelLoan", sort: false,permissions:[0,1] ,fn: (v) => v },
]
const restrictedMails = [
    "hema.bagh@buenofinance.in",
    "mansi.taneja@buenofinance.in",
    "vishal.singh@buenofinance.in"
]

class Loan extends React.Component {
    constructor (props) {
        super(props);
        this.store = LoanStore;
        this.pagination = PaginationStore;
        this.dbounceCaller = debounce(() => this.fetchSearch(true));
        this.state = {
            searchText: "",
            searchType: "loanId",
            filter: {},
            loading: true,
            hasError: false,
            errorMessage: "",
            sort: {
                sortType: false,
                sort: false
            },
            pageNumberValue:"1",
            pageButton:true,
            emailFromAuth:""
        }
    }

    componentDidMount () {
        this.fetchLoanRequest(true);
        const {email}= getLocalStorage("LmsUserDetails");
        this.setState({emailFromAuth:email})
    }

    fetchLoanRequest ($hasUpdate = false) {
        const { loading, filter, sort, searchText, searchType } = this.state;
        const { page, pageSize } = this.pagination;

        let params = {
            page: page,
            pageSize: pageSize
        }

        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }

        const updatedFilter = makeFilters(filter, "loan")

        if (sort.sort) {
            Object.assign(params, sort);
        }

        Object.assign(params, { filter: updatedFilter.filter });

        if (searchText) {
            Object.assign(params, { "searchText": searchText, "searchType": searchType });
        }

        if (!loading) {
            this.setState({ loading: true });
        }

        getLoan(
            params,
            (res) => {
                if (res && res.success) {
                    const { page, totalCount } = res.response;
                    this.store.setData(res.response.loans);
                    this.pagination.goToPage(page);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }



    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchLoanRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:1);
        if(pageNumberValue && pageNumberValue <= 0){
            this.setState({
                pageNumberValue:1
            })
        }
        this.fetchLoanRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }


    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }

    // search methods
    fetchSearch () {
        const { searchText, searchType } = this.state;

        const params = {};

        if (searchText) {
            this.setState({
                pageButton:false
            })
            Object.assign(params, { "searchText": searchText, "searchType": searchType, "type": "loan" });

            getSearch(
                params,
                (res) => {
                    if (res.success && res) {
                        this.store.setData(res.response.loans);
                        this.setState({ loading: false });
                    } else {
                        this.setState({
                            hasError: true,
                            loading: false
                        })
                    }
                })
        } else {
            this.fetchLoanRequest(true);
            this.setState({
                pageButton:true
            })
        }
    }

    handleSearch = evt => {

        this.setState({ searchText: evt.target.value });

        this.dbounceCaller();
    }

    handleSearchType = (evt) => {
        this.setState({
            searchType: evt.target.value
        })
    }

    // sort methods
    handleSort = (sortByValue) => {
        const { sort } = this.state;
        let nSort = sort;

        if (!nSort.sort) {
            nSort = {
                "sortLabel": sortByValue,
                "sortType": "desc",
                "sort": sortByValue
            }
        } else {
            // switch sortType and sortBy in state
            if (nSort.sortLabel === sortByValue) {
                nSort.sortType === "asc" ? nSort.sortType = "desc" : nSort.sortType = "asc";
            } else {
                nSort = {
                    "sort": sortByValue,
                    "sortLabel": sortByValue,
                    "sortType": "desc"
                }
            }
        }

        this.setState({
            sort: makeSort(nSort, "loan")
        }, () => this.fetchLoanRequest(true))

    }

    // filter methods
    handleFilter = data => {
        let tempFilter = Object.assign({}, this.state.filter);
        data.forEach(item => {
            const propertyValue = { [item.property]: item.value };
            Object.assign(tempFilter, propertyValue);
        })

        if (!data.length) {
            tempFilter = {};
        }

        this.updateProperty(tempFilter);
    }

    updateProperty = (_filter) => {
        console.log("updating property...", _filter);
        this.setState({
            filter: _filter
        }, () => this.fetchLoanRequest(true))
    }

    removeProperty = (property) => {
        let tempFilter = Object.assign({}, this.state.filter);
        delete tempFilter[property];
        this.updateProperty(tempFilter)
    }

    clearFilter = _ => {
        this.setState({
            filter: {}
        }, () => this.updateProperty())
    }

    handleColTitle = (items) =>{
        const res= getLocalStorage("LmsUserDetails");
        const { role} = res;

        const checkPermission = (PERMISSIONS)=>{
            let conditionCheck = false;
                for(let item of PERMISSIONS){
                    if(role.includes(item)){
                        conditionCheck=true;
                        break;
                    }
            }

            return conditionCheck;
        }

        if(checkPermission(["ROLE_CRM_ADMIN","ROLE_KDR"])){
           return items;
        }else{
            const newarr = items.filter((a)=>{return a.key !== 'loan-reset-kyc-doc'});
            return newarr;
        }
        // if(checkPermission(["ROLE_KDR"])){

        // }
        // else{
        //    const newarr = items.filter((a)=>{return a.key !== 'loan-cancel-loan' && a.key !== 'loan-reset-kyc-doc'});
        //    return newarr;
        // }
    }



    render () {
        const { loading, hasError, errorMessage, sort, filter ,pageNumberValue,pageButton,emailFromAuth} = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;

        return (
            <>

                <SearchField
                    placeholder={"Loan"}
                    onSearch={this.handleSearch}
                    onSearchType={this.handleSearchType}
                    search={this.state.searchText}
                    searchType={SEARCH_TYPE_CODES["loan"]}
                />

                {restrictedMails.includes(emailFromAuth)
                   ?
                   <>
                    <div style={{
                        background: "#fff",
                        height: 50,
                        listStyle: "none",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        flexWrap: 'wrap',
                    }}> </div>
                   </>
                   :
                   <>  <FilterBar
                            screen={"loan"}
                            appliedFilter={filter}
                            callback={{
                                handleFilter: this.handleFilter,
                                removeProperty: this.removeProperty,
                                clearFitler: this.clearFilter
                            }}
                        />
                   </>}
                <CustomTable
                    {...this.props}
                    cols={this.handleColTitle(COLS)}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={pageButton}
                />
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}


export default observer(Loan);