import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
    Chip,
    Button
} from "@material-ui/core";
import CustomModal from "./../components/modal";
import FilterScreen from "./../screens/home/filter";
import { convertFromCamelCase } from "./../utils/helper";

class FilterBar extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            modalChildren: <div>Loading...</div>,
            chipData: [],
            filter: {}
        }
    }

    removeFilter = property => {
        let filterCpy = this.state.chipData;
        const newChipData = [];
        filterCpy.forEach((item, i) => {
            if(item.property !== property) {
                 newChipData.push(item);
            }
        })
        this.setState({ chipData: newChipData }, () => this.props.callback.removeProperty(property))
    }

    addFilter = data => {
        console.log("segregating property and values...", data);
        const properyValue = [];
        Object.keys(data).forEach(item => {
            properyValue.push({
                property: item,
                value: data[item]
            })
        })
        this.props.callback.handleFilter(properyValue);
        this.setState({ chipData: properyValue }, () => this.handleClose());
    }

    handleClose = () => {
        this.setState({
            modalChildren: <div>Loading...</div>,
            isOpen: false
        })
    }

    handleOpen = _ => {
        const { screen } = this.props;
        const { filter } = this.state;
        this.setState({
            modalChildren: <FilterScreen
                                screen={screen}
                                handleClose={this.handleClose}
                                addFilter={this.addFilter}
                                appliedFilter={filter}
                                />,
            isOpen: true
        })
    }

    static getDerivedStateFromProps(props, state) {

        if(JSON.stringify(state.filter)!==JSON.stringify(props.appliedFilter)) {
            if (!Object.keys(props.appliedFilter).length) {
                return { filter: props.appliedFilter, chipData: [] }
            }
            return { filter: props.appliedFilter };
        }

    }

    render() {
        const { classes } = this.props;
        const { isOpen, modalChildren, chipData } = this.state;

        return (<>
            <div className={classes.filterBarContainer}>
                {chipData.map(chip => {
                    return (
                        <li key={chip.property}>
                            <Chip
                                label={convertFromCamelCase(chip.property)+" "+chip.value}
                                onDelete={() => this.removeFilter(chip.property)}
                                className={classes.chip}
                            />
                        </li>
                    );
                })}
                <Button
                    variant="outlined"
                    disableRipple
                    onClick={this.handleOpen}
                    className={classes.filterBtn}
                    disabled={false}
                >FILTERS</Button>
            </div>
            <div className={classes.theOtherContainer}>
        </div>
        <CustomModal
                isOpen={isOpen}
                handleClose={this.handleClose}
            >{modalChildren}</CustomModal>
        </>);
    }
}

const styles = {
    filterBarContainer: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    },
    chip: {
        margin:"0 10px",
        borderRadius: 4
    },
    filterBtn: {
        marginRight: 50,
        marginLeft: 50
    }
}

export default withStyles(styles)(FilterBar);