import React from 'react';
import {
	Button as MuiButton,
} from '@material-ui/core';
import { BtnStyles } from './styles';

const Button = ({
	variant = 'contained',
	color = 'primary',
	loading = false,
	btnStyle = {},
	text,
	...MUIProps
}) => {
	return (
		<MuiButton
			variant={variant}
			color={color}
			style={{...BtnStyles[`${color}_${variant}`], ...btnStyle}}
			{...MUIProps}>
			{!loading && text}
			{loading && <span className={BtnStyles.loading}>...</span>}
		</MuiButton>
	);
};

export default Button;
