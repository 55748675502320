import React from "react";
import CustomTable from "./../../components/table";
import { observer } from "mobx-react";
import PaginationStore from "./../../stores/pagination";
import Alert from "./../../components/alert";
import RoleStore from "./../../stores/roleStore";
import { getLmsUser } from "./../../service/api";
import { Button } from "@material-ui/core";
import UplaodFile from "./uplaodFile";
import CustomModal from "../../components/modal";

const COLS = [
    { key: "role-id", align: "center", label: "ROLE ID", value: "id", type: "text", sort: false, fn: (v) => v },
    { key: "role-name", align: "center", label: "NAME", value: "name", type: "text", sort: false, fn: (v) => v },
    { key: "role-email", align: "center", label: "EMAIL", value: "email", type: "text", sort: false, fn: (v) => v },
    // { key: "role-phone", align: "center", label: "PHONE", value: "phone", type: "text", sort: false, fn: (v) => v },
    { key: "role-permission", align: "center", label: "PERMISSION", value: "roles", type: "text", sort: false, fn: (v) =>v? v.join(','):null },
    { key: "role-edit-role", align: "center", label: "EDIT", value: "roleEditRole", type: "roleEditRole", sort: false, fn: (v) => v }
]

class Role extends React.Component {
    constructor (props) {
        super(props);
        this.store = RoleStore;
        this.pagination = PaginationStore;
        this.state = {
            loading: true,
            hasError: false,
            errorMessage: "",
            sort: {
                sortType: "desc",
                sort: false
            },
            openModel:false,
            pageNumberValue:"1"
        }
    }

    componentDidMount () {
        this.fetchRoleRequest(true);
    }

    fetchRoleRequest ($hasUpdate = false) {

        const { page, pageSize } = this.pagination;

        let params = {
            page: page,
            pageSize: pageSize
        }

        if ($hasUpdate) {
            params = {
                page: 1,
                pageSize: pageSize
            }
        }

        getLmsUser(
            params,
            (res) => {
                console.log(res);
                if (res && res.success) {
                    const { page,  totalCount, lmsUsers } = res.response;
                    this.store.setData(lmsUsers);
                    this.pagination.goToPage(page);
                    this.pagination.updateMaxPage(totalCount);
                    this.setState({ loading: false });
                } else {
                    this.setState({
                        hasError: true,
                        loading: false
                    })
                }
            })
    }

    handlePageChange = (evt, value) => {
        this.pagination.goToPage(value);
        this.setState({
            pageNumberValue:value
        })
        this.fetchRoleRequest();
    }

    handleGoToPageChange = () => {
        const { pageNumberValue} = this.state;
        this.pagination.goToPage(pageNumberValue && pageNumberValue>0?pageNumberValue:"1");
        if(pageNumberValue && pageNumberValue <= "0"){
            this.setState({
                pageNumberValue:"1"
            })
        }
        this.fetchRoleRequest();
    }

    onChangeGoToValue = (evt)=>{
       this.setState({
           pageNumberValue:evt.target.value
       })
    }

    falsifyError = () => {
        this.setState({
            hasError: false
        })
    }


    goToAddScreen = _ => {
        const { location, history } = this.props;
        const pathName = location.pathname;
        history.push(pathName + "/addRole");
    }

    handleModel = () =>{
      this.setState({
          openModel:true
      })
    }

    render () {
        const { loading, hasError, errorMessage, sort ,openModel,pageNumberValue} = this.state;
        const { data } = this.store;
        const { maxPage, page } = this.pagination;
        return (
            <>
                <div style={styles.btn}>
                    {/* <Button
                        onClick={this.handleModel}
                        style={{
                            border: "1px solid black"
                        }}>
                        Add File
                    </Button> */}
                    <Button
                        onClick={this.goToAddScreen}
                        style={{
                            marginRight: 50,
                            marginLeft: 50,
                            border: "1px solid black",
                            width:150
                        }}
                        >
                        Add Role
                    </Button>
                </div>
                <CustomTable
                    {...this.props}
                    cols={COLS}
                    data={data}
                    option={[]}
                    loading={loading}
                    count={maxPage}
                    page={page}
                    sort={sort}
                    handlePageChange={this.handlePageChange}
                    handleSort={this.handleSort}
                    pageNumberValue={pageNumberValue}
                    handleGoToPageChange={this.handleGoToPageChange}
                    onChangeGoToValue={this.onChangeGoToValue}
                    pageButton={true}
                />
                <CustomModal
                    isOpen={openModel}
                    handleClose={()=> this.setState({
                        openModel:false
                    })}>
                    <UplaodFile  handleClose={() => this.setState({
                            openModel:false
                        })}/>
                </CustomModal>
                <Alert
                    open={hasError}
                    message={errorMessage}
                    callback={this.falsifyError}
                />
            </>
        );
    }
}

const styles = {
    headTitle: {
        margin: "20px 0px 0px 40vw",
        position: "absolute",
        size: "55px"
    },
    btn: {
        background: "#fff",
        height: 50,
        listStyle: "none",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexWrap: 'wrap',
    }
}

export default observer(Role);